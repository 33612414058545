import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import businessIcon from '../../images/Business.png';
import personalIcon from '../../images/Personal.png';
import UserContent from './UserContent';
import { PMAPI } from "../../helper/PMAPI"
import { useDispatch } from 'react-redux';
import { setFruxData, setFruxDataState, setSelectedUser } from '../../redux/slices/logsSlice';

function CheckUserType(props ) {
    const [show, setShow] = useState(false);
    const [responseApi, setResponseApi] = useState()
    const dispatch = useDispatch();

    const handleUserClick = (userTitle) => {
        PMAPI.getFruxImages()
        .then((response) => {
            setResponseApi(response)
            setShow(false)
            props.modalshowcontrol();
            dispatch(setFruxData(response))
            dispatch(setFruxDataState(true))
            dispatch(setSelectedUser(userTitle.toLowerCase()))
        })
        .catch((error) => {
            console.error(error)
        })

    };


    const handleClose = () => setShow(false);



    return (
        
        <>
            <Modal
                backdrop='static'
                keyboard={false}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='FRUXModal_wrap'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Which best describes how you plan to use Atticus?
                        <span>Select one</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <UserContent
                        userIcon={personalIcon}
                        userTitle="Personal"
                        userDsc="Examples: Give me a Python code snippet for parsing a CSV file
                            Write a cover letter for a makeup artist
                            Create an image of a sports car"
                        onHide={handleClose}
                        onClick={handleUserClick}                        
                    />

                    <UserContent
                        userIcon={businessIcon}
                        userTitle="Business"
                        userDsc="Examples: Give me a Python code snippet for parsing a CSV file
                            Write a cover letter for a makeup artist
                            Create an image of a sports car"
                        onHide={handleClose}
                        onClick={handleUserClick}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CheckUserType;


