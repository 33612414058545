import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaFileAlt } from 'react-icons/fa';
import IconFileInitial from '../../icons/IconFileInitial';
import IconFileInProgress from '../../icons/IconFileInProgress';
import IconFileComplete from '../../icons/IconFileComplete';
import MRAKabob from '../General/MRKabob';

const SkeletonLoaderFile = (props) => {
  return (
    <>
      <div>
        <Container state={props.fileState} uploading_file={props.uploading_file}>
          {props.fileState === 'initial' && props.uploading_file == true ? <IconFileInitial /> :
            props.fileState === 'loading' && props.uploading_file == true ? <IconFileInProgress /> :
              <IconFileComplete />

          }
          <span className='skeleton-text'> {props.response} </span>
          { }

        </Container>
      </div>
      {/* <div className='kabob-btn-skeleton'>
        {(props.uploading_file == false || props.fileState === 'complete') &&
          <span className='kabob-skeleton'>
            <MRAKabob/>
          </span>
        }
      </div> */}

    </>
  );
};

export default SkeletonLoaderFile;

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ state, uploading_file }) => (state === 'complete' || uploading_file == false ? 'white' : '#e0e0e0')};
  padding: 38px;
  border-radius: 5px;
  position: relative;
  height: 33px; 
  // align-items: flex-start;
  margin-top: 21px;
  ${({ state, uploading_file }) =>
    state === 'loading' && uploading_file == true &&
    css`
      background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
      background-size: 400% 100%;
      animation: ${loadingAnimation} 1.5s infinite;
    `}
`;

const FileIcon = styled(FaFileAlt)`
  font-size: 24px;
  margin-right: 10px;
`;

const Text = styled.div`
  width: 100%;
  background-color: ${({ state }) => (state === 'complete' ? 'white' : '#e0e0e0')};
  color: ${({ state }) => (state === 'complete' ? 'black' : 'transparent')};
`;

export { Container, FileIcon, Text };