import React, { useState, useContext, useEffect, } from 'react';
import { Accordion, AccordionHeader, AccordionBody, AccordionItem } from "react-headless-accordion";
import MRLAccordionPar from './MRLAccordionPar';
import IconAccordion from "../../icons/IconAccordion";
import EditableText from "../General/MREditableText";
import MRAKabob from "../General/MRKabob";
import MRLContext from '../MRLogs/MRLContext';
import { MRFolderAPI } from "../../../helper/MRFolderAPI";
import { MRLogsAPI } from "../../../helper/MRLogsAPI";
import IconsAreYouSureDelete from '../../icons/LogsIcons/IconsAreYouSureDelete';
import IconsAreYouSureCancel from '../../icons/LogsIcons/IconsAreYouSureCancel';
import IconCheckMark from '../../icons/LogsIcons/IconCheckmark'
import IconCancelConfirmationFolder from '../../icons/LogsIcons/IconCancelConfirmationFolder';
import IconConfirmFolder from '../../icons/LogsIcons/IconConfirmFolder';
import MainRegionContext from '../General/MRContext'
import coversationContext from '../../../context/conversationContext';
import IconAddFolderCyan from '../../icons/LogsIcons/IconAddFolderCyan';
import ProfileMenuContext from '../../profile-menu/PMContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAddNewFolder } from '../../../redux/slices/logsSlice';

const MRLAccordionFolder = ({ folder, logsContext, moveChildren, previousFolder }) => {

    const mainRegionLogs = useContext(MRLContext);
    const [nameIsEditing, setNameIsEditing] = useState(false);
    const [name, setName] = useState(folder.name);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isAddingFolder, setIsAddingFolder] = useState(false);
    const [nameConflictError, setNameConflictError] = useState(false);
    // const { addNewFolder, setaddNewFolder } = useContext(ProfileMenuContext);
    const [nameErrorMessage, setnameErrorMessage] = useState('Enter a folder name that does not already exist');
    const [renamingFolder, setrenamingFolder] = useState(false);
    const [originalName, setOriginalName] = useState('');
    const [fetchApiResponse, setFetchApiResponse] = useState()
    const [openAccordian, setOpenAccordian] = useState(false)
    const [isAccordionOpen, setAccordionOpen] = useState(false);
    const [folderLength, setFolderLength] = useState()
    const [isClickedOutside, setIsClickedOutside] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState()
    const [folderToBeMoved, setFolderToBeMovedId] = useState()
    const [moveState, setMoveState] = useState()
    const [folderKeyId, setFolderKeyId] = useState(0)
    const logsContainer =  document.getElementById("logsScroll");
    const [editingFolderName, setEditingFolderName] = useState()
    const [newFolderID,setnewFolderID] = useState()
    const navigate = useNavigate()
    const addNewFolder = useSelector(state => state.logs.addNewFolder)
    const dispatch = useDispatch()

    useEffect(() => {
        if (mainRegionLogs?.selectedState === false) {
            setMoveState(false)
        }

    }, [mainRegionLogs?.selectedState])

    useEffect(() => {
        if (addNewFolder) {
            dispatch(setAddNewFolder(false))
            setTimeout(() => {    
                addEmptyAccordion();
            }, 400);             
        }          
    },[addNewFolder])
    const generateUUID = () => {
        const uuid = 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          const r = (Math.random() * 16) | 0;
          const v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
        return uuid;
      };
      const handleAddNewFolder = (folderId) => {
        setnewFolderID(folderId);
        console.log(`set new folder id to: `, folderId);
        // mainRegionLogs.handleAccordionToggle(folder.id, folder);
        // handleFolderHeaderClick(folder);
      
        const parentAccordion = document.querySelector(`.acc_header_wrapper[data-folder-id='${folderId}'] button.titleWrap`);
        const iconAccordianDiv = document.querySelector('#iconAccodianDiv')
        console.log(`parentAccordion: `, parentAccordion);
      
            if (!parentAccordion){ 
                console.error(`Button not found under accordion with ID .acc_header_wrapper[data-folder-id='${folderId}']`);     
                return 
            }    


            if (parentAccordion.getAttribute("aria-expanded") === "false"){
            const mouseEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });

            iconAccordianDiv.dispatchEvent(mouseEvent)
            addEmptyAccordion(folderId);
            } else {
                addEmptyAccordion(folderId);
            }
        
      }

      
      const handleConfirmFolder = async (folderID) => {
        if (window.isCreatingFolder || window.isRenamingFolder || editingFolderName) {
          return;
        }
        if (!folder.isBeingAdded) {
          return;
        }
        window.isCreatingFolder = true;
        const folder_id = newFolderID;
        try {
          const response = await MRFolderAPI.createFolder(
            {
              name,
              folder_id,
            },
            false,
            folder.parent_folder_id 
          );
          console.log('response to confirmation', response);
          const createdFolder = response.data.folder;
          mainRegionLogs.setlogsData((prevLogsData) => {
            const updatedLogsData = { ...prevLogsData };
      
            const updateFolder = (folders) => {
              return folders.map((folder) => {
                if (folder.id === folderID && folder.isBeingAdded) {
                  return {
                    ...folder,
                    id: createdFolder.id, 
                    name: createdFolder.name,
                    user_name: createdFolder.user_name,
                    created_at: createdFolder.created_at,
                    folder_id: createdFolder.folder_id,
                    isNewFolder: false,
                    isBeingAdded: false,
                    isEditing: false,
                  };
                } else if (folder.children && folder.children.length > 0) {
                  return {
                    ...folder,
                    children: updateFolder(folder.children),
                  };
                }
                return folder;
              });
            };
      
            updatedLogsData.data = updatedLogsData.data.map((header) => ({
              ...header,
              data: updateFolder(header.data),
            }));
      
            return updatedLogsData;
          });
      
         
          mainRegionLogs.handleAccordionToggle(createdFolder.id);
      
          setNameConflictError(false);
          setNameIsEditing(false);
        } catch (error) {
          if (error.message === 'Empty Name') {
            setnameErrorMessage('Enter valid folder name');
          } else {
            setnameErrorMessage('Enter a folder name that does not already exist');
          }
          setNameConflictError(true);
          console.log('Error creating folder:', error);
          updateFolderAttribute(folderID, 'isEditing', true);
        } finally {
          window.isCreatingFolder = false;
        }
      };
      const addEmptyAccordion = (folderId = null) => {
        if (window.isAddingNewFolder) {
          return;
        }
        window.isAddingNewFolder = true;
      
        const tempFolderId = generateUUID();
      
        mainRegionLogs.setlogsData((prevLogsData) => {
          const updatedLogsData = { ...prevLogsData };
      
          const newFolder = {
            id: tempFolderId,
            header: 'Folder',
            name: '',
            type: 'FOLDER',
            isNewFolder: true,
            isBeingAdded: true,
            isEditing: true,
            can_delete: true,
            can_rename: true,
            has_children: false,
            parent_folder_id: folderId,
          };
      
          const addFolderToParent = (folders) => {
            return folders.map((folder) => {
              if (folder.id === folderId) {
                return {
                  ...folder,
                  children: folder.children ? [newFolder, ...folder.children] : [newFolder],
                };
              } else if (folder.children && folder.children.length > 0) {
                return {
                  ...folder,
                  children: addFolderToParent(folder.children),
                };
              }
              return folder;
            });
          };
      
          if (folderId) {
            updatedLogsData.data = updatedLogsData.data.map((header) => ({
              ...header,
              data: addFolderToParent(header.data),
            }));
            const parentFolder = updatedLogsData.data
              .flatMap((header) => header.data)
              .find((folder) => folder.id === folderId);
            if (parentFolder && !mainRegionLogs.openItems.includes(parentFolder.id)) {
              mainRegionLogs.setOpenItems([...mainRegionLogs.openItems, parentFolder.id]);
            }
          } else {
            const todayHeader = updatedLogsData.data.find((header) => header.header === 'Today');
            if (todayHeader) {
              const isAddingEmptyFolder = todayHeader.data.some((folder) => folder.isBeingAdded);
              if (!isAddingEmptyFolder) {
                todayHeader.data = [newFolder, ...todayHeader.data];
              }
            }
          }
      
          setTimeout(() => {
            const newElement = document.querySelector(`.acc_header_wrapper[data-folder-id='${tempFolderId}']`);
            if (newElement) {
              newElement.classList.add('css_for_creating_element');
            }
          }, 0);
      
          window.isAddingNewFolder = false;
          return updatedLogsData;
        });
        setTimeout(() => {
          const newFolderElement = document.querySelector(`.acc_header_wrapper[data-folder-id='${tempFolderId}']`);
          if (newFolderElement) {
            const inputElement = newFolderElement.querySelector('input');
            if (inputElement) {
              inputElement.focus();
            }
          }
        }, 100);
      };

    const handleFolderClick = (destinationFolder) => {
        const element = document.querySelector(`.acc_header_wrapper[data-folder-id='${destinationFolder.id}']`);
        const containsClass = element.classList.contains('notAllowedCursor');
       
        if (containsClass){
            mainRegionLogs.resetMove()
            logsContainer.classList.remove('notAllowedCursor');
            return 
        }

        if (mainRegionLogs.moveFolder || mainRegionLogs.moveConversation) {
                MRFolderAPI.move(false, destinationFolder.id, mainRegionLogs.toBeMovedId, mainRegionLogs.tobeMovedType)
                    .then((response) => {
                        console.log("response folder move", response)
                        if (response.data.message === "Folder moved" || response.data.message === "Conversation moved to another folder successfully") {
                            mainRegionLogs.updateLogsDataMoveFolder(destinationFolder, mainRegionLogs.toBeMovedObj)
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching logs data:", error);
                    })
                    .finally(() => {
                        mainRegionLogs.resetMove()
                        logsContainer.classList.remove('notAllowedCursor');
                    }
                    )
        } else {
            console.log("no folder seleted to move")
        }

    }



    const handleRenaming = (folderId) => {
    if (window.isAddingNewFolder) {
        return;
    }   
    setNameIsEditing(true);
    setrenamingFolder(true);
    setEditingFolderName(true)

    mainRegionLogs.setlogsData((prevLogsData) => {
        const updatedLogsData = { ...prevLogsData };
        updatedLogsData.data.forEach(header => {
            let folder = header.data.find(f => f.id === folderId);
            if (folder) {
                folder.isEditing = true;
            }
        });

        return updatedLogsData;
    });
    const folderToEdit = mainRegionLogs.logsData.data
        .flatMap(header => header.data)
        .find(f => f.id === folderId);

    if (folderToEdit) {
        setName(folderToEdit.name);
        setOriginalName(folderToEdit.name); 
    }
    
};

    
  
  

const handleCancelRenaming = (folderID,e) => {
    e.stopPropagation()
    setEditingFolderName(false)
        mainRegionLogs.setlogsData(prevLogsData => {
            const updatedLogsData = { ...prevLogsData };
            updatedLogsData.data.forEach(header => {
                let folder = header.data.find(f => f.id === folderID);
                if (folder) {
                    folder.isEditing = false;
                }
            });
            return updatedLogsData;
        });

        // Reset the name to the original folder name
        const originalFolder = mainRegionLogs.logsData.data
            .flatMap(header => header.data)
            .find(f => f.id === folderID);

        if (originalFolder) {
            setName(originalFolder.name);
        } else {
            setName(folder.name)
        }
        setrenamingFolder(false);
        setNameConflictError(false);
    };


  
const updateFolderAttribute = (folderID, attribute, value) => {
    mainRegionLogs.setlogsData(prevLogsData => {
        const updatedLogsData = { ...prevLogsData };
        updatedLogsData.data.forEach(header => {
            let folder = header.data.find(f => f.id === folderID);
            if (folder) {
                folder[attribute] = value;
            }
        });

        return updatedLogsData;
    });
};

const MainRegionState = useContext(MainRegionContext);
const conversation = useContext(coversationContext);

const handleCreateNewConvo = (folderId) => {

    conversation.resetAiModelValue();
    MainRegionState.setMRSubscription(false);
    MainRegionState.setMRConvoID(0);
    MainRegionState.setMRShow([
        {
            "MRTextHeader": true,
            "MRGigaPrompt": false,
            "MRInputBar": true,
            "MRConversation": true,
            "MRLogs": false
        }
    ]);
    MainRegionState.setFolderID(folder.id)
    navigate('/conversation')
};

const handleCancel = (e) => {
    e.stopPropagation();
  
    mainRegionLogs.setlogsData((prevLogsData) => {
      const updatedLogsData = { ...prevLogsData };
  
      const removeEmptyFolder = (folders) => {
        return folders.filter((folder) => {
          if (folder.children && folder.children.length > 0) {
            folder.children = removeEmptyFolder(folder.children);
          }
          return !(folder.isNewFolder && folder.isBeingAdded);
        });
      };
  
      updatedLogsData.data = updatedLogsData.data.map((header) => ({
        ...header,
        data: removeEmptyFolder(header.data),
      }));
  
      return updatedLogsData;
    });
  
    setIsAddingFolder(false);
    setNameConflictError(false);
    setnameErrorMessage('Enter a folder name that does not already exist');
    setName('');
    setnewFolderID(null);
  };


    const handleEditing = (folderId, e) => {
        e.stopPropagation()
        mainRegionLogs.setlogsData(prevLogsData => {
            const updatedLogsData = { ...prevLogsData };
            let todayHeader = updatedLogsData.data.find(header => header.header === "Today");
            let folder = todayHeader.data.find(f => f.id === folderId);

            if (folder) {
                folder.isEditing = true;
            }
            return updatedLogsData;
        });
    };


    const handleBlur = async (folderID) => {
        if (window.isRenamingFolder) {
            return;
        }
   
        if (!renamingFolder) {
        window.isRenamingFolder = false;
        folder.isBeingAdded  = false
        folder.isEditing = false      
            return;
        }

        window.isRenamingFolder = true;

        try {
            await MRFolderAPI.renameFolder({ folderID, newName: name });
            mainRegionLogs.setlogsData((prevLogsData) => {
                const updatedLogsData = { ...prevLogsData };
                updatedLogsData.data.forEach(header => {
                    let folder = header.data.find(f => f.id === folderID);
                    if (folder) {
                        folder.name = name;
                        folder.isEditing = false;
                    }
                });

                return updatedLogsData;
            });
            setEditingFolderName(false)
            setNameIsEditing(false);
            setrenamingFolder(false);
            setNameConflictError(false);
        } catch (error) {
            if (error.message == 'Empty Name') {
                setnameErrorMessage('Enter valid folder name');
            } else {
                setnameErrorMessage('Enter a folder name that does not already exist');
            }
            if (name !== originalName) {

                setNameConflictError(true);
            }
            console.log('Error creating folder', error.message);
        } finally {
            window.isRenamingFolder = false;
        }
    };




    const handleDelete = async (folderID) => {
        const accordionElement = document.querySelector(`.acc_header_wrapper[data-folder-id='${folderID}']`);
        if (!accordionElement) return;

        accordionElement.classList.add('shake');

        try {
            await MRFolderAPI.deleteFolder(folderID);
            const newLogs = await MRLogsAPI.getLogsData();
            mainRegionLogs.setlogsData(newLogs);
        } catch (error) {
            console.error('Error deleting folder:', error);
        }
    };

    const handleChildCall = async (folder, e) => {
        e.stopPropagation();

        if (folder.has_children) {
            try {
                const response = await MRFolderAPI.fetchChildFolders(false, folder.id)
                console.log("response fetch_child: ", response)
                mainRegionLogs.updateLogsData(response)
                setFetchApiResponse(response)
                setOpenAccordian(true)
            } catch (error) {
                console.error(error);
            } finally {
                setOpenAccordian(!openAccordian)

            }

        } else {
            console.log("Child Folder Not Present")

        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 32 && (folder.isEditing || editingFolderName)) {
          e.preventDefault();
          const { selectionStart, selectionEnd, value } = e.target;
          const newValue =
            value.substring(0, selectionStart) +
            ' ' +
            value.substring(selectionEnd, value.length);
            
          setName(newValue);
          const newCursorPosition = selectionStart + 1;

          setTimeout(() => {
            e.target.setSelectionRange(newCursorPosition, newCursorPosition);
          }, 0);
        }
    };
      
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
        window.removeEventListener('keydown', handleKeyDown);
        };
    }, [editingFolderName, folder.isEditing]);
      

    const handleFolderHeaderClick = (folder) => {
        handleFolderClick(folder);
      };
    
    const handleMoveFolder = (folder) => {       
        if (mainRegionLogs.prevFolder !== null) {
            const prevSelectedDiv = document.querySelector(`[data-folder-id="${mainRegionLogs.prevFolder}"]`);
            if (prevSelectedDiv) {
              prevSelectedDiv.classList.remove('border');
              prevSelectedDiv.classList.remove('copyCursor')
            }
        } 
        if(folder.can_move){      
            mainRegionLogs.setPrevFolder(folder.id); 
            mainRegionLogs.setPrevFolderObj(folder?.parent_folder_id); 
            setFolderKeyId(folder.id)
            setMoveState(true)
            mainRegionLogs.setSelectedState(true)
            mainRegionLogs.setMoveFolder(true)
            mainRegionLogs.setToBeMovedId(folder.id)
            mainRegionLogs.setToBeMovedObj(folder)
            setSelectedFolderId(folder.id)
            setFolderToBeMovedId(folder.id)
            mainRegionLogs.setToBeMovedType('folder')   
            mainRegionLogs.setMoveSelected(true)    
        }else{
            console.error("Folder cannot move")
        }

    }


    const promptDelete = () => {
        setIsDeleting(true);
    };

    const cancelDelete = () => {
        setIsDeleting(false);
    }

    const calculateHeaderButtonClassName = (folder) => {
        const isSelectedAndMoving = mainRegionLogs?.selectedState && mainRegionLogs?.moveSelected;
        const isCurrentFolderSelected = folder.id === mainRegionLogs.prevFolder;
        const shouldHaveBorder = (isSelectedAndMoving && moveState && isCurrentFolderSelected) || moveChildren;
        if (!mainRegionLogs.selectedState){
            mainRegionLogs.setMoveSelected(false)             
        } 
        
        if (shouldHaveBorder){
            if(mainRegionLogs.folderBorder){
                return 'copyCursor'
            }else{         
                return 'notAllowedCursor'
            }
        }else if(mainRegionLogs?.moveSelected){
            if (folder.id === mainRegionLogs?.prevFolderObj){             
                const prevSelectedFolder = document.querySelector(`[data-folder-id="${folder.id}"]`);
                if (prevSelectedFolder){                   
                    return 'notAllowedCursor'    
                }
            }                
            else{
                return 'copyCursor'
            } 
        } 

    }

    const calculateHeaderWrapperClassName = (folder) => {
        
        const isSelectedAndMoving = mainRegionLogs?.selectedState && mainRegionLogs?.moveSelected;
        const isCurrentFolderSelected = folder.id === mainRegionLogs.prevFolder;
        const shouldHaveBorder = (isSelectedAndMoving && moveState && isCurrentFolderSelected) || moveChildren;
        if (!mainRegionLogs.selectedState){
            mainRegionLogs.setMoveSelected(false)             
        } 
        
        if (shouldHaveBorder){
            if(mainRegionLogs.folderBorder){
                return 'border copyCursor'
            }else{         
                return 'notAllowedCursor'
            }
        }else if(mainRegionLogs?.moveSelected){
            if (folder.id === mainRegionLogs?.prevFolderObj){             
                const prevSelectedFolder = document.querySelector(`[data-folder-id="${folder.id}"]`);
                if (prevSelectedFolder){                  
                    return 'notAllowedCursor'    
                }
            }                
            else{
                return 'border copyCursor'
            } 
        } 
    };
    // prevents the accordion from expanding/collapsing when the user is renaming a folder
  useEffect(() => {
    const handleClickEvents = (event) => {
      if (event.target.closest('.titleWrap') && ( renamingFolder)) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
    document.addEventListener('click', handleClickEvents, true);
    return () => {
      document.removeEventListener('click', handleClickEvents, true);
    };
  }, [folder.isEditing, editingFolderName, renamingFolder]);
return (
    <>
        <Accordion key={folder.id}>
            <AccordionItem>
                <div
                    key={folder.id}
                    className={`acc_header_wrapper ${calculateHeaderWrapperClassName(folder)}`}
                    data-folder-id={folder.id}
                    onClick={() => handleFolderHeaderClick(folder)}
                >
                    <AccordionHeader
                        className={`titleWrap ${calculateHeaderButtonClassName(folder)}`}
                        onClick={(event) => {
                            if (!folder.isEditing && !editingFolderName && !renamingFolder) {
                                mainRegionLogs.handleAccordionToggle(folder.id, folder);
                                handleFolderHeaderClick(folder, event);
                            }
                        }}
                        onKeyDown={(event) => {
                            if (event.key === ' ' && !folder.isEditing && !editingFolderName && !renamingFolder) {
                                event.preventDefault();
                                mainRegionLogs.handleAccordionToggle(folder.id, folder);
                                handleFolderHeaderClick(folder, event);
                            }
                        }}
                    >

                        <div className="acco_col">
                            <div
                                className={`AccoIcon iconAccodian ${fetchApiResponse && mainRegionLogs.openItems.includes(folder.id) && 'rotate-180'}`}
                                id = "iconAccodianDiv"
                                onClick={(e) => {
                                    if (!renamingFolder) {
                                        handleChildCall(folder, e);
                                    }
                                }}
                            >
                                {folder.has_children && <IconAccordion width="20" height="12" />}
                            </div>
                            <div className="Acco_title_box">
                                {isDeleting ? (
                                    <span className="subTitle redConfirmationText">Please confirm you want to delete this folder and all its contents</span>
                                ) : nameConflictError ? (
                                    <span className="subTitle redConfirmationText">{nameErrorMessage}</span>
                                ) : (
                                    <span className="subTitle">{folder.header}</span>
                                )}
                                <span className="accordion-title">
                                    <EditableText
                                        name={name}
                                        setName={setName}
                                        searchedName={conversation.searched}
                                        isEditing={folder.isEditing || editingFolderName}
                                        handleEditing={() => folder.isEditing || editingFolderName && handleRenaming(folder.id)}
                                        canRename={folder.can_rename}
                                    >
                                        {folder.name}
                                    </EditableText>
                                </span>
                            </div>
                        </div>
                    </AccordionHeader>
                    {(folder.isBeingAdded || folder.isEditing || editingFolderName) ? (
                        <div className="MRLog_button_wrapper MRCprompt_wrap d-flex align-items-center">
                            <div className={`AccoIcon customIcon icon-confirm ${name.trim() === '' || folder.name === name ? 'disabledIcon' : ''}`} onClick={() => name.trim() !== '' && handleConfirmFolder(folder.id)}>
                                {folder.isBeingAdded ? (
                                    <div className='' onClick={() => name.trim() !== '' && handleConfirmFolder(folder.id)}>
                                        <IconConfirmFolder color={name.trim() === '' ? '#C7C7C7' : '#5DCDF5'} />
                                    </div>
                                ) : (
                                    <div className='' onClick={() => name.trim() !== '' && handleBlur(folder.id)}>
                                        <IconConfirmFolder color={name.trim() === '' || folder.name === name ? '#C7C7C7' : '#5DCDF5'} />
                                    </div>
                                )}

                            </div>


                            <div className="AccoIcon customIcon" onClick={folder.isBeingAdded ? (e) => handleCancel(e) : folder.isEditing || editingFolderName ? (e) => handleCancelRenaming(folder.id, e) : (e) => handleEditing(folder.id, e)}>

                                <IconCancelConfirmationFolder />
                            </div>
                        </div>
                    ) : isDeleting ? (
                        <div className="MRLog_button_wrapper MRCprompt_wrap d-flex align-items-center">
                            <div className="AccoIcon customIcon" onClick={() => handleDelete(folder.id)}>
                                <IconsAreYouSureDelete />
                            </div>
                            <div className="AccoIcon customIcon" onClick={cancelDelete}>
                                <IconsAreYouSureCancel />
                            </div>
                        </div>
                    ) : (
                        <div className="MRLog_button_wrapper MRCprompt_wrap d-flex align-items-center">
                            {
                                ((mainRegionLogs?.selectedState && moveState && folder.id === mainRegionLogs.prevFolder) || moveChildren) ?
                                    <button className={`AccoIcon customIcon btn btn-primary ${calculateHeaderButtonClassName(folder)}`}>
                                        <IconCheckMark />
                                    </button>
                                    :
                                    <button className={`AccoIcon customIcon btn btn-primary ${calculateHeaderButtonClassName(folder)}`} onClick={() => handleCreateNewConvo(folder.id)}>
                                        <IconAddFolderCyan />
                                    </button>
                            }
                            <MRAKabob
                                {...(folder.can_rename && folder.name !== "Default" ? { rename: () => handleRenaming(folder.id) } : {})}
                                copyText={folder.name}
                                {...(folder.can_delete && folder.name !== "Default" ? { deleteConvo: promptDelete } : {})}
                                addConvo={() => handleCreateNewConvo(folder.id)}
                                addFolder={() => handleAddNewFolder(folder.id)}
                                {...(folder.name !== "Default" ? { move: () => handleMoveFolder(folder) } : {})}
                            />
                        </div>
                    )}
                </div>
                <AccordionBody>
                    <div className="accordion-body">

                        {folder?.children && folder?.children?.map(convo => (
                            convo.type === 'PARENT_CONVERSATION' ? (
                                <div className="mb-20" key={convo.id}>

                                    <MRLAccordionPar convo={convo} folderID={folder.id} moveChildren={moveState} />
                                </div>
                            ) : convo.type === 'FOLDER' ? (
                                <MRLAccordionFolder key={convo.id} folder={convo} moveChildren={moveState} />
                            ) : null
                        ))}
                    </div>
                </AccordionBody>

            </AccordionItem>
        </Accordion>

    </>
);
};

export default MRLAccordionFolder;
