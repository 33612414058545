const ConfirmIcon = ({ color = "#5DCDF5" }) => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="9.54536" height="2.12119" rx="1" 
                  transform="matrix(0.692072 0.721828 -0.692072 0.721828 1.46826 7.57812)" 
                  fill={color} />
            <rect width="18.4033" height="2.11129" rx="1" 
                  transform="matrix(-0.607877 0.794031 -0.768421 -0.639945 18 1.35156)" 
                  fill={color} />
        </svg>
    );
};

export default ConfirmIcon;
