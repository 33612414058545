
import * as React from "react"

function IconVariations(props) {
  return (
    <svg className={props.class}
      width={props.width}
      height={props.height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.3818 19.125H5.57586C4.68861 19.125 3.8377 19.4806 3.21031 20.1135C2.58293 20.7464 2.23047 21.6049 2.23047 22.5V30.375C2.23047 31.2701 2.58293 32.1285 3.21031 32.7615C3.8377 33.3944 4.68861 33.75 5.57586 33.75H13.3818C14.269 33.75 15.12 33.3944 15.7473 32.7615C16.3747 32.1285 16.7272 31.2701 16.7272 30.375V22.5C16.7272 21.6049 16.3747 20.7464 15.7473 20.1135C15.12 19.4806 14.269 19.125 13.3818 19.125ZM14.4969 30.375C14.4969 30.6734 14.3794 30.9595 14.1703 31.1705C13.9612 31.3815 13.6775 31.5 13.3818 31.5H5.57586C5.28011 31.5 4.99647 31.3815 4.78735 31.1705C4.57822 30.9595 4.46073 30.6734 4.46073 30.375V22.5C4.46073 22.2016 4.57822 21.9155 4.78735 21.7045C4.99647 21.4935 5.28011 21.375 5.57586 21.375H13.3818C13.6775 21.375 13.9612 21.4935 14.1703 21.7045C14.3794 21.9155 14.4969 22.2016 14.4969 22.5V30.375Z" />
      <path d="M26.2054 19.125C24.7718 19.125 23.3704 19.5539 22.1784 20.3574C20.9864 21.1609 20.0574 22.3029 19.5088 23.6391C18.9602 24.9753 18.8166 26.4456 19.0963 27.8641C19.376 29.2826 20.0663 30.5855 21.08 31.6082C22.0937 32.6309 23.3853 33.3273 24.7913 33.6095C26.1973 33.8916 27.6548 33.7468 28.9792 33.1934C30.3037 32.6399 31.4357 31.7026 32.2322 30.5001C33.0286 29.2976 33.4537 27.8838 33.4537 26.4375C33.4514 24.4988 32.687 22.6403 31.3281 21.2694C29.9693 19.8986 28.127 19.1274 26.2054 19.125ZM26.2054 31.5C25.2129 31.5 24.2427 31.2031 23.4175 30.6468C22.5923 30.0905 21.9491 29.2999 21.5693 28.3748C21.1895 27.4498 21.0901 26.4319 21.2837 25.4499C21.4773 24.4678 21.9553 23.5658 22.6571 22.8578C23.3589 22.1498 24.253 21.6676 25.2264 21.4723C26.1998 21.2769 27.2088 21.3772 28.1257 21.7604C29.0427 22.1435 29.8264 22.7924 30.3778 23.6249C30.9292 24.4574 31.2235 25.4362 31.2235 26.4375C31.222 27.7797 30.6928 29.0665 29.7521 30.0156C28.8113 30.9647 27.5358 31.4985 26.2054 31.5Z" />
      <path d="M12.9116 16.875H22.7716C23.3668 16.8749 23.9511 16.7147 24.4645 16.4109C24.9778 16.107 25.4014 15.6705 25.6918 15.1464C25.9821 14.6223 26.1286 14.0295 26.1161 13.4292C26.1036 12.8289 25.9326 12.2428 25.6208 11.7315L20.6908 3.64833C20.3914 3.15743 19.9723 2.75208 19.4735 2.47091C18.9748 2.18974 18.413 2.04211 17.8416 2.04211C17.2703 2.04211 16.7085 2.18974 16.2097 2.47091C15.711 2.75208 15.2919 3.15743 14.9925 3.64833L10.0625 11.7315C9.75061 12.2428 9.57962 12.8289 9.56714 13.4292C9.55466 14.0295 9.70114 14.6223 9.99148 15.1464C10.2818 15.6705 10.7055 16.107 11.2188 16.4109C11.7321 16.7147 12.3165 16.8749 12.9116 16.875ZM11.9627 12.9116L16.8915 4.82846C16.9915 4.66566 17.1311 4.53131 17.297 4.43815C17.4628 4.34499 17.6495 4.29609 17.8394 4.29609C18.0292 4.29609 18.2159 4.34499 18.3818 4.43815C18.5477 4.53131 18.6872 4.66566 18.7873 4.82846L23.7161 12.9116C23.8196 13.0816 23.8763 13.2764 23.8806 13.4759C23.8848 13.6754 23.8364 13.8724 23.7402 14.0468C23.6441 14.2211 23.5038 14.3666 23.3335 14.4682C23.1633 14.5698 22.9694 14.6239 22.7716 14.625H12.9116C12.7135 14.6247 12.519 14.5712 12.3481 14.4699C12.1773 14.3686 12.0363 14.2232 11.9396 14.0487C11.843 13.8742 11.7942 13.6769 11.7982 13.477C11.8023 13.2771 11.859 13.082 11.9627 12.9116Z" />
    </svg>
  )
}

export default IconVariations
