// src/helper/configs/axiosConfigs.js
import axios from "axios"
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

export const api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers:{'Authorization' : Cookies.get('token')}
})

const isTokenValid = () => {
  const token = Cookies.get('token');
  if (token) {
      try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          return decodedToken.exp > currentTime;
      } catch (error) {
          console.log('Invalid token');
      }
  }
  return false;
};

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status
  if (statusCode && statusCode == 401 && (!isTokenValid()))
  {
    const token = Cookies.get('token');
    Cookies.remove('token');
    redirectToLogin()
  }
  // logging only errors that are not 401
  else if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

const redirectToLogin = () => {
  window.location.href = '/login';
};

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})