import React, { useContext,useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import ConversationContext from '../../context/conversationContext';

export default function MRISearchInputBar() {
  const context = useContext(ConversationContext);

  const handleFormChange = (e) => {
    context.setSearchInputBar(e.target.value);
  };

  const focusInput = () => {
    if (context.setIsFocused) {
        console.log('set focus true')
      context.setIsFocused(true);
    }
  };

  const blurInput = () => {
    if (context.setIsFocused) {
        console.log('set focus false')
      context.setIsFocused(false);
    }
  };
  return(
    <>
      <TextareaAutosize
        className='form-control GPInputText'
        as="textarea"
        aria-label="Prompt textarea"
        placeholder='enter text HERE to begin'
        style={{ height: '30px', verticalAlign: 'middle' }}
        value={context.searchInputBar}
        onFocus={focusInput}
        onBlur={blurInput}
        onChange={(e) => handleFormChange(e)}
      />
    </>
  );
};
