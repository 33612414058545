
import * as React from "react"

function IconNewConversation(props) {
  return (
    <svg className="icon icon-newfolder icon_custom_bg"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={props.fill} clipPath="url(#clip0_1304_48658)">
        <path d="M20.42.814H3.58A3.584 3.584 0 000 4.394V15.08a3.586 3.586 0 002.972 3.528v3.83a.75.75 0 001.22.584l5.419-4.362h10.81A3.584 3.584 0 0024 15.079V4.395a3.584 3.584 0 00-3.58-3.58zM22.5 15.08a2.082 2.082 0 01-2.08 2.08H9.346a.75.75 0 00-.47.166L4.472 20.87v-2.962a.75.75 0 00-.75-.75H3.58a2.082 2.082 0 01-2.08-2.08V4.395a2.083 2.083 0 012.08-2.08h16.84a2.082 2.082 0 012.08 2.08v10.684z"></path>
        <rect width="2" height="8" x="11" y="6" rx="1"></rect>
        <rect
          width="2"
          height="8"
          x="16"
          y="9"
          rx="1"
          transform="rotate(90 16 9)"
        ></rect>
      </g>
      <defs>
        <clipPath id="clip0_1304_48658">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}


export default IconNewConversation
