import React, { useState, useContext, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import coversationContext from '../../../context/conversationContext';
import ProfileMenuContext from '../../profile-menu/PMContext';

export default function MRITextInput({ setInputBarFocused, expand, viewOneConvo, InputBarFocused, setCurrentTextRow , setExpand}) {
  const [textareaheight, setTextareaheight] = useState(1);
  const context = useContext(coversationContext);
  const pmState = useContext(ProfileMenuContext);
  const inputRef = useRef(null);
  const [currentRow, setCurrentRow] = useState(1)
  const [toggleState, setToggleState] = useState(expand)
  const [lineHeight, setLineHeight] = useState(1)
  const handleFormChange = (e) => {
    const textarea = inputRef.current;
    if (!textarea) return;
  
    const { scrollHeight, clientHeight, scrollTop } = textarea;
    const lineHeightValue = parseInt(getComputedStyle(textarea).lineHeight);
    setLineHeight(lineHeightValue);
  
    const newCurrentRow = Math.floor((scrollHeight - scrollTop) / lineHeightValue);
  
    if (e.target.value === null || e.target.value === '' || e.target.value.length <= 139 || e.target.value.split('\n').length > 1) {
      if (e.target.value.length <= 77 ||  e.target.value.split('\n').length < 2) {
        setExpand(false)
        setCurrentRow(e.target.value.split('\n').length);
      } else {
        setCurrentRow(e.target.value.split('\n').length + (e.target.value.length / 77));
      }
    } else {
      setCurrentRow(newCurrentRow);
    }
    setCurrentTextRow(newCurrentRow);
    context.setPrompt(e.target.value);
    pmState.setWithoutFocus(false);
  };

  useEffect(() => {
    setCurrentTextRow(currentRow);
  }, [currentRow])

  const handleBlur = () => {
    if (context.prompt || !pmState.credits) {
      pmState.setWithoutFocus(true);
    }
    setInputBarFocused(false);
  };

  const handleFocus = () => {
    if (context.prompt || !pmState.credits) {
      pmState.setWithoutFocus(false);
    }
    setInputBarFocused(true);
  };

  useEffect(() => {
    if (InputBarFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [InputBarFocused]);

  useEffect(() => {
    if (expand) {
      const viewportHeight = window.innerHeight;
      const expandedHeight = viewportHeight * 0.8;
      setTextareaheight(expandedHeight);
    } else if (!expand && currentRow > 10 && context.prompt.length > 500 ) {
      setTextareaheight(250);
    }
    else if (context.prompt.length < 500){
      if (context.prompt.split('\n').length > 1) {
        setTextareaheight(context.prompt.split('\n').length + (context.prompt.length) * lineHeight + 50)
      }
      else {
      setTextareaheight(context.prompt.split('\n').length + (context.prompt.length / 50) * lineHeight + 15);
    }
    }
    else {
      setTextareaheight(currentRow * lineHeight + 15);
    }
  }, [expand, toggleState]);



  return (
    <>
      <TextareaAutosize
        ref={inputRef}
        className={`form-control ${!expand ? 'GPInputText' : (viewOneConvo ? 'GPInputTextexisting' : 'GPInputTextnew')}`}
        as="textarea"
        aria-label="Prompt textarea"
        placeholder="enter text HERE to begin"
        // onKeyPress={handleKeyPress}
        style={{ height: textareaheight, verticalAlign: 'middle' }}
        value={context.prompt}
        onChange={(e) => handleFormChange(e)}
        onFocus={handleFocus}
        onBlur={handleBlur}

      />
    </>
  );
}
