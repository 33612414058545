
const IconEyeInvisible = (props) => {
    return (
        <>
            <div onClick={props?.onClick}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8983 7.68809C15.7554 7.49256 12.3496 2.90039 7.99992 2.90039C3.65019 2.90039 0.244313 7.49256 0.101531 7.6879C-0.0338438 7.8734 -0.0338438 8.12499 0.101531 8.3105C0.244313 8.50603 3.65019 13.0982 7.99992 13.0982C12.3496 13.0982 15.7554 8.506 15.8983 8.31065C16.0339 8.12518 16.0339 7.8734 15.8983 7.68809ZM7.99992 12.0433C4.79588 12.0433 2.02085 8.99534 1.19938 7.99893C2.01979 7.00165 4.78901 3.95533 7.99992 3.95533C11.2038 3.95533 13.9787 7.00271 14.8005 7.99965C13.9801 8.9969 11.2108 12.0433 7.99992 12.0433Z" fill="#C7C7C7" />
                    <path d="M7.99981 4.83594C6.25474 4.83594 4.83496 6.25572 4.83496 8.00079C4.83496 9.74585 6.25474 11.1656 7.99981 11.1656C9.74488 11.1656 11.1647 9.74585 11.1647 8.00079C11.1647 6.25572 9.74488 4.83594 7.99981 4.83594ZM7.99981 10.1107C6.83637 10.1107 5.88993 9.1642 5.88993 8.00079C5.88993 6.83738 6.8364 5.89091 7.99981 5.89091C9.16322 5.89091 10.1097 6.83738 10.1097 8.00079C10.1097 9.1642 9.16325 10.1107 7.99981 10.1107Z" fill="#C7C7C7" />
                    <rect x="11.7119" y="0.548828" width="1" height="16.3052" rx="0.5" transform="rotate(28.2302 11.7119 0.548828)" fill="#C7C7C7" />
                </svg>
            </div>
        </>
    );
};

export default IconEyeInvisible;