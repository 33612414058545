import React from 'react';
import IconArrowDown from "../../icons/IconArrowDown";
import IconArrowUp from "../../icons/IconArrowUp";



function HPmultiplierInput({ children, value , setValue }) {

    const handleChange = (e) => {
        setValue(parseInt(e.target.value));


    }


    function handleBlur(e, setValue) {
        const inputValue = e.target.value.trim();
        if (inputValue === '' || inputValue === null) {
            setValue(0);
        }
    }

    function increment() {
        setValue(prevValue => prevValue + 1);
    };

    function decrement() {
        if (value > 0) {
            setValue(prevValue => prevValue - 1);
        }
    };
    /*
    Moved Conversation Context setting to state
    useEffect(() => {
        if (children === "Set Loop(s)") {
            conversation.update({ boosterValue: value });
        } else {
            conversation.update({ multiplierValue: value });
        }
    }, [children, value]);
    */


    return (
        <>
            <div className="hp-mulitplier-input">
                <h5 className="hp-title-sub">{children}</h5>
                <div className="hpm-input-wrap">
                    <input className="HPinputNum" type="number" onBlur={(e) => handleBlur(e, setValue)}
                        onChange={(e) => handleChange(e)} value={value} min={0} />
                    <div className="arrow-box">
                        <span className="arrowUp countArrow" onClick={increment}>
                            <IconArrowUp width='22' height='16' />
                        </span>
                        <span className="arrowdown countArrow" onClick={decrement}>
                            <IconArrowDown width='22' height='16' />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};


export default HPmultiplierInput;