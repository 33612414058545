function UserContent({ userTitle, userIcon, userDsc, onClick }) {

    const handleClick = () => {
        onClick(userTitle);
    };


    return (
        <>
            <button onClick={handleClick} className={`usertype userContent_container `}>
                <div className="userIcon">
                    <img src={userIcon} alt="" />
                </div>
                <div className="userDetails">
                    <div className="content">
                        <span className="user_title">{userTitle}</span>
                        <p>{userDsc}</p>
                    </div>
                </div>
            </button>
        </>
    );
}

export default UserContent;