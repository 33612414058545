const Icon = (props) => {
    return (
        <>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.0286 3.69619H8.96455C8.75898 3.69619 8.5582 3.61178 8.41955 3.46831L6.83946 1.80381C6.45964 1.42522 5.93999 1.22747 5.41368 1.2305H1.97137C0.884405 1.23047 0 2.11488 0 3.20181V12.7994C0 13.8864 0.884405 14.7708 1.97137 14.7708H14.0358H14.0377C15.124 14.7659 16.0048 13.8773 16 12.7934V5.66753C16 4.58056 15.1156 3.69619 14.0286 3.69619ZM14.7943 12.7958C14.7961 13.2178 14.4543 13.5632 14.0347 13.565H1.97137C1.54937 13.565 1.20575 13.2214 1.20575 12.7994V3.20181C1.20575 2.77981 1.54937 2.43619 1.97137 2.43556H5.42937C5.43599 2.43556 5.44262 2.43556 5.44927 2.43556C5.65061 2.43556 5.84596 2.51575 5.97736 2.64597L7.54902 4.30262C7.91795 4.68362 8.43402 4.90187 8.96455 4.90187H14.0286C14.4506 4.90187 14.7942 5.2455 14.7942 5.66749V12.7958H14.7943Z" fill="#060E1E"/>
<rect x="7.33325" y="6" width="1.33333" height="5.33333" rx="0.666667" fill="#060E1E"/>
<rect x="10.6667" y="8" width="1.33333" height="5.33333" rx="0.666667" transform="rotate(90 10.6667 8)" fill="#060E1E"/>
</svg>




        </>
    );
};

export default Icon;