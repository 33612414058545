const IconCopy = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                <path d="M2.45932 9.2068C2.45932 8.4468 2.45932 7.69346 2.45932 6.93346C2.45932 6.05346 2.44598 5.18013 2.45932 4.30013C2.47265 3.60013 2.78598 3.06013 3.39931 2.70013C3.61932 2.57346 3.85265 2.49346 4.10598 2.48013C4.20598 2.48013 4.30598 2.4668 4.40598 2.4668C5.09932 2.4668 5.79265 2.4668 6.49265 2.4668C8.26598 2.4668 10.0326 2.4668 11.806 2.4668C12.2193 2.4668 12.5793 2.63346 12.8926 2.90013C13.1726 3.14013 13.3793 3.43346 13.466 3.80013C13.506 3.96013 13.5326 4.1268 13.5326 4.29346C13.5326 7.5668 13.5326 10.8468 13.5326 14.1201C13.5326 15.0201 12.9326 15.7935 11.9926 15.9668C11.8926 15.9868 11.786 15.9935 11.6793 15.9935C9.23265 15.9935 6.79265 15.9935 4.34598 15.9935C4.07932 15.9935 3.81931 15.9535 3.57931 15.8468C3.15931 15.6601 2.83265 15.3601 2.63265 14.9335C2.50598 14.6601 2.44598 14.3801 2.45265 14.0801C2.45265 12.4535 2.45265 10.8268 2.45265 9.20013L2.45932 9.2068ZM3.69932 9.23346C3.69932 10.8535 3.69932 12.4735 3.69932 14.0935C3.69932 14.1801 3.69931 14.2668 3.73265 14.3535C3.79265 14.5268 3.90598 14.6601 4.08598 14.7135C4.19932 14.7468 4.32598 14.7601 4.44598 14.7668C5.77265 14.7668 7.09932 14.7668 8.42598 14.7668C9.51265 14.7668 10.5926 14.7668 11.6793 14.7668C11.9926 14.7668 12.2993 14.5468 12.2993 14.1401C12.2993 10.8668 12.2993 7.59346 12.2993 4.32013C12.2993 4.1668 12.246 4.02013 12.1393 3.9068C11.9993 3.75346 11.8193 3.7068 11.6193 3.70013C10.666 3.70013 9.71265 3.69346 8.75932 3.69346C7.83265 3.69346 6.91265 3.70013 5.98598 3.70013C5.43265 3.70013 4.88598 3.70013 4.33265 3.70013C4.13265 3.70013 3.96598 3.77346 3.83265 3.9268C3.72598 4.05346 3.69265 4.2068 3.69265 4.37346C3.69265 5.99346 3.69265 7.61346 3.69265 9.23346H3.69932Z" fill="#060E1E" />
                <path d="M1.24643 5.53333C1.24643 6.75333 1.24643 7.97333 1.24643 9.18667C1.24643 9.45333 1.13977 9.66 0.893099 9.78667C0.539766 9.96 0.153099 9.75333 0.0464323 9.43333C0.0197656 9.36 0.013099 9.27333 0.013099 9.2C0.013099 7.47333 0.013099 5.74 0.013099 4.01333C0.013099 3.24 -0.000234376 2.47333 0.0264323 1.7C0.0397656 1.21333 0.253099 0.8 0.626432 0.48C0.873099 0.26 1.15977 0.1 1.48643 0.04C1.6131 0.0133333 1.73977 0 1.86643 0C3.89977 0 5.9331 0 7.96643 0C8.2131 0 8.41977 0.0866667 8.53977 0.313333C8.7731 0.733333 8.46643 1.24 7.9931 1.24C5.97977 1.24 3.96643 1.24 1.94643 1.24C1.81977 1.24 1.69977 1.24667 1.58643 1.30667C1.3531 1.42 1.24643 1.61333 1.23977 1.86C1.23977 3.08667 1.23977 4.31333 1.23977 5.54L1.24643 5.53333Z" fill="#060E1E" />
            </svg>
        </>
    );
};

export default IconCopy;