const Icon = (props) => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M12.6665 4.66602C12.4897 4.66602 12.3201 4.73625 12.1951 4.86128C12.0701 4.9863 11.9998 5.15587 11.9998 5.33268V12.7933C11.9807 13.1305 11.8292 13.4464 11.5782 13.6723C11.3272 13.8982 10.9971 14.0157 10.6598 13.9993H5.33984C5.00257 14.0157 4.67249 13.8982 4.42151 13.6723C4.17052 13.4464 4.01896 13.1305 3.99984 12.7933V5.33268C3.99984 5.15587 3.9296 4.9863 3.80457 4.86128C3.67955 4.73625 3.50998 4.66602 3.33317 4.66602C3.15636 4.66602 2.98679 4.73625 2.86177 4.86128C2.73674 4.9863 2.6665 5.15587 2.6665 5.33268V12.7933C2.68553 13.4842 2.97756 14.1393 3.47862 14.6152C3.97969 15.0912 4.64895 15.3492 5.33984 15.3327H10.6598C11.3507 15.3492 12.02 15.0912 12.5211 14.6152C13.0221 14.1393 13.3141 13.4842 13.3332 12.7933V5.33268C13.3332 5.15587 13.2629 4.9863 13.1379 4.86128C13.0129 4.73625 12.8433 4.66602 12.6665 4.66602Z" fill="#EB2834"/>
<path d="M13.3333 2.66602H10.6667V1.33268C10.6667 1.15587 10.5964 0.986302 10.4714 0.861278C10.3464 0.736253 10.1768 0.666016 10 0.666016H6C5.82319 0.666016 5.65362 0.736253 5.5286 0.861278C5.40357 0.986302 5.33333 1.15587 5.33333 1.33268V2.66602H2.66667C2.48986 2.66602 2.32029 2.73625 2.19526 2.86128C2.07024 2.9863 2 3.15587 2 3.33268C2 3.50949 2.07024 3.67906 2.19526 3.80409C2.32029 3.92911 2.48986 3.99935 2.66667 3.99935H13.3333C13.5101 3.99935 13.6797 3.92911 13.8047 3.80409C13.9298 3.67906 14 3.50949 14 3.33268C14 3.15587 13.9298 2.9863 13.8047 2.86128C13.6797 2.73625 13.5101 2.66602 13.3333 2.66602ZM6.66667 2.66602V1.99935H9.33333V2.66602H6.66667Z" fill="#EB2834"/>
<path d="M7.33333 11.3333V6.66667C7.33333 6.48986 7.2631 6.32029 7.13807 6.19526C7.01305 6.07024 6.84348 6 6.66667 6C6.48986 6 6.32029 6.07024 6.19526 6.19526C6.07024 6.32029 6 6.48986 6 6.66667V11.3333C6 11.5101 6.07024 11.6797 6.19526 11.8047C6.32029 11.9298 6.48986 12 6.66667 12C6.84348 12 7.01305 11.9298 7.13807 11.8047C7.2631 11.6797 7.33333 11.5101 7.33333 11.3333Z" fill="#EB2834"/>
<path d="M9.99984 11.3333V6.66667C9.99984 6.48986 9.9296 6.32029 9.80457 6.19526C9.67955 6.07024 9.50998 6 9.33317 6C9.15636 6 8.98679 6.07024 8.86177 6.19526C8.73674 6.32029 8.6665 6.48986 8.6665 6.66667V11.3333C8.6665 11.5101 8.73674 11.6797 8.86177 11.8047C8.98679 11.9298 9.15636 12 9.33317 12C9.50998 12 9.67955 11.9298 9.80457 11.8047C9.9296 11.6797 9.99984 11.5101 9.99984 11.3333Z" fill="#EB2834"/>
</svg>

        </>
    );
};

export default Icon;