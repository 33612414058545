import Dropdown from 'react-bootstrap/Dropdown';
import IconKabob from "../../icons/IconKabob";
import IconCopy from '../../icons/IconCopy';
import IconAddConvo from '../../icons/LogsIcons/IconAddConvo';
import IconAddFolder from '../../icons/LogsIcons/IconAddFolderKabob';
import IconDelete from '../../icons/LogsIcons/IconDelete';
import IconRename from '../../icons/LogsIcons/IconRename';
import IconCloneConversation from '../../icons/Clone.png'
import IconPublicConvo from '../../icons/LogsIcons/IconPublicConvo'
import IconPrivateConvo from '../../icons/LogsIcons/IconPrivateConvo'
import MoveIcon from '../../../images/Move.svg'
import Share from '../../../images/share.svg'
import Group from '../../../images/Group.svg'
import { MRLogsAPI } from "../../../helper/MRLogsAPI"
import ReactDOM from 'react-dom';
import MRLContext from '../MRLogs/MRLContext';
import { useContext } from 'react';

const copyText = async (props) => {
  const copyText = props;
  try {
    await navigator.clipboard.writeText(copyText);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

const createImageName = (prompt) => {
  const text =  prompt?.length > 20 ?  prompt.slice(0, 20) : prompt
  const cleanName = text?.replace(/[^a-zA-Z0-9\s]/g, '')?.replace(/\s+/g, '_');
  const lowercaseName = cleanName?.toLowerCase();
  const imageName = `${lowercaseName}.png`;
  return imageName;
}

const toDataURL = async (downloadUrl) => {
  const blob = await fetch(downloadUrl, { mode: "cors" }).then(res => res.blob());
  return URL.createObjectURL(blob);
}

const downloadImage = async (downloadUrl, receipt) => {
  const a = document.createElement("a");
  const title =  createImageName(receipt)
  a.href = await toDataURL(downloadUrl);
  a.download = title != "undefined.png" ? title : "ai_image.png";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const handleDownload = async (downloadUrl, receipt) => {
  await downloadImage(downloadUrl , receipt)
};

function MRAKabob(props) {
  const logsContext = useContext(MRLContext);

  const changePrivacy = (convoId, privacy) => {
    MRLogsAPI.changePrivacy(false, convoId, !privacy)
    .then((response) => {
      console.log(response)
      logsContext.updateIsPublic(convoId, !privacy)
    })
    .catch((error) => {
      console.error("Error fetching logs data:", error);
    })
     
  } 
  
  const handleKabobClick = (event) => {
    if (props.onClick) {
      props.onClick(); 
    }
  };

  return (
    <div className={`kabob_btn`} onClick={handleKabobClick}> 
      <Dropdown drop="end">
        <Dropdown.Toggle>
          <IconKabob />
        </Dropdown.Toggle>
        {ReactDOM.createPortal(
          <Dropdown.Menu className="kabob-dropdown-menu">
            {props.copyShareUrl && (
              <Dropdown.Item eventKey="" onClick={() => { copyText(props.copyShareUrl) }} disabled={false}>
                <IconCopy />
                <span className="linkText">Copy Link</span>
              </Dropdown.Item>
            )}
            {props.clone && (
              <Dropdown.Item eventKey="" onClick={props.clone} disabled={false}>
                 <img style={{width: "11%"}} src={IconCloneConversation} />
                <span className="linkText">Clone</span>
              </Dropdown.Item>
            )}    
            {props.copyText && (
              <Dropdown.Item eventKey="" onClick={() => { copyText(props.copyText) }} disabled={false}>
                <IconCopy />
                <span className="linkText">Copy</span>
              </Dropdown.Item>
            )}
            {props.convo_uuid  && (
              <Dropdown.Item eventKey="" onClick={() => { changePrivacy(props.convo_uuid, props.markPrivacy) }} disabled={false}>
               { props.markPrivacy ? <IconPrivateConvo /> : <IconPublicConvo />}
                <span className="linkText">{props.markPrivacy ? 'Make Private' : 'Make Public'}</span>
              </Dropdown.Item>
            )}
            {props.downloadImg && (
              <Dropdown.Item onClick={() => { handleDownload(props.downloadImg, props.receipt) }} disabled={false}>
                <IconCopy />
                <span className="linkText">Download</span>
              </Dropdown.Item>
            )}
            {props.rename && (
              <Dropdown.Item eventKey="" onClick={props.rename} disabled={false}>
                <IconRename />
                <span className="linkText">Rename</span>
              </Dropdown.Item>
            )}
            {props.deleteConvo && (
              <Dropdown.Item eventKey="" onClick={props.deleteConvo} disabled={false}>
                <IconDelete />
                <span className="linkText">Delete</span>
              </Dropdown.Item>
            )}
            {props.move && (
              <Dropdown.Item eventKey="" onClick={props.move} disabled={false}>
                <img src={MoveIcon} alt=""  />
                <span className="linkText">Move</span>
              </Dropdown.Item>
            )}
            {props.addConvo && (
              <Dropdown.Item eventKey="" onClick={props.addConvo} disabled={false}>
                <IconAddConvo />
                <span className="linkText">Add Conversation</span>
              </Dropdown.Item>
            )}
            {props.addFolder && (
              <Dropdown.Item eventKey="" onClick={props.addFolder} disabled={false}>
                <IconAddFolder />
                <span className="linkText">Add Folder</span>
              </Dropdown.Item>
            )}
            {props.moveToConversation && (
              <Dropdown.Item eventKey="" onClick={props.moveToConversation} disabled={false}>
                <img src={Group} alt=""  />
                <span className="linkText">Move To Conversation</span>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>,
          document.body
        )}
      </Dropdown>
    </div>
  );
}

export default MRAKabob;
