import { configureStore } from '@reduxjs/toolkit'
import conversationReducers from '../slices/conversationSlice'
import logsReducer from '../slices/logsSlice';

export const store = configureStore({
  reducer: {
    conversation: conversationReducers,
    logs: logsReducer
  },
  
})