import { useIsAuthenticated } from 'react-auth-kit';
import HMMenu from './components/hyper-menu/HMMenu';
import MRState from './components/main-region/General/MRState';
import MainRegion from './components/main-region/MainRegion';
import PMMenu from './components/profile-menu/PMMenu';
import PMState from './components/profile-menu/PMState';
import { useSelector } from 'react-redux';
import FruxSlideShow from './components/user_guide/FruxSlideShow';



const App = () => {
  const isAuthenticated = useIsAuthenticated()
  const responseFruxData = useSelector(state => state.logs.responseFruxData)
  const responseFruxDataState = useSelector(state => state.logs.responseFruxDataState)
  const selectedUser = useSelector(state => state.logs.selectedUser)

  return (
    <>
      {responseFruxDataState ? (
        <FruxSlideShow response={responseFruxData} selectedUser={selectedUser} />
      ) : (
        <MRState>
          <div className="HyperPrompt">
            {isAuthenticated() &&
              <PMState>
                <HMMenu />
                <MainRegion />
                <PMMenu />
              </PMState>
            }
          </div>
          <div className="mobileViewPort">Mobile View Port</div>
        </MRState>)}
    </>
  );
}

export default App;
