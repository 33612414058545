import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import SubscriptionEclipse from '../../icons/SubscriptionEclipse';

function MRSButton(props) {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = (link) => {
    if (link === props.link) {
      props.setHasBeenClicked(true);

      setIsSelected(true); 
    } else {
      setIsSelected(false); 
    }
  }

  return (
    <>
      <Button
        onClick={() => handleClick(props.link)} href={props.link}
        disabled={props.disable}
        className={`${props.infinite ? 'infinite-btn' : ''} 
                    ${props.is_current_plan && props.infinite ? 'infinite-selected' : ''} 
                    ${props.product_id === '22' && !props.is_current_plan ? 'free-plan-card' : ''} 
                    ${props.hasBeenClicked ? (isSelected ? 'selected-plan-btn' : 'unselected-plan-btn') : ''}`} 
        variant="primary subs_primary"
      >
        {isSelected
          ? <><SubscriptionEclipse /> Purchasing</>
          : props.buttonText
        }
      </Button>
    </>
  );
}

export default MRSButton;
