import React, { useContext } from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import MainRegionContext from './General/MRContext';
import MRHyperPrompt from './HyperPrompt';
import MRSubscriptions from './MRSubscriptions/MRSubscriptions';

import { useAuthUser } from "react-auth-kit";


function MainRegion() {
  const MainRegionState = useContext(MainRegionContext);
  const MRShow = MainRegionState.MRShow[0];
  const auth = useAuthUser();
  return (
    <div>
      <Container fluid>
        <Row className='justify-content-center align-items-center'>
          {auth() && MainRegionState.MRSubscription &&
            <Col>
              <MRSubscriptions />
            </Col>
          }
          {!MainRegionState.MRSubscription &&
            <MRHyperPrompt />
          }
        </Row>
      </Container>
    </div>
  );
}

export default MainRegion;