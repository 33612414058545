const Icon = (props) => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
  <g clipPath="url(#clip0_3328_102172)">
    <path d="M15.6307 0.623047H2.7405C2.01392 0.623873 1.31734 0.912873 0.803573 1.42664C0.289808 1.94042 0.000816721 2.637 0 3.36358L0 11.5418C0.000802361 12.1877 0.229351 12.8126 0.64544 13.3066C1.06153 13.8006 1.6385 14.1321 2.27487 14.2427V17.1745C2.27492 17.2827 2.30558 17.3887 2.36331 17.4803C2.42103 17.5719 2.50348 17.6453 2.60112 17.692C2.69877 17.7387 2.80764 17.7569 2.91517 17.7444C3.0227 17.732 3.12451 17.6893 3.20885 17.6215L7.35671 14.2821H15.6309C16.3574 14.2813 17.0539 13.9922 17.5676 13.4785C18.0813 12.9648 18.3703 12.2683 18.3712 11.5418V3.36358C18.3703 2.637 18.0813 1.94042 17.5676 1.42664C17.0538 0.912873 16.3572 0.623873 15.6307 0.623047ZM17.223 11.5418C17.2225 11.9639 17.0546 12.3687 16.7561 12.6672C16.4576 12.9657 16.0528 13.1336 15.6307 13.1341H7.15427C7.0233 13.1341 6.89626 13.1789 6.79424 13.261L3.42306 15.9752V13.7082C3.42306 13.5559 3.36258 13.4099 3.25491 13.3022C3.14725 13.1946 3.00122 13.1341 2.84896 13.1341H2.7405C2.31833 13.1336 1.9136 12.9657 1.61509 12.6672C1.31658 12.3687 1.14867 11.9639 1.1482 11.5418V3.36358C1.14867 2.94142 1.31658 2.53668 1.61509 2.23817C1.9136 1.93965 2.31833 1.77173 2.7405 1.77124H15.6307C16.0528 1.77173 16.4576 1.93965 16.7561 2.23817C17.0546 2.53668 17.2225 2.94142 17.223 3.36358V11.5418Z" fill="#5DCDF5"/>
    <rect x="8.41992" y="4.59375" width="1.53093" height="6.12372" rx="0.765465" fill="#5DCDF5"/>
    <rect x="12.2476" y="6.88867" width="1.53093" height="6.12372" rx="0.765465" transform="rotate(90 12.2476 6.88867)" fill="#5DCDF5"/>
  </g>
  <defs>
    <clipPath id="clip0_3328_102172">
      <rect width="18.3712" height="18.3712" fill="white"/>
    </clipPath>
  </defs>
</svg>


        </>
    );
};

export default Icon;