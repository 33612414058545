import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams} from "react-router-dom";
import mainRegionContext from "./MRContext";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Convo from '../MRConversation/ViewOneConversation.json';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { PMAPI } from "../../../helper/PMAPI";
import HMMenu from "../../hyper-menu/HMMenu";
import PMMenu from "../../profile-menu/PMMenu";
import MainRegion from "../MainRegion";
import PMState from "../../profile-menu/PMState";
import { useSelector } from "react-redux";
import FruxSlideShow from "../../user_guide/FruxSlideShow";
import coversationContext from "../../../context/conversationContext";
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';


const MRState = (props) => {
    const [MRConvoID, setMRConvoID] = useState(0);
    const [loadingTime, setLoadingTime] = useState(1000);
    const [fShowExistingConvo, setFShowExistingConvo] = useState(false);
    const [MRSubscription, setMRSubscription] = useState(false);
    const [MRShow, setMRShow] = useState([{ "MRTextHeader": true, "MRGigaPrompt": false, "MRInputBar": true, "MRConversation": false, "MRLogs": false }]);
    const [convo, setConvo] = useState(Convo);
    const [PMLinks, setPMLinks] = useState([]);
    const [MRfolderID, setFolderID] = useState(null); 
    const [dropdownTitle, setDropdownTitle] = useState(false)
    const [fileUpload, setFileUpload] = useState([])
    const [triggerBtn, setTriggerBtn] = useState(false)
    const [canUseAtticus, setCanUseAtticus] = useState()
    const [generateReport, setGenerateReport] = useState(false)
    const [param, setParams] = useState(props.param); 
    const [fileError, setFileError] = useState('')
    const [files,setFiles] = useState('')
    const [fileState, setFileState] = useState('complete')
    const { id } = useParams()
    const responseFruxData = useSelector(state => state.logs.responseFruxData)
    const responseFruxDataState = useSelector(state => state.logs.responseFruxDataState)
    const selectedUser = useSelector(state => state.logs.selectedUser)
    const isAuthenticated = useIsAuthenticated();
    const conversation = useContext(coversationContext);

    const navigate = useNavigate()

    useEffect(() => {
      if ((!isAuthenticated() || !isTokenValid()) && !id) {
        navigate("/login");
      }
    }, [isAuthenticated, param]);

    const handleDropdown = (e) => {
      conversation.setaiModelId(e.id)
      conversation.setaiModelValue(e.name)
      conversation.setaiModelHeader(e.header)
      conversation.setaiModelImage(e.image_url)
      setLoadingTime(e.run_time)
      
      if(e.model_type === "agent"){
          conversation.setaiModeltype("AI Agent");
        }else if(e.model_type ){
          conversation.setaiModeltype("AI Model");
        }else{
          conversation.setaiModeltype("Select");
      }
  }

  {/*Clear main-region*/ }
  const resetMRShow = () => {
    setMRShow([
      {
        "MRTextHeader": false,
        "MRGigaPrompt": false,
        "MRInputBar": true,
        "MRConversation": false,
        "MRLogs": false
      }
    ]);
  };

  function updateMRShow() {
    const nextMRShow = MRShow.map(show => {
      if (show.type === 'square') {
        // No change
        return show;
      } else {
        // Return a new circle 50px below
        return {
          ...show,
          y: show.y + 50,
        };
      }
    });
    // Re-render with the new array
    setMRShow(nextMRShow);
  }
  
  {/*Turn on Home State*/}
  const showMRHome = () => {
    setMRShow([
      {
        "MRTextHeader": true,
        "MRGigaPrompt": false,
        "MRInputBar": true,
        "MRConversation": false,
        "MRLogs": false
      }
    ]);
  };

  {/*Turn on GigaPrompt Settings*/ }
  const showGigaPrompt = () => {
    setMRShow([
      {
        "MRTextHeader": false,
        "MRGigaPrompt": true,
        "MRInputBar": true,
        "MRConversation": false,
        "MRLogs": false
      }
    ]);
  };

  {/*Turn on MRConversation Component*/ }
  const showMRConversation = () => {
    setMRShow([
      {
        "MRTextHeader": false,
        "MRGigaPrompt": false,
        "MRInputBar": true,
        "MRConversation": true,
        "MRLogs": false
      }
    ]);
  };

  {/*Turn on MRLogs Component*/ }
  const showMRLogs = () => {
    setMRShow([
      {
        "MRTextHeader": true,
        "MRGigaPrompt": false,
        "MRInputBar": true,
        "MRConversation": false,
        "MRLogs": true
      }
    ]);
  };


  const isTokenValid = () => {
    const token = Cookies.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp > currentTime;
      } catch (error) {
        console.log('Invalid token');
      }
    }
    return false;
  };

  return (
    <mainRegionContext.Provider value={{
            MRShow, setMRShow, MRConvoID, loadingTime, setLoadingTime, showGigaPrompt, showMRLogs, showMRConversation, showMRHome, MRSubscription, setMRSubscription, fShowExistingConvo,fileError, setFileError, 
            setFShowExistingConvo, convo, setConvo, setMRConvoID, PMLinks, setPMLinks, MRfolderID, setFolderID,handleDropdown,dropdownTitle,setDropdownTitle,fileUpload, setFileUpload,triggerBtn, setTriggerBtn,
            files,setFiles, fileState, setFileState, generateReport, setGenerateReport, canUseAtticus, setCanUseAtticus
    }}>
      {props.children}
      {responseFruxDataState ? (
        <FruxSlideShow response={responseFruxData} selectedUser={selectedUser} />
      ) : (param === "conversation" || param === "conversations") && (
        <div className="HyperPrompt">
          {isAuthenticated() && isTokenValid() && (
            <PMState>
              <HMMenu param={param} />
              <MainRegion param={param} />
              <PMMenu />
            </PMState>
          )}
        </div>
      )}
    </mainRegionContext.Provider>
  );
};

export default MRState;