const IconFlashWhiteBG = (props) => {
    return (
        <>
            <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.49283 18.9004L11.9445 6.28157C12.1132 5.99587 11.8739 5.64429 11.5411 5.68882L6.58441 6.35141L7.76871 0.236451C7.80863 0.0303085 7.53839 -0.0878107 7.40957 0.0794413L0.0829511 9.59095C-0.130463 9.86797 0.0955161 10.2619 0.446954 10.2255L5.91164 9.65979L4.12015 18.763C4.07695 18.9826 4.37862 19.0938 4.49283 18.9004Z" fill="#EEECEC" />
                <path d="M2.18717 9.19949C2.06109 9.20996 1.97735 9.06685 2.04437 8.9555L7.09091 0.571519C7.01583 0.509836 6.89764 0.505865 6.82607 0.603967L0.0764187 9.85506C-0.120189 10.1245 0.0879941 10.5076 0.411756 10.4723L5.4461 9.92201L3.79569 18.776C3.77344 18.8954 3.85103 18.9795 3.94172 18.9996L6.76982 9.70744C6.9024 9.2719 6.56793 8.83556 6.12942 8.87202L2.18717 9.19949Z" fill="#9D9D9D" />
            </svg>

        </>
    );
};

export default IconFlashWhiteBG;