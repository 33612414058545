import React from 'react';
import ReactMarkdown from 'react-markdown';
import Prism from 'prismjs';
import 'prism-themes/themes/prism-one-dark.css';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-go';

class MRFormatting extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  componentDidUpdate() {
    Prism.highlightAll();
  }

  render() {
    const { markdownContent } = this.props;

    // Custom component for rendering code blocks with a "Copy Code" button
    const CodeBlock = ({node, inline, className, children, ...props}) => {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <div style={{position: 'relative'}}>
          <pre className={className ? className : null} {...props}>
            <code>{children}</code>
          </pre>
          <button 
            style={{position: 'absolute', top: '5px', right: '5px', background: 'transparent', border: 'none', color: 'white' }}
            onClick={() => navigator.clipboard.writeText(children.toString().trim())}>
            Copy Code
          </button>
        </div>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    };

    return (
      <ReactMarkdown
        children={markdownContent}
        components={{
          code: CodeBlock
        }}
      />
    );
  }
}

export default MRFormatting;
