const Icon = () => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.112 8C21.0832 5.64547 18.7338 4 16 4C12.3181 4 9.33337 6.98477 9.33337 10.6667V13.3717M9.33337 13.3717C9.96193 13.3333 10.7368 13.3333 
                11.7334 13.3333H20.2667C22.507 13.3333 23.627 13.3333 24.4827 13.7693C25.2354 14.1528 25.8472 14.7647 26.2307
                 15.5173C26.6667 16.3731 26.6667 17.4931 26.6667 19.7333V21.6C26.6667 23.8403 26.6667 24.9603 26.2307 
                 25.816C25.8472 26.5687 25.2354 27.1805 24.4827 27.564C23.627 28 22.507 28 20.2667 28H11.7334C9.49316 
                 28 8.37305 28 7.51741 27.564C6.76476 27.1805 6.15284 26.5687 5.76935 25.816C5.33337 24.9603 5.33337 23.8403 5.33337 21.6V19.7333C5.33337 17.4931 5.33337 16.3731 5.76935 15.5173C6.15284 14.7647 6.76476 14.1528 7.51741 13.7693C7.99243 13.5273 8.54895 13.4196 9.33337 13.3717Z" stroke="black" stroke-width="2" />
            </svg>
        </>
    );
};
export default Icon;
