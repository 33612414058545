import React from 'react';
import { AuthProvider, RequireAuth } from 'react-auth-kit';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import LoginPage from './components/login/hp-login';
import SignUpPage from './components/login/hp-signup';
import MRSubscriptions from './components/main-region/MRSubscriptions/MRSubscriptions';
import reportWebVitals from './reportWebVitals';
// redux
import { Provider } from 'react-redux';
import { store } from './redux/store';

import ConversationResponseState from './context/ConversationResponseState';
import ConversationState from './context/ConversationState';
import SubscriptionState from './context/SubscriptionState';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import MobileVersion from './components/MobileVersion/MobileVersion';
import Error401Page from './components/error_pages/Error401Page';
import Error404Page from './components/error_pages/Error404Page';
import Faq from './components/faq/Faq';
import HMConversationId from './components/hyper-menu/HMConversationId';
import HMRoutes from './components/hyper-menu/HMRoutes';
import ConfirmResetPassword from './components/login/hp-confirmReset';
import ResetPassword from './components/login/hp-resetPassword';
import SetNewPassword from './components/login/hp-setNewPassword';
import './styles/_Accordion.css';
import './styles/_Faqs.css';
import './styles/_GPsettings.css';
import './styles/_MobileView.css';
import './styles/_hyperMenu.css';
import './styles/_icon.css';
import './styles/_logPage.css';
import './styles/_profileMenu.css';
import './styles/_subscription.css';
import './styles/_variables.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
const tagManagerArgs = {
  gtmId: 'GTM-T3RQW4WJ'
}

TagManager.initialize(tagManagerArgs)
root.render(
  <Provider store = {store}>
    <AuthProvider authType={'cookie'}
      authName={'token'}
      cookieDomain={window.location.hostname}
      cookieSecure={false}>
        <MobileVersion/>
      <ConversationState>
        <SubscriptionState>
          <BrowserRouter>
            <ConversationResponseState>
              <Routes>
                <Route path="/error-401" element={<Error401Page />} /> 
                <Route path="/error-404" element={<Error404Page />} /> 
                <Route path="/conversation/:id" element={<HMConversationId />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/confirmReset" element={<ConfirmResetPassword />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/SetNewPassword" element={<SetNewPassword />} />
                <Route path="/subscriptions" element={<MRSubscriptions />} />
                <Route path="/resources" element={<Faq/>} />
                <Route
                  path="/:param"
                  element={
                    <RequireAuth loginPath="/login">
                        <HMRoutes />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/conversation/:id"
                  element={
                    <RequireAuth loginPath="/login">
                        <HMConversationId />
                    </RequireAuth>
                  }
                />
                <Route path="/app"
                  element={
                    <RequireAuth loginPath="/login">
                      <App />
                    </RequireAuth>
                  }
                />
                <Route path="/" element={<LoginPage />} />
              </Routes>
            </ConversationResponseState>
          </BrowserRouter>
        </SubscriptionState>
      </ConversationState>
    </AuthProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
