import React, { useContext, useState, useEffect } from "react";
import Col from 'react-bootstrap/Col';

import MainRegionContext from './General/MRContext';
import MRConversation from './MRConversation/MRConversation';
import MRGigaPromptSettings from './MRGigaPrompt/MRGigaPromptSettings';
import MRInputBar from './MRInputBar/MRInputBar';
import MRTextHeader from './MRTextHeader/MRTextHeader';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useIsAuthenticated } from "react-auth-kit";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FRUXButton from "../user_guide/FRUXButton";
import MRLState from './MRLogs/MRLState';
import MRLogs from './MRLogs/MRLogs';
import MRLFilter from "./MRLogs/MRLFilter";
import MRSeachBar from "./SearchBar";
import ConversationContext from '../../context/conversationContext';
import DropDownBox from './MRDropDown';
import WelcomeMessage from "./WelcomeMessage";


function HyperPrompt() {
  const MainRegionState = useContext(MainRegionContext);
  const ConversationState = useContext(ConversationContext);
  const MRShow = MainRegionState.MRShow[0];
  const auth = useIsAuthenticated()
  const navigate = useNavigate()
  const { id } = useParams();
  const disableEditing = useSelector(state => state.logs.disableEditing)
  const logOutUser = useSelector(state => state.logs.logOutUser)
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [clientHeight, setClientHeight] = useState(0);
  const [InputBoxHeight, setInputBoxHeight] = useState(0);
  const [TotalHeight, setTotalHeight] = useState(0);

  const handleRedirect = () => {
    const url = window.location.href
    navigate(`/login?redirect_uri=${url}`)
  }

  const handleClone = () => {
    axios.defaults.headers.common['Authorization'] = Cookies.get('token');
    try {
      const cloneConversation = async () => {
        const response = await axios.post(`${baseUrl}/api/v1/conversation/${id}/save_conversation`);
        if (response.status === 200) {
          console.log("response clone conversation", response);
          window.location.href = `/conversation/${response.data.parent_conversation_uuid}`;
        } else {
          console.log("error occured")
        }
      };
      cloneConversation();
    } catch (error) {
      if (error.response.status === 404) {
        navigate('/error-404');
      } else if (error.response.status === 401) {
        navigate('/error-401');
      }
      console.error("An error occurred during conversation clonning:", error);
    }
  }

  useEffect(() => {
    const input = document.querySelector('.file-cards');
    if (input) {
      const height = input.clientHeight;
      setClientHeight(height);
    }
  },); 


  useEffect(() => {
    const input = document.querySelector('.GPInputText') || document.querySelector('.GPInputTextexisting') ||  document.querySelector('.GPInputTextnew');
    if (input) {
      const height = input.clientHeight;
      setInputBoxHeight(height);
    }
      var total_height = InputBoxHeight + clientHeight
      setTotalHeight(total_height - 40)
  },); 
  return (
    <>
      <Col>
      </Col>
      <Col lg="6" className="scrollAdd" id="logsScroll">
        {MRShow.MRGigaPrompt &&
          <MRGigaPromptSettings />
        }
        {(MRShow.MRLogs && !id) ? (
          <MRTextHeader>
            {/* <h1 className="MRTitle">Start a new conversation</h1> */}
            <h1 className="MRTitle">Search your conversations</h1> 
          </MRTextHeader>
        ) : (
          (MRShow.MRTextHeader && !MainRegionState.MRConvoID && !id) && (
            <MRTextHeader>
              {/* <h1 className="MRTitle">Have you broken the internet today?</h1> */}
              <FRUXButton />

            </MRTextHeader>
          )
        )}
        {MRShow.MRInputBar && (MRShow.MRLogs || MainRegionState.MRConvoID === 0) ? (
          <>
           { MainRegionState.aiDrawer && <br />}
            {auth() ? (
              <>
                {/* {!disableEditing && <MRInputBar />} */}              
              <>
                  {MRShow.MRLogs ? (
                    <div className={`search-component GPInputWrapper ${ConversationState.isFocused ? 'focused' : ''}`}>   
                       <MRSeachBar />
                    </div>

                  ) : (
                    !disableEditing && <>
                      <div className="welcome-dropbox" style = {{marginBottom: TotalHeight}}>
                        {(!id && <WelcomeMessage />)}
                        {id && <DropDownBox />}
                      </div>
                     <MRInputBar viewOneConvo={false} />
                    </>
                  )}
                </>
              </>
            ) : (
              <div className="btn-div mr-header sign-in-btn GPInputWrapperFixed">
              </div>
            )}
          </>
        ) : (
          <>
            {auth() ? (
              <>
                  {!disableEditing && <>     <div className="welcome-dropbox" style = {{marginBottom: TotalHeight}}>
                        
                      </div>   <MRInputBar viewOneConvo={true} /> </>}
                {disableEditing && !logOutUser && (
                  <div className="btn-div mr-header sign-in-btn GPInputWrapperFixed">
                    <input onClick={handleClone} className="sign_in_btn mt-10" type="submit" value="Save To My Conversations" />
                  </div>
                )}
              </>
            ) : (
              <div className="btn-div mr-header sign-in-btn GPInputWrapperFixed">
                <input onClick={handleRedirect} className="sign_in_btn" type="submit" value="Log In" />
              </div>
            )}
          </>
        )}
        {(MRShow.MRConversation && !id) &&  <><MRConversation /></>}
        {(MRShow.MRLogs && !id) && (
          <MRLState>
            <MRLFilter />
            <MRLogs />
          </MRLState>
        )}
      </Col>
      <Col>
      </Col>
    </>
  );

}

export default HyperPrompt;