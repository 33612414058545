import { useState, useEffect } from "react";
import profileMenuContext from "./PMContext";
import { PMAPI } from "../../helper/PMAPI";
import {useAuthUser} from 'react-auth-kit';

const PMState = (props) => {
  
  const auth = useAuthUser();
  const [updateUserCredit, setupdateUserCredit] = useState(false);
  const [userCredits, setUserCredits] = useState();
  const [buttonText, setButtonText] = useState('Feed Robot')
  const [credits, setCredits] = useState(true)
  const [PMLinks, setPMLinks] = useState();
  const [addNewFolder,setaddNewFolder] = useState(false);
  const [newFolderID,setnewFolderID] = useState(null)
  const [withoutFocus,setWithoutFocus] = useState(true)

  async function updateLinks(){
    const profileLinks = await PMAPI.getProfileLinks();
    setPMLinks(profileLinks);
  }

  async function updateCredits(){
    const creditsInfo = await PMAPI.getCreditsInfo();
    setUserCredits(creditsInfo.data);
  }

  
  useEffect(() => {
    if(!PMLinks){
      updateLinks();
    }
    if(updateUserCredit){
      updateCredits();
      setupdateUserCredit(false);
    }
    }
  )

  return (
    <profileMenuContext.Provider value={{
      PMLinks,setPMLinks,withoutFocus,setWithoutFocus,
      userCredits, setUserCredits, setupdateUserCredit, buttonText, setButtonText, credits, setCredits, addNewFolder,setaddNewFolder, newFolderID,setnewFolderID
      }}>
        {props.children}
    </profileMenuContext.Provider>
  )
}

export default PMState;