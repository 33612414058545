import React, { useState } from 'react';

import { PMAPI } from "../../helper/PMAPI";
import { useDispatch } from 'react-redux';
import { setCreditsUpdateState, setFruxDataState } from '../../redux/slices/logsSlice';

const FruxSlideShow = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const responseData = props.response["data"][props.selectedUser];
    const dispatch = useDispatch();

    const handleClick = () => {
        const nextIndex = (currentIndex + 1) % responseData.length;
        setCurrentIndex(nextIndex);
        if (currentIndex === responseData.length - 1) {
            dispatch(setFruxDataState(false))
            dispatch(setCreditsUpdateState(true))
            PMAPI.updateFruxStatus(false, props.selectedUser)
                .then((response) => {
                    dispatch(setCreditsUpdateState(false))
                    console.log("response frux images", response)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    };

    return (
        <div
            id="carouselExampleSlidesOnly"
            className="carousel slide"
            data-ride="carousel"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        >
            <div className="carousel-inner">
                {responseData.map((item, index) => (
                    <div key={index} className={`carousel-item  ${index === currentIndex ? 'active img-div' : ''}`}>
                        <img className="d-block img_height_width" src={item.image_url} alt={`Image ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FruxSlideShow;