import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import MainRegionContext from './General/MRContext';
import { MRConvoAPI } from "../../helper/MRConversationAPI";
import { updateResponse } from '../../redux/slices/conversationSlice';
import { useDispatch } from 'react-redux';
import { setResponseLogs } from '../../redux/slices/logsSlice';
import coversationContext from '../../context/conversationContext';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js';

const MRIDropDownBox = (props) => {
  const mrstate = useContext(MainRegionContext);
  const conversation = useContext(coversationContext);
  const [allowedFileTypes, setAllowedFileTypes] = useState([]);
  const [maxFiles, setMaxFiles] = useState(0);
  const [maxFileSize, setMaxFileSize] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await MRConvoAPI.getConfigurations();
        const config = response.data;
        setAllowedFileTypes(config.allowed_file_types);
        setMaxFiles(config.max_documents);
        setMaxFileSize(config.max_size);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConfigurations();
  }, []);

  useEffect(() => {
    if (mrstate.triggerBtn) {
      const dropzone = document.querySelector('.dropzone input');
      mrstate.setTriggerBtn(false);
      dropzone?.click();
    }
  }, [mrstate.triggerBtn]);

  const onDrop = useCallback((acceptedFiles) => {    
    const newFiles = acceptedFiles.map(file => {
      return {
        id: uuidv4(),
        name: file.name,
        type: file.type,
        file: file,
        size: file.size
      };
    });

    const totalSize = newFiles.reduce((sum, file) => sum + file.size, 0) + mrstate.fileUpload.reduce((sum, file) => sum + file.size, 0);

    if (totalSize > maxFileSize) {
      const errorMessage = `You can upload up to ${maxFiles} medical files at a time. Each file must be smaller than ${maxFileSize} and one of the following types: ${allowedFileTypes.join(', ')}`;
      mrstate.setFileError(errorMessage);

      const responsePayload = {
        ConvoId: "",
        Persona: "",
        is_image: "",
        image_url: conversation.aiModelImage,
        response: errorMessage,
        is_user_prompt: false,
        ai_model_url: conversation.aiModelImage,
        loops_completed: '',
        total_loops: '',
        polling: '',
        prompt: errorMessage,
        parent_conversation_uuid: '',
        is_hp_error: false,
        is_ai_model_error: false,
        hover_text: `Atticus`,
      };

      dispatch(updateResponse([responsePayload]));
      dispatch(setResponseLogs([{ ...responsePayload, Persona: 'Atticus', is_image: false }]));
    } else if (mrstate.fileUpload.length + newFiles.length > maxFiles) {
      const errorMessage = `You can upload up to ${maxFiles} medical files at a time. Each file must be smaller than ${maxFileSize} and one of the following types: ${allowedFileTypes.join(', ')}`;
      mrstate.setFileError(errorMessage);

      const responsePayload = {
        ConvoId: "",
        Persona: "",
        is_image: "",
        image_url: conversation.aiModelImage,
        response: errorMessage,
        is_user_prompt: false,
        ai_model_url: conversation.aiModelImage,
        loops_completed: '',
        total_loops: '',
        polling: '',
        prompt: errorMessage,
        parent_conversation_uuid: '',
        is_hp_error: false,
        is_ai_model_error: false,
        hover_text: `Atticus`,
      };

      dispatch(updateResponse([responsePayload]));
      dispatch(setResponseLogs([{ ...responsePayload, Persona: 'Atticus', is_image: false }]));
      if (mrstate.fileUpload.length < maxFiles) {
        const remainingSpace = maxFiles - mrstate.fileUpload.length

        const filesToAdd = newFiles.slice(0, remainingSpace);
        mrstate.setFileUpload(prevFiles => [...prevFiles, ...filesToAdd]);

      }
    } else {
      const validFiles = newFiles.filter(file => allowedFileTypes.includes(file.type) && file.size <= maxFileSize);
      mrstate.setFileUpload(prevFiles => [...prevFiles, ...validFiles]);
      mrstate.setFileError('');
    }
  }, [allowedFileTypes, maxFiles, maxFileSize, mrstate, conversation.aiModelImage, dispatch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFileTypes.join(',') 
  });

  useEffect(() => {
    const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.dataTransfer && event.dataTransfer.files) {
        onDrop(Array.from(event.dataTransfer.files));
      }
    };

    const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    document.body.addEventListener('drop', handleDrop);
    document.body.addEventListener('dragover', handleDragOver);

    return () => {
      document.body.removeEventListener('drop', handleDrop);
      document.body.removeEventListener('dragover', handleDragOver);
    };
  }, [onDrop]);

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`} style={{ display: props.welcomePage ? '' : 'none' }}>
      <input {...getInputProps()} accept={allowedFileTypes.join(',')} />
      {isDragActive && <p className='dropzone-para'>Drop the files here...</p>}
    </div>
  );
};

export default MRIDropDownBox;
