import React, { useState } from 'react';
import { AccordionHeader, AccordionItem } from "react-headless-accordion";
import IconAccordion from '../../icons/IconAccordion';
import IconGP from '../../icons/IconGP';
import MRAKabob from "../General/MRKabob";
import MRFormatting from "./MRFormatting";

const MRPromptError = ({ promptError }) => {
    const textError = promptError
    if (textError) {
        return( <div>
            {textError}
        </div>)
    }
}

const MRCErrorPrompt = ({ promptData }) => {
    const data = promptData
    const [errorIconHover, setErrorIconHover] = useState(false)

    const handleHoverErrorIcon = () =>{
        setErrorIconHover(true)
    }

    const handleLeaveErrorIcon = () => {
        setErrorIconHover(false)
    }
    return (
        <>
            <AccordionHeader as='div' className="titleWrap text" >
                        <div className="acco_col " >

                            <div className="">
                                <span style={{paddingLeft: '67px'}} className={`accordion-title`}>
                                    { <MRPromptError promptError={data?.response} />}
                                </span>

                            </div>
                        </div>

                        {
                            <div className="GPIcon ConversationGPIcon">
                                <div className="modelIcon" onMouseEnter={handleHoverErrorIcon} onMouseLeave={handleLeaveErrorIcon}>
                                    <img className='error-icon' src={data?.ai_model_url} alt="aiIcon" />
                                </div>
                               {errorIconHover && <div className='HPIcon-hover-text'>
                                    Error
                                </div>}
                            </div>
                        }

                    </AccordionHeader>
        </>
    );
};


export default MRCErrorPrompt;