import { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ProgressBar from 'react-bootstrap/ProgressBar';

const SkeletonLoading = ({runTime}) => {
    const [counter, setCounter] = useState(0);
    const [maxCounter, setMaxCounter] = useState(95);  /*the max counter*/
    const interval = runTime /  maxCounter; /*1000 ms to reach max counter*/
   
    useEffect(() => {
        //Implementing the setInterval method
        console.log(runTime)
        const loop = setInterval(() => {
            if(counter < maxCounter){
                setCounter(counter+1);
                console.log(counter)

            }
        }, interval);
 
        //Clearing the interval
        return () => clearInterval(loop);
    }, [counter]);

    return (
        <>
            <ProgressBar animated now={counter} />
        </>
    );
};

export default SkeletonLoading;