import React, { useEffect, useState } from 'react';
import { useSignIn } from 'react-auth-kit';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import IconEye from "../icons/IconEye";
import Spinner from 'react-bootstrap/Spinner';
import loginBG from '../../images/loginBG.jpg'
import HPLogo from '../../images/HP_logo.svg'
import IconEyeInvisible from "../icons/IconEyeInvisible";
import PasswordChecklist from "react-password-checklist"
import InputField from '../main-region/MRInputBar/InputField.jsx';

const SetNewPassword = (props) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [showPassword, setShowPassword] = React.useState(false);
    const [isCorrect, setIsCorrect] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [passwordChecklistHeader, setPasswordChecklistHeader] = useState(true)
    const [passwordError, setPasswordError] = useState(false)
    const [error, setError] = useState()
    const [buttonDisable, setButtonDisable] = useState(false)
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const onSubmit = (e) => {
        if (buttonDisable) {
            e.preventDefault();
            return
        }
        e.preventDefault();
        setLoading(true);

        const ResetPassword = async () => {
            try {
                const response = await axios.post(`${baseUrl}/api/v1/users/change_password`, {
                    token: token,
                    password: formData.password,
                    confirm_password: formData.confirmPassword,
                });

                if (response.status === 200) {
                    console.log(response.data);
                    navigate('/login');
                    setLoading(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.log("Unauthorized Error:", error.response.data.error);
                    if (error.response.data.error === 'Invalid Token') {
                        setError(error.response.data.error)
                    }
                } else {
                    console.error("An error occurred during password reset:", error);
                }
                setLoading(false);
            }
        };

        ResetPassword();
    };


    useEffect(() => {
        if (!formData.password || formData.confirmPassword) {
            setButtonDisable(true)
        }

        if (formData.password === formData.confirmPassword && isChecked === true) {
            setIsCorrect(true)
        }
        else {
            setIsCorrect(false)
        }
    }, [formData, isChecked]);

    useEffect(() => {

        let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/;
        if (!passwordRegex.test(formData.password)) {
            setPasswordChecklistHeader('Password must contain:')
        }
        else {
            setPasswordChecklistHeader('Robot Approved:')
        }


    }, [formData.password]);

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };

    const renderPasswordConfirmError = () => {
        if (formData.password && (formData.password === formData.confirmPassword)) {
            setConfirmPasswordError(false);
            setButtonDisable(false)
        }
        else if (formData.confirmPassword?.length < 8 || formData.confirmPassword?.length > 100) {
            setConfirmPasswordError('Password must be between 8 and 100 characters')
            setButtonDisable(true)
        } else {
            setConfirmPasswordError('Password and Confirm Password do not match');
            setButtonDisable(true)
        }
    }

    const renderPasswordError = () => {
        if (formData.password?.length < 8 || formData.password?.length > 100) {
            setPasswordError('Password must be between 8 and 100 characters');
            setButtonDisable(true)
        } else {
            setPasswordError(false);
        }
    }







    const handleConfirmPasswordToggle = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            <div className="auth_page_wrapper">
                {/* HP Header logo */}
                <a href="./" className="HP_header_logo">
                    <img src={HPLogo} alt="Atticus Logo" />
                    <span>Atticus</span>
                </a>
                <div className="photo_col">
                    <img src={loginBG} alt="" />
                    <h1 className="log_title">42X Prompting Accelerator</h1>
                </div>
                <div className="auth_col">
                    <div className="form_scroll">
                        <div className="form_wrap">

                            <h2 className="log_title">Set New Password<span className="log_sub">Enter a secure password </span></h2>
                            {error && (
                                <h3 className="mb-5 text-danger">{error}</h3>
                            )}

                            <div className="inputBox mb-2">

                            <form className="d-flex flex-column logForm setNewPWForm" onSubmit={onSubmit}>

                                <InputField 
                                    id="new-password"
                                    name="new-password"
                                    label="Password"
                                    type="password"
                                    value={formData.password} 
                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                    onBlur={renderPasswordError}
                                    showPassword={showPassword}
                                    setShowPassword={setShowPassword}
                                    error={passwordError}
                                />

                                <InputField 
                                    id="confirm-password"
                                    name="confirm-password"
                                    label="Confirm Password"
                                    type="password"
                                    value={formData.confirmPassword} 
                                    onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                                    onBlur={renderPasswordConfirmError}
                                    showPassword={showConfirmPassword}
                                    setShowPassword={setShowConfirmPassword}
                                    error={confirmPasswordError}
                                />
                                <div className='passwordChecklist'>
                                    <span className='passConfirmTitle'>
                                        {passwordChecklistHeader}
                                    </span>
                                    <PasswordChecklist
                                        rules={["capital", "lowercase", "minLength", "number", "maxLength"]}
                                        minLength={8}
                                        maxLength={100}
                                        value={formData.password}
                                        onChange={(isValid) => { }}
                                        hideIcon={true}
                                        messages={{
                                            capital: "uppercase letter",
                                            lowercase: "lowercase letter",
                                            minLength: ">7 Characters",
                                            number: "number",
                                            maxLength: " "
                                        }}
                                    />
                                </div>
                                {loading
                                    ? <span className="log_btn mt-10" disabled>
                                        <Spinner animation="border" role="status" />
                                    </span>
                                    : <input aria-disabled={buttonDisable} className="log_btn mt-10 newPassBtn" type="submit" value="Confirm Password" />
                                }



                                </form>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default SetNewPassword;
