

const IconEye = (props) => {
    
    return (
        <div onClick={props?.onClick}><svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8983 5.69004C15.7554 5.49451 12.3496 0.902344 7.99992 0.902344C3.65019 0.902344 0.244313 5.49451 0.101531 5.68985C-0.0338438 5.87535 -0.0338438 6.12695 0.101531 6.31245C0.244313 6.50798 3.65019 11.1001 7.99992 11.1001C12.3496 11.1001 15.7554 6.50795 15.8983 6.3126C16.0339 6.12714 16.0339 5.87535 15.8983 5.69004ZM7.99992 10.0452C4.79588 10.0452 2.02085 6.99729 1.19938 6.00089C2.01979 5.0036 4.78901 1.95728 7.99992 1.95728C11.2038 1.95728 13.9787 5.00466 14.8005 6.0016C13.9801 6.99886 11.2108 10.0452 7.99992 10.0452Z" fill="#C7C7C7" />
            <path d="M7.99981 2.83594C6.25474 2.83594 4.83496 4.25572 4.83496 6.00079C4.83496 7.74585 6.25474 9.16564 7.99981 9.16564C9.74488 9.16564 11.1647 7.74585 11.1647 6.00079C11.1647 4.25572 9.74488 2.83594 7.99981 2.83594ZM7.99981 8.11067C6.83637 8.11067 5.88993 7.1642 5.88993 6.00079C5.88993 4.83738 6.8364 3.89091 7.99981 3.89091C9.16322 3.89091 10.1097 4.83738 10.1097 6.00079C10.1097 7.1642 9.16325 8.11067 7.99981 8.11067Z" fill="#C7C7C7" />
        </svg>
        </div>

    );
};

export default IconEye;