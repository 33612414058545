const Icon = (props) => {
    return (
        <>
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9985 3.4715C11.994 3.43375 11.9495 3.096 11.7198 2.866L9.134 0.2805C8.90425 0.05075 8.56625 0.006 8.5285 0.00175C8.519 0.0005 8.5095 0 8.5 0H1.25C0.56075 0 0 0.56075 0 1.25V14.75C0 15.4392 0.56075 16 1.25 16H10.75C11.4392 16 12 15.4392 12 14.75V3.5C12 3.4905 11.9995 3.481 11.9985 3.4715ZM11.366 3.2195C11.3748 3.22825 11.381 3.24 11.3888 3.25H9.5C9.0865 3.25 8.75 2.9135 8.75 2.5V0.61125C8.76 0.619 8.7715 0.62525 8.7805 0.634L11.366 3.2195ZM10.75 15.5H1.25C0.8365 15.5 0.5 15.1635 0.5 14.75V1.25C0.5 0.8365 0.8365 0.5 1.25 0.5H8.25V2.5C8.25 3.18925 8.81075 3.75 9.5 3.75H11.5V14.75C11.5 15.1635 11.1635 15.5 10.75 15.5Z" fill="black" />
            </svg>

        </>
    );
};

export default Icon;