import React from "react";
import { useParams } from "react-router-dom";

import MRState from "../main-region/General/MRState";

function Conversation() {
    const { param } = useParams();
    return (<>
        {param &&
            <MRState param={param} />}
    </>)

}

export default Conversation;