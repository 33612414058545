import { useEffect, useState, useContext, useRef } from "react";

import MRCPrompt from './MRCPrompt';
import MRCReciept from './MRCReceipt';
import SkeletonLoading from './MRCLoading';
import MRAKabob from "../General/MRKabob";
import EditableText from "../General/MREditableText";

import { MRConvoAPI } from "../../../helper/MRConversationAPI"

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { updateLogsData, updateDisableEditing, updateLogoutUser } from "../../../redux/slices/logsSlice";
import { clickedLogsAccordian, reset, setClickedLogsAccordian, setScrollBottom } from "../../../redux/slices/conversationSlice";
import MainRegionContext from "../General/MRContext";
import ConversationContext from '../../../context/conversationContext';

import { useIsAuthenticated } from "react-auth-kit";
import SkeletonLoaderFile from "./SkeletonLoaderFile";
const MRConversationWrapper = ({ children, convoUUID, convoName, convoID, receiptText }) => {
    const mrState = useContext(MainRegionContext);
    const ConversationState = useContext(ConversationContext);
    const [nameIsEditing, setNameIsEditing] = useState(false);
    const [name, setname] = useState(convoName);
    const isAuthenticated = useIsAuthenticated();
    const [error, setError] = useState('');
    const [ClickedKabob, setClickedKabob] = useState(false);
    const kabobRef = useRef(null);
    const inputref = useRef(null);
    const [allowRename, setAllowRename] = useState(true);
    const [clientHeight, setClientHeight] = useState(0);
    const [FileHeight, setFileHeight] = useState(0);

    useEffect(() => {
        setname(convoName);
    }, [convoName]);

    const handleEditing = () => {
        setNameIsEditing(true);
        ConversationState.setIsFocused(true);
        inputref.current.focus();
    };

    const handleKabobClick = () => {
        if (!(ClickedKabob && (nameIsEditing || name.trim() === ''))) {
            setAllowRename(false);
            setClickedKabob(true);
            if (nameIsEditing) {
                ConversationState.setIsFocused(true);
                inputref.current.focus();
            }
        }
    };

    useEffect(() => {
        if (nameIsEditing) {
            ConversationState.setIsFocused(true);
            inputref.current.focus();
        }
    }, [nameIsEditing]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (kabobRef.current && !kabobRef.current.contains(event.target)) {
                setClickedKabob(false);
                setAllowRename(true);
                ConversationState.setIsFocused(false);
                if (inputref.current && inputref.current.contains(event.target) && nameIsEditing) {
                    setClickedKabob(true);
                }
            } else {
                setAllowRename(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [kabobRef, inputref, nameIsEditing]);

    const handleInputBlur = (event) => {
        if (name.trim() === '') {
            setError('Conversation Title can not be blank');
        } else {
            setError('');
            handleBlur(convoID);
        }
    };

    const handleBlur = (convoID) => {
        if (name === '') {
        } else if (!ClickedKabob) {
            if (allowRename) {
                MRConvoAPI.renameConvo({ convoID, name });
            }
        } else if (convoID !== 0) {
            MRConvoAPI.renameConvo({ convoID, name });
            setNameIsEditing(false);
        }
    };

    const handleCloneConvo = async (convoUUID) => {
        setClickedKabob(false);
        setNameIsEditing(false);
        ConversationState.setIsFocused(false);
        try {
            const response = await MRConvoAPI.cloneConvo({ convoUUID });
            window.location.href = `/conversation/${response.data.uuid}`;
        } catch (error) {
            console.error('Error cloning convo:', error);
        }
    };

    useEffect(() => {
        const input = document.querySelector('.GPInputText') || document.querySelector('.GPInputTextexisting') ||  document.querySelector('.GPInputTextnew');
        if (input) {
          const height = input.clientHeight;
          setClientHeight(height - 38);
        }
      },); 
    const handleRename = async () => {
        try {
            const response = await MRConvoAPI.renameConvo({ convoID, name });
            console.log('Rename request sent:', response);
        } catch (error) {
            console.error('Error renaming convo:', error);
        }
    };


    useEffect(() => {
        const input = document.querySelector('.file-cards');
        if (input) {
        const height = input.clientHeight;
        setFileHeight(height);
        }
  },);
  
    return (
        <div className="mr-conversation" style={{ marginBottom: `calc(${FileHeight}px + 20vh)` , paddingBottom: clientHeight}}>
            <div className="mb-2 ">
                <div ref={inputref} className={`d-flex justify-content-between sticky-top NewConversation_title ${error ? 'error-border' : (ConversationState.isFocused ? 'focused-input' : 'no-error-border')}`}>
                    <h2 className="title">
                        <EditableText
                            name={name}
                            label="Conversation Title"
                            setName={setname}
                            isEditing={nameIsEditing}
                            handleEditing={handleEditing}
                            handleBlur={() => handleInputBlur(convoID)}
                            error_msg={error}
                            ClickedKabob={ClickedKabob}
                            onEnterPress={handleRename}
                        />
                        <span className={`gp-input-label ${error ? 'error-label' : ''}`}>Conversation Title</span>
                    </h2>
                    <div ref={kabobRef}>
                        {isAuthenticated() ? (
                            <MRAKabob
                                rename={handleEditing}
                                copyText={name}
                                clone={() => handleCloneConvo(convoUUID)}
                                onClick={handleKabobClick}
                            />
                        ) : (
                            <MRAKabob
                                copyText={name}
                                onClick={handleKabobClick}
                            />
                        )}
                    </div>
                    <div className="gradient_overlay"></div>
                </div>
                {error && <span className="error-display sticky-top1 ">{error}</span>}
            </div>
            {children}
        </div>
    );
};

const MRViewConversation = () => {

    const mrState = useContext(MainRegionContext);
    const [isLoading, setIsLoading] = useState(false);
    const status = useSelector(state => state.conversation.status)
    const { id } = useParams();


    useEffect(() => {
        if (
            (status[status?.length - 1]?.polling === true)
            && (status[status?.length - 1]?.loops_completed !== status[status.length - 1]?.total_loops)
        ) {
            setIsLoading(true)
        } else {
            setIsLoading(false);
        }
    }, [status]);
    return (
        <>
            {/* {responseContext.state.map((res) => (
                res?.loops_completed !== -1 &&
                <MRCPrompt prompt={res} receipt={res.prompt} />))} */}
            {(isLoading && !id) && (
                <div className="loading">
                    <SkeletonLoading runTime={mrState.loadingTime} />
                </div>
            )}
        </>
    )
}

/* Start Product New Conversation Flow Scope Creep */
const MRNewConversation = () => {
    return (
        <>
            {/*
        <MRConversationWrapper
                        convoName="New Conversation"
                        convoID="0">
        </MRConversationWrapper>
         */}
            <MRViewConversation />
        </>
    )
}
/* End Product New Conversation Flow Scope Creep */

const MRLoadExistingConversation = ({ convoID, forcefully = false }) => {
    const [convoLoading, setConvoLoading] = useState(false);
    const [currentConvoID, setcurrentConvoID] = useState();
    const [count, setCount] = useState(0)
    const [endOfPage, setIsEndOfPage] = useState(false)
    const [page, setPage] = useState(1)
    const [per_page, setPerPage] = useState(20)
    const [pagesLimit, setPagesLimit] = useState(5)
    const [order, setOrder] = useState("created_at,desc")
    const [lastReversedIndex, setLastReversedIndex] = useState(null)
    const [innerConvoIndex, setInnerConvoIndex] = useState(null)
    const [lastConvoSetId, setLastConvoSetId] = useState(null)
    const [responseSet, setResponseSet] = useState()
    const [firstConvoId, setFirstConvoId] = useState(0)
    const [last_convo_id, setLastConvoId] = useState()
    const [viewOneConvoIds, setViewOneConvoIds] = useState([])
    const [newConvoIds, setNewConvoIds] = useState([])
    const mrState = useContext(MainRegionContext);
    const fShowExistingConvo = mrState?.fShowExistingConvo;
    const [convoLength, setConvoLength] = useState(20)
    const { id } = useParams();
    const data = useSelector(state => state.conversation.data)
    const status = useSelector(state => state.conversation.status)
    const scrollBottom = useSelector(state => state.conversation.scrollBottom)
    const logsData = useSelector(state => state.logs.logsData)
    const expandResponse = useSelector(state => state.logs.expandResponse)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const innerRef = useRef();
    const [clientHeight, setClientHeight] = useState(0);

    useEffect(() => { //first time setting endOfPage and Child accrodian true to fetch data
        if (id) {
            setIsEndOfPage(true);
        }
        if (clickedLogsAccordian) {
            setConvoLoading(true)
        }
    }, []);

    useEffect(() => {
        if ((id && fShowExistingConvo)) { //for new conversation in logs
            dispatch(setScrollBottom(true))
            MRConvoAPI.getConvo(convoID, per_page, order, null, null, lastConvoSetId, innerConvoIndex)
                .then((response) => {
                    mrState.setMRConvoID(response.data.id)
                    dispatch(updateLogsData(response))
                    setResponseSet(response)
                    setInnerConvoIndex(response.data.meta.inner_convo_index)
                    setLastConvoSetId(response.data.meta.last_convo_set_id)
                    setCount(response.data.jobs_data.length - 1)
                    setPagesLimit(response.data.meta.total_pages)
                    setFirstConvoId(response.data.meta.first_convo_id)
                    setLastConvoId(response.data.meta.last_convo_id)

                })
                .finally(() => {
                    setcurrentConvoID(convoID);
                    setPage(2);
                })
            mrState?.setFShowExistingConvo(false);

        }
    }, [id, endOfPage, fShowExistingConvo]);

    useEffect(() => {
        if (status.length === 1 && scrollBottom) {
            if (id) {
                const container = document.getElementById("scrollableDiv");
                container.scrollTop = container?.scrollHeight;
                container?.scrollTo({ top: container?.scrollHeight, behavior: 'smooth' });
            }
        }
    }, [status?.length, scrollBottom]);

    useEffect(() => {
        if (fShowExistingConvo && !id) {
            setTimeout(() => {
                const container = document.getElementById("logsScroll");
                container.scrollTop = container?.scrollHeight;
                container?.scrollTo({ top: container?.scrollHeight, behavior: 'smooth' });
            }, 800)
        }

    }, [data?.jobs_data?.[data?.jobs_data?.length - 1]?.responses?.length ?? 0]);

    useEffect(() => {  //for fetching data on scroll top
        if (id && endOfPage && firstConvoId !== last_convo_id && !fShowExistingConvo) {
            fetchData()
        }
    }, [id, endOfPage, fShowExistingConvo]);    
    
    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            const lastJob = data?.jobs_data[data?.jobs_data.length - 1];
            if (lastJob && lastJob.responses) {
              const convoIds = lastJob.responses.map(response => response.ConvoId);
              setNewConvoIds(convoIds);
            }
        }
    },[data])

    

    useEffect(() => {
        if (logsData && logsData.length > 0) {
            const firstJobData = logsData[0]?.data?.jobs_data[0];
            if (firstJobData && firstJobData.responses) {
              const convoIds = firstJobData.responses.map(response => response.ConvoId);
              setViewOneConvoIds(convoIds);
            }
        }
    },[logsData])

    const fetchData = (page) => {
        setConvoLoading(true)
        const currentScrollPosition = innerRef?.current?.scrollTop;
        setTimeout(() => {
            if ((convoID !== currentConvoID || fShowExistingConvo || id)) {
                MRConvoAPI.getConvo(convoID, per_page, order, last_convo_id, lastReversedIndex, lastConvoSetId, innerConvoIndex)
                    .then((response) => {
                        mrState.setMRConvoID(response.data.id)
                        setLastReversedIndex(response.data.meta.last_index - 1)
                        setLastConvoSetId(response.data.meta.last_convo_set_id)
                        setInnerConvoIndex(response.data.meta.inner_convo_index)
                        dispatch(updateLogsData(response))
                        dispatch(updateDisableEditing(response?.data?.disable_editing))
                        dispatch(updateLogoutUser(response?.data?.log_out_user))
                        setResponseSet(response)
                        setConvoLength(convoLength + 20)
                        setCount(response.data.jobs_data.length - 1)
                        setPagesLimit(response.data.meta.total_pages)
                        console.log("response", response);
                        setLastConvoId(response.data.meta.last_convo_id)
                        setFirstConvoId(response.data.meta.first_convo_id)
                        if (status.length !== 1) {
                            dispatch(reset())
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            navigate('/error-404');
                        } else if (error.response.status === 401) {
                            navigate('/error-401');
                        }
                    })
                    .finally(() => {
                        setcurrentConvoID(convoID);
                        setConvoLoading(false);
                        setPage(page + 1)
                        dispatch(setClickedLogsAccordian(false))
                        innerRef.current.scrollTop = currentScrollPosition;
                    })
                mrState?.setFShowExistingConvo(false);
            } else {
                setConvoLoading(false);
                dispatch(setClickedLogsAccordian(false))
            }
        }, 1500);  
    }

    if (!id) {
        return (
            <> 
                {convoLoading && (
                    <div className="loading" style={{ marginTop: '6vh' }}>
                        <SkeletonLoading runTime={mrState.loadingTime} />
                    </div>
                )}
                {Object.keys(data).length > 0 && (
                    <MRConversationWrapper
                        convoUUID={data?.conversation_uuid}
                        convoName={data?.name}
                        convoID={currentConvoID}
                        receiptText={data?.jobs_data[0]?.job_receipt?.prompt}
                    >
                        {data?.jobs_data?.map((res) => (
                            <React.Fragment key={res?.id}>
                                <MRCReciept
                                    hpIconHover={res.job_receipt.hover_text}
                                    receiptData={res?.job_receipt}
                                    showgp={res?.job_receipt?.show_gp_icon}
                                />
                                {res?.responses?.map((prompt, index) => (
                                    prompt.type === "file" ? ( <div className="skeletion-div">
                                        <SkeletonLoaderFile  
                                        uploading_file={newConvoIds.includes(prompt.ConvoId)}
                                        response={prompt.response}
                                        fileState={mrState.fileState} key={index} /></div>
                                        ) : (
                                        <MRCPrompt
                                            expand={true}
                                            prompt={prompt}
                                            receipt={res.job_receipt.prompt}
                                            key={index}
                                        />
                                        )
                                ))}
                            </React.Fragment>
                        ))}
                        <MRViewConversation />
                    </MRConversationWrapper>
                )}
            </>

        )
    }
    else {
        return (
            <>
                <div
                    id="scrollableDiv"
                    className="scroll"
                    style={{
                        height: '100vh',
                        display: "flex",
                        flexDirection: "column-reverse",
                        width: "100%",
                        overflowX: "hidden",
                        margin: 0,
                        padding: 0,
                        boxSizing: "border-box"
                    }}
                    ref={innerRef}
                >
                    {logsData && (
                        <MRConversationWrapper
                            convoUUID={logsData[0]?.data.conversation_uuid}
                            convoName={logsData[0]?.data.name}
                            convoID={currentConvoID}
                            receiptText={logsData[count]?.data.jobs_data[0]?.job_receipt.prompt}
                        >
                            {
                                convoLoading && <div className="loading" style={{ marginTop: '6vh' }}>
                                    <SkeletonLoading runTime={mrState.loadingTime} />
                                </div>
                            }
                            <InfiniteScroll
                                dataLength={convoLength}
                                next={() => fetchData(page)}
                                style={{ overflow: "inherit", display: "flex", flexDirection: "column-reverse", height: '100%' }}
                                inverse={true}
                                hasMore={firstConvoId !== last_convo_id && !convoLoading}
                                scrollableTarget="scrollableDiv"
                            >
                                {
                                    (status?.length === 1) && <div className="loading">
                                        <SkeletonLoading runTime={mrState.loadingTime} />
                                    </div>
                                }
                                {logsData?.map((conversation) => (
                                    <React.Fragment key={conversation.id}>
                                        {conversation.data.jobs_data.map((res) => (
                                        <React.Fragment>
                                            {res.responses.map((prompt, index) => (
                                                prompt.type === "file" ? ( <div className="skeletion-div">
                                                <SkeletonLoaderFile response={prompt.response}
                                                    uploading_file={viewOneConvoIds.includes(prompt.ConvoId)}
                                                    fileState={mrState.fileState}
                                                    key={index} />
                                                    </div>
                                                ) : (
                                                <MRCPrompt
                                                    expand={expandResponse}
                                                    prompt={prompt}
                                                    receipt={res.job_receipt.prompt}
                                                    key={index}
                                                />
                                                )
                                            ))}
                                            <MRCReciept
                                                receiptData={res.job_receipt}
                                                showgp={res.job_receipt.show_gp_icon}
                                                hpIconHover={res.job_receipt.hover_text}
                                            />
                                        </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                                <MRViewConversation />
                            </InfiniteScroll>
                        </MRConversationWrapper>
                    )}
                </div>                    
            </>
        )
    }
}

const MRConversation = () => {
    const mrState = useContext(MainRegionContext);
    const { id } = useParams();
    const convoID = id ? id : mrState?.MRConvoID;
    //const convoID = 1;
    /*If Convo ID is 0
    setNewConvo*/
    if ((convoID === 0 || convoID === null)) {
        return (
            <>
                <MRNewConversation />
            </>
        )
    }
    /*If Convo ID is known
    setConvo
    */
    else if (convoID !== 0 || id) {
        console.log("convo")
        return (
            <MRLoadExistingConversation convoID={convoID} />
        );
    }
    else {
        return <></>
    }

};


export default MRConversation;