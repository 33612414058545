import React, {useContext} from "react";
import IconArrow from "../../icons/IconArrow";
import MainRegionContext from "../General/MRContext";



function MRBackButton() {
    const MainRegionState = useContext(MainRegionContext);
    
    const showMRLogs = () => {
        MainRegionState.setMRShow([
          {
            "MRTextHeader": true,
            "MRGigaPrompt": false,
            "MRInputBar": true,
            "MRConversation": true,
            "MRLogs": false
          }
      ]);
      };
    
    return (
        <>
            {/* new conversation icon */}
            <div className="icon-title-wrap" onClick={showMRLogs}>
                <IconArrow width='8' height='13' />
                <span className="hp-icon-title">Back to Conversation</span>
            </div>
        </>
    );
};


export default MRBackButton;