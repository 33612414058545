
import * as React from "react"

function IconExpand(props) {
  return (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.77203 9.99215H1.66543C1.22373 9.99215 0.800124 9.81668 0.487795 9.50436C0.175465 9.19203 0 8.76842 0 8.32672V2.22012C0 1.77842 0.175465 1.35481 0.487795 1.04248C0.800124 0.730152 1.22373 0.554688 1.66543 0.554688H2.77572C2.92296 0.554688 3.06416 0.613176 3.16827 0.717286C3.27238 0.821396 3.33087 0.962599 3.33087 1.10983C3.33087 1.25707 3.27238 1.39827 3.16827 1.50238C3.06416 1.60649 2.92296 1.66498 2.77572 1.66498H1.66543C1.5182 1.66498 1.377 1.72347 1.27289 1.82758C1.16878 1.93169 1.11029 2.07289 1.11029 2.22012V8.32672C1.11029 8.47395 1.16878 8.61515 1.27289 8.71926C1.377 8.82337 1.5182 8.88186 1.66543 8.88186H7.77203C7.91926 8.88186 8.06046 8.82337 8.16457 8.71926C8.26868 8.61515 8.32717 8.47395 8.32717 8.32672V7.21643C8.32717 7.06919 8.38566 6.92799 8.48977 6.82388C8.59388 6.71977 8.73508 6.66128 8.88232 6.66128C9.02955 6.66128 9.17075 6.71977 9.27486 6.82388C9.37897 6.92799 9.43746 7.06919 9.43746 7.21643V8.32672C9.43746 8.76842 9.262 9.19203 8.94967 9.50436C8.63734 9.81668 8.21373 9.99215 7.77203 9.99215Z" fill="#9D9D9D"/>
  <path d="M6.11399 0.000247139L9.44486 0.00024743C9.59209 0.000247443 9.73329 0.0587358 9.8374 0.162846C9.94151 0.266955 10 0.408159 10 0.555392L10 3.88626C10 4.03349 9.94151 4.1747 9.8374 4.27881C9.73329 4.38292 9.59209 4.44141 9.44486 4.44141C9.29762 4.44141 9.15642 4.38292 9.05231 4.27881C8.9482 4.1747 8.88971 4.03349 8.88971 3.88626L8.88971 1.11054L6.11399 1.11054C5.96675 1.11054 5.82555 1.05205 5.72144 0.947939C5.61733 0.843829 5.55884 0.702626 5.55884 0.555392C5.55884 0.408158 5.61733 0.266955 5.72144 0.162845C5.82555 0.0587355 5.96675 0.000247126 6.11399 0.000247139Z" fill="#9D9D9D"/>
  <path d="M9.44497 0.000323724C9.55475 0.000347099 9.66206 0.0329184 9.75333 0.0939202C9.8446 0.154922 9.91574 0.241615 9.95775 0.34304C9.99976 0.444465 10.0108 0.556067 9.98934 0.663739C9.96793 0.771411 9.91508 0.870318 9.83746 0.947956L5.3963 5.38911C5.34509 5.44214 5.28383 5.48443 5.2161 5.51352C5.14838 5.54262 5.07553 5.55793 5.00182 5.55857C4.92811 5.55921 4.855 5.54517 4.78678 5.51725C4.71855 5.48934 4.65657 5.44812 4.60445 5.396C4.55232 5.34387 4.5111 5.28189 4.48319 5.21366C4.45527 5.14544 4.44123 5.07234 4.44187 4.99862C4.44251 4.92491 4.45782 4.85207 4.48692 4.78434C4.51601 4.71661 4.55831 4.65535 4.61133 4.60414L9.05249 0.162981C9.15657 0.058864 9.29775 0.000355182 9.44497 0.000323724Z" fill="#9D9D9D"/>
  </svg>

  );
}


export default IconExpand
