import React, { useState, useContext, useEffect } from 'react';
import { AccordionHeader, AccordionItem } from "react-headless-accordion";
import IconAccordion from "../../icons/IconAccordion";
import IconArrowCircle from '../../icons/IconArrowCircle';
import IconCheckMark from '../../icons/LogsIcons/IconCheckmark'
import MRAKabob from "../General/MRKabob";

import MRLContext from '../MRLogs/MRLContext';
import MainRegionContext from '../General/MRContext';
import { MRLogsAPI } from "../../../helper/MRLogsAPI";
import { Link, useNavigate, useParams } from 'react-router-dom';
import coversationContext from '../../../context/conversationContext';


const MRReadMore = ({ children }) => {
    const conversation = useContext(coversationContext);
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const highlightMatch = (text, searchTerm) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, "gi");
        return text.replace(regex, "<span class='highlight-searched'>$1</span>") 
    };
        
    const renderHighlightedText = (text) => {
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: highlightMatch(text, conversation.searched),
                }}
            />
        );
    };

    const highlightedText = renderHighlightedText(text);
    const highlightedTextContent = highlightedText.props.dangerouslySetInnerHTML.__html;
 
    return (
        <p className="text" onClick={toggleReadMore}>
            <span dangerouslySetInnerHTML={{ __html: isReadMore ? highlightedTextContent : highlightedTextContent.slice(0, 50) }} />
        </p>
    );
};

const MRLAccordionChild = (props) => {
    const mainRegionLogs = useContext(MRLContext);
    const MainRegionState = useContext(MainRegionContext);
    const navigate = useNavigate();

    const openConversation = () => {
            navigate(`/conversation/${props.uuid}`);
            return
        }

    async function deleteJob(jobID){
        await MRLogsAPI.deleteJob(jobID);
        const newLogs = await MRLogsAPI.getLogsData();
        mainRegionLogs.setlogsData(newLogs);
    }
 
    console.log(props.uuid)
    return (
        <>
            <AccordionItem>
                <div className={`acc_header_wrapper childConversation ${mainRegionLogs?.moveSelected && 'notAllowedCursor'}`}>
                    <AccordionHeader className={`titleWrap ${mainRegionLogs?.moveSelected && 'notAllowedCursor'}`} key={props.job.id} onClick={props.event}>
                        <div className="acco_col" onClick={openConversation}>
                            <div className="AccoIcon Icon-90">
                                <IconAccordion width="20" height="12" />
                            </div>
                            <div className="Acco_title_box">
                                <span className="subTitle">{props.job.header}</span>
                                <span className={`accordion-title`}>
                                {props.job.name &&
                                    <MRReadMore>
                                        {props.job.name}
                                    </MRReadMore>
                                }
                                    </span>
                            </div>
                        </div>
                    </AccordionHeader>
                    <div className="MRLog_button_wrapper MRCprompt_wrap d-flex align-items-center">
                        { mainRegionLogs?.moveSelected &&  (props.moveChildren || props.moveState) ? 
                            <button className="AccoIcon customIcon icon-btn notAllowedCursor btn btn-primary" >   
                                <IconCheckMark />  
                            </button>
                            :
                            <button className={`AccoIcon customIcon icon-btn btn btn-primary ${mainRegionLogs?.moveSelected && 'notAllowedCursor' }`} onClick={openConversation}>   
                                <IconArrowCircle/>
                            </button>
                        }
                       <MRAKabob 
                        copyText={props.job.name} 
                        deleteJob={ () => {deleteJob(props.job.id)}} />
                    </div>

                </div>
            </AccordionItem>

        </>
    );
};

export default MRLAccordionChild;