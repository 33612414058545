import React, { useState, useContext, useEffect } from "react";
import IconGP from "../../icons/IconGP";
import IconPromptMultiplier from "../../icons/IconPromptMultiplier";
import IconQualityBooster from "../../icons/IconQualityBooster";
import IconVariations from "../../icons/IconVariations";
import MRBackButton from "../MRBackButton/MRBackButton";
import GPSettings from "./MRGPSettings";
import coversationContext from "../../../context/conversationContext";


function GigaPromptingHeader({ isActive, props }) {
    return (
        <>
            {/* Atticus Steps */}
            <div className={`HP-step ${isActive ? 'active' : ''}`}>
                <span className="hp-icon">
                    <IconGP width='36' height='36' fill='#495FAB' />
                    {/* vertical line */}
                    <div className="vertical_line"></div>
                </span>
                <div className="hp-content">
                    <div className="flex-box">
                        <span className="hp-title">
                            Features
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

function MRGigaPromptSettings() {
    const conversation = useContext(coversationContext);

    /*Set Variations*/
    const setVariationActiveHandler = () => {
        conversation.setVariationActive(!conversation.variationActive)
    }

    /*Set Multiplier*/
    const setMultiplierActiveHandler = () => {
        conversation.setMultiplierActive(!conversation.multiplieriationActive)
    }

    /*Set Quality Booster*/
    const setqualityBoosterActiveHandler = () => {
        //setqualityBoosterActive(!qualityBoosterActive)
        conversation.setqualityBoosterActive(false)
    }

    /*
    Moved those states to be handled in context 
    useEffect(() => {
        conversation.update({
            "variationActive": variationActive,
            "multiplieriationActive": multiplieriationActive,
            "qualityBoosterActive": qualityBoosterActive
        })
    },
    [variationActive, multiplieriationActive, qualityBoosterActive])
    */

    return (
        <>
        <MRBackButton />
            <div className='hp-conversation-wrapper'>
                <div className="HPsteps-wrap">

                    <GigaPromptingHeader isActive={
                        conversation.variationActive || conversation.multiplieriationActive || conversation.qualityBoosterActive} />

                    {/*Variations*/}
                    <GPSettings
                        active={conversation.variationActive}
                        setActive={conversation.setVariationActive}
                        onClick={setVariationActiveHandler}
                        disabled={false}
                        name="Variations"
                        icon={<IconVariations width='36' height='36' />}
                        range={false}
                    />

                    {/* Multiplier */}
                    <GPSettings
                        active={conversation.multiplieriationActive}
                        setActive={conversation.setMultiplierActive}
                        onClick={setMultiplierActiveHandler}
                        disabled={false}
                        name="Prompt Multiplier"
                        icon={<IconPromptMultiplier width='36' height='32' />}
                        range={true}
                        rangeInput="Prompt Multiplier"
                        rangeValue = {conversation.multiplierValue}
                        setRangeValue = {conversation.setmultiplierValue}
                    />

                    {/* Quality Booster */}
                    <GPSettings
                        active={conversation.qualityBoosterActive}
                        setActive={conversation.setqualityBoosterActive}
                        onClick={setqualityBoosterActiveHandler}
                        disabled={false}
                        name="Quality Booster"
                        nameSubTitle="Tree of thoughts"
                        nameSubSubTitle="!Experimental uses LOTS of credits!"
                        icon={<IconQualityBooster width='24' height='36' />}
                        range={true}
                        rangeInput="Set Loop(s)"
                        rangeValue = {conversation.boosterValue}
                        setRangeValue = {conversation.setboosterValue}
                    />
                </div>

            </div>
        </>
    );
};


export default MRGigaPromptSettings;