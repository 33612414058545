const IconKabob = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="5" height="20" viewBox="0 0 5 20" fill="none">
        <g filter="url(#filter0_i_2609_13693)">
          <rect x="4.28613" y="7.85547" width="4.28568" height="4.28568" rx="2.14284" transform="rotate(90 4.28613 7.85547)" fill="#C7C7C7" />
          <rect x="4.28613" y="15.7148" width="4.28568" height="4.28568" rx="2.14284" transform="rotate(90 4.28613 15.7148)" fill="#C7C7C7" />
          <rect x="4.28613" width="4.28568" height="4.28568" rx="2.14284" transform="rotate(90 4.28613 0)" fill="#C7C7C7" />
        </g>
        <defs>
          <filter id="filter0_i_2609_13693" x="0" y="0" width="4.28613" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0 0.45 0 0 0 0.46 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2609_13693" />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default IconKabob;