// src/apis/MRConvoAPI.js

import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

import Cookies from 'js-cookie';

//SET Credentials when live
const credentials = false

export const MRConvoAPI = {
  
    getAIModels: async function (cancel = false) {
      const response = await api.request({
        method: "GET",
        url: `/api/v1/ai_models`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      })
      return response.data
    },
    getConfigurations: async function (convoID,  cancel = false) {
      const response = await api.request({
        method: "GET",
        url: `api/v2/configurations`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        // signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
      })
      return response.data
    },
    generate_report: async function (variation_enabled, prompt_multiplier, tot_enabled,tot_loops,ai_model_id,has_prompt_multiplier ,cancel = false) {
      const response = await api.request({
        method: "POST",
        url: `/api/v2/generate_reports`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        // signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
        params: {
          variation_enabled,
          prompt_multiplier,
          tot_enabled,
          tot_loops,
          prompt: 'generate report',
          ai_model_id: ai_model_id,
          has_prompt_multiplier
        },
      })
      return response.data
    },
    can_use_atticus: async function (cancel = false) {
      const response = await api.request({
        method: "GET",
        url: `/api/v2/can_use_atticus`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
      })
      return response.data
    },
    getConvo: async function (convoID, per_page, order, last_convo_id, lastReversedIndex, lastConvoSetId,innerConvoIndex,  cancel = false) {
      const response = await api.request({
        method: "GET",
        url: `api/v1/conversation/${convoID}`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },   
        params: {
          per_page,
          order,
          last_convo_id,
          last_index: lastReversedIndex,
          last_convo_set_id: lastConvoSetId,
          inner_convo_index: innerConvoIndex
        },
      })    
      // returning the product returned by the API
      return response.data
    },
    cloneConvo: async function ({convoUUID}, cancel = false) {
     const response =  await api.request({
        method: "POST",
        url: `api/v1/conversation/${convoUUID}/clone_conversation`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        }
      })
      return response.data
    },    
    renameConvo: async function ({convoID, name}, cancel = false) {
      await api.request({
        method: "POST",
        url: `api/v1/conversation/${convoID}/rename`,
        withCredentials: credentials,
        data: {
          name: name
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        }
      })
    },
    deleteConvo: async function (convoID, cancel = false) {
      const response = await api.request({
        method: "POST",
        url: `/api/v1/conversation/${convoID}/delete`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        }
      })
            return response
    },
    createConvo: async function ({folder_id}, cancel = false) {
      const response = await api.request({
        method: "POST",
        url: `/api/v1/conversation`,
        withCredentials: credentials,
        // data: {
        //   folder_id: folder_id
        // },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        }
      })
      return response.data
    },
}

// defining the cancel API object for MRConvoAPI
const cancelApiObject = defineCancelApiObject(MRConvoAPI)