import * as React from "react"

const IconArrow = (props) => {
    return (
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={props.width} 
        height={props.height} 
        viewBox="0 0 8 13" 
        fill="none">
            <rect x="7.58398" y="1.08398" width="9.19226" height="1.53204" rx="0.766022" transform="rotate(135 7.58398 1.08398)" fill="#5DCDF5" />
            <rect x="6.5" y="13" width="9.19226" height="1.53204" rx="0.766022" transform="rotate(-135 6.5 13)" fill="#5DCDF5" />
        </svg>
    );
};

export default IconArrow;