import React, {  useEffect } from 'react'
import HPLogo from '../../images/HP_logo.svg'
import loginForeground from '../../images/loginForeground.png'
import loginMiddleground from '../../images/loginMiddleground.png'
import loginBackground from '../../images/loginBackground.png'
const HPLoginlcol = () => {
    useEffect(() => {
      document.addEventListener('mousemove', parallax);
      return () => {
        document.removeEventListener('mousemove', parallax);
      };
    }, []);
  
    function parallax(event) {
      const middleground = document.querySelector('.loginMiddleground');
      const loginBackground =  document.querySelector('.loginBackground'); 
      const centerX = window.innerWidth / 2;
      const shiftX = event.pageX - centerX ;
      const shiftY = (window.innerHeight - event.pageY);
      
      const shiftLoginBackgroundX = Math.min(((shiftX + event.pageX * 0.35) / 100) + 9);
      const shiftBackgroundY = Math.min(((shiftY+ event.pageY * 1.25) / 100) - 7);
      const shiftMiddlegroundX = Math.min(((shiftX + event.pageX * 0.7) / 100) + 7);
      const shiftMiddlegroundY = Math.min((shiftY+ event.pageY * 2.5) / 100);

      middleground.style.transform = `translateX(max(${shiftMiddlegroundX}px)) translateY(${shiftMiddlegroundY}px)`;
      loginBackground.style.transform = `translateX(max(${shiftLoginBackgroundX}px)) translateY(${shiftBackgroundY}px)`;
    }

 
    return (
        <>
            {/* HP Header logo */}
            <a href="./" className="HP_header_logo">
                <img src={HPLogo} alt="Atticus Logo" />
                <span>Atticus</span>
            </a>
            <div className="photo_col">
                <img className='loginBackground' src={loginBackground} alt="" />
                <img className='loginMiddleground' src={loginMiddleground} alt=""  />
                <img className='loginForeground' src={loginForeground} alt=""  />
                <h1 className="log_title">42X Prompting Accelerator </h1>
            </div>
        </>
    );
};

export default HPLoginlcol;
