import spacePhoto from '../../images/space.png';

const MobileVersion = () => {
    return (
        <>
            <div className="mobileViewPort">
                <div className="warningPage">
                    <span className="pageTitleBtn">Size Break</span>
                    <div className="pageContent">
                        <h1 className="pageTitle titlexl">Our free range robots need space...</h1>
                        <img src={spacePhoto} alt="Space Photo" />
                        <h3 className="pageTitle titleSM">please use a minimum screen size of 800px <span>or</span><span>use  a monitor</span></h3>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileVersion;