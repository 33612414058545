import { useState } from "react";
import subscriptionContext from './subscriptionContext';

const SubscriptionState = (props) => {
    const data = {
        channelSub: null
    }
    const [subData, setSubData] = useState(data);
    const update = (props) => {
      setSubData({
          channelSub: props?.channelSub !== undefined ? props?.channelSub : subData?.channelSub}
          );
    }
    return (
        <subscriptionContext.Provider value={{ subData, update }}>
            {props.children}
        </subscriptionContext.Provider>
    )
}

export default SubscriptionState;