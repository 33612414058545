import { createSlice, current } from '@reduxjs/toolkit'



const initialState = {
    status: [],
    responseData: [],
    receipt: [],
    convoId: null,
    data: {},
    conversationSetId: null,
    dataId: null,
    conversationName: '',
    count: 0,
    clickedLogsAccordian: false,
    scrollBottom: false
}


export const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        update: (state, action) => {
            state.status = [...state.status, action.payload];
        },
        updateReceipt: (state, action) => {
            state.receipt = action.payload;
        },
        setConversationSetId: (state, action) => {
            state.conversationSetId = action.payload;
        },
        setDataId: (state, action) => {
            state.dataId = action.payload
        },
        setConversationName: (state, action) => {
            state.conversationName = action.payload
        },
        updateConvoId: (state, action) => {
            state.convoId = action.payload
        },

        updateResponse: (state, action) => {
            const data = action.payload;

            if (data?.receipt_data) {       //first time if responses not present than push only receipt
                state.responseData.push({
                    "ConvoId": '',
                    "Persona": '',
                    "is_image": '',
                    "image_url": '',
                    "response": '',
                    "is_user_prompt": true,
                    "ai_model_url": '',
                    "loops_completed": '',
                    "total_loops": '',
                    "polling": '',
                    "prompt": data.receipt_data.prompt,
                    "hover_text": data.receipt_data.hover_text,
                    "parent_conversation_uuid": ''
                });
            }
            else {
                state.responseData.push(data[0]);
            }

            if (state?.data?.jobs_data) {
                const lastJobData = state?.data?.jobs_data[state?.data?.jobs_data?.length - 1];
                if (lastJobData.id === state?.conversationSetId) {
                    lastJobData.responses.push(state.responseData[state.count]);
                } else {
                    state.data.jobs_data = [
                        ...state.data.jobs_data,
                        {
                            id: state?.conversationSetId,
                            responses: [],
                            job_receipt: state?.receipt,
                        },
                    ];
                }
            } else {
                state.data = {
                    conversation_uuid: '',
                    created_on: '',
                    id: state?.dataId,
                    name: state?.conversationName,
                    jobs_data: [
                        {
                            id: state?.conversationSetId,
                            responses: [],
                            job_receipt: state?.receipt,
                        }
                    ]
                }
            }
            state.count = state.count + 1
        },
        setClickedLogsAccordian: (state, action) => {
            state.clickedLogsAccordian = action.payload
        },
        setScrollBottom: (state, action) => {
            state.scrollBottom = action.payload
        },
        reset: (state) => {
            state.status = []
        },
        resetResponseData: (state) => {
            state.data = {};
            state.responseData = [];
            state.count = 0;
        }
    },
})


export const { update, updateResponse, reset, updateReceipt,
    updateConvoId, responseObject, data, setConversationSetId, setDataId,
    setConversationName, resetResponseData, clickedLogsAccordian, setClickedLogsAccordian, setScrollBottom, scrollBottom } = conversationSlice.actions

export default conversationSlice.reducer