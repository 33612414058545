import React, { useEffect, useState } from "react";
import coversationResponseContext from "./conversationResponseContext";

const ConversationResponseState = (props) => {
    const [state, setState] = useState([]);
    const [responseData, setResponseData] = useState([])
    const [isPolling, setIsPolling] = useState(false)
    const [buttonPressed, setButtonPressed] = useState(false)
    const [clickedChild,setClickedChild] = useState(false)


    const updateResponseData = (props) => {
        if (props.data.meta.current_convo_id === 0 || props.data.meta.current_convo_id === null) {
            setResponseData([props])
        }
        else {
            setResponseData((prevState) => {
                const index = prevState.findIndex(
                    (item) => item.data?.jobs_data[item.data?.jobs_data.length - 1]?.id === props.data?.jobs_data[0]?.id  // 1 0

                );

                if (index !== -1) {
                    const existingResponses = prevState[index].data.jobs_data[prevState[index].data.jobs_data.length - 1]?.responses;
                    const newResponses = props.data.jobs_data[props.data.jobs_data.length - 1]?.responses;
                    prevState[index].data.jobs_data[prevState[index].data.jobs_data.length - 1].responses = newResponses.concat(existingResponses);
                    props.data.jobs_data = props.data.jobs_data.slice(1)
                    return [...prevState, props];
                }

                return [...prevState, props];

            });
        }
    };
    const update = (props) => {
        setState(prevState => { return [...prevState, props] });
    }

    const setPolling = (props) => {
        setIsPolling(props)
    }

    const setResponse = (props) => {
        setResponseData((prevState) => {
            if (prevState[0]?.data?.jobs_data[0]?.responses[0]?.ConvoId === props[0]?.ConvoId) {
                return prevState
            }
            else {
                const newData = prevState
                newData[0]?.data?.jobs_data[0]?.responses.unshift(props[0])
                return newData
            }
        })
    }
    const reset = (props) => {
        setState([]);
    }

    const resetResponseData = (props) => {
        setResponseData([]);
    }
    return (
        <coversationResponseContext.Provider value={{ state, isPolling, update, responseData, reset, 
        updateResponseData, resetResponseData, setResponse, setPolling,
        buttonPressed, setButtonPressed, clickedChild,setClickedChild }}>
            {props.children}
        </coversationResponseContext.Provider>
    )
}

export default ConversationResponseState;