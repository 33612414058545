import { useContext, useState } from "react";
import coversationContext from "./conversationContext";
import MainRegionContext from "../components/main-region/General/MRContext";

const ConversationState = (props) => {
    
    const [variationActive, setVariationActive] = useState(false);
    const [multiplieriationActive, setMultiplierActive] = useState(false);
    const [qualityBoosterActive, setqualityBoosterActive] = useState(false);
    const [multiplierValue, setmultiplierValue] = useState(1);
    const [boosterValue, setboosterValue] = useState(0);
    const [aiModelId, setaiModelId] = useState(0);
    const [aiModalValue, setaiModelValue] = useState('')
    const [aiModelHeader, setaiModelHeader] = useState('')
    const [aiModelImage,setaiModelImage] = useState('')
    const [aiModelResponse,setaiModelResponse] = useState('')
    const [aiModaltype, setaiModeltype] = useState('')
    const [prompt, setPrompt] = useState("");
    const [title, setTitle] = useState("")
    const [searchInputBar, setSearchInputBar] = useState("")
    const [searched, setSearched] = useState("")
    const [isFocused, setIsFocused] = useState(false);

    const data = {
        variationActive: variationActive,
        multiplieriationActive: multiplieriationActive,
        qualityBoosterActive: qualityBoosterActive,
        multiplierValue: multiplierValue,
        boosterValue: boosterValue,
        aiModelId: aiModelId,
    }
    const [state, setState] = useState(data);

    const update = (props) => {
        setState({});
    }

    const resetAiModelValue = () => {
        setTitle("")
    }

    return (
        <coversationContext.Provider value={
            { 
            state, update, searchInputBar, setSearchInputBar,searched, setSearched,
            variationActive, setVariationActive,
            multiplieriationActive, setMultiplierActive,
            qualityBoosterActive, setqualityBoosterActive,
            multiplierValue, setmultiplierValue,
            boosterValue, setboosterValue,
            aiModelId, setaiModelId,
            aiModalValue, setaiModelValue,
            aiModelImage,setaiModelImage,
            aiModelHeader, setaiModelHeader,
            aiModelResponse,setaiModelResponse,
            aiModaltype, setaiModeltype,
            prompt, setPrompt,
            title, setTitle, resetAiModelValue,
            isFocused, setIsFocused
            }}>
            {props.children}
        </coversationContext.Provider>
    )
}

export default ConversationState;