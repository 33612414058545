const Icon = (props) => {
    return (
        <>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.1602 5.60425C16.1552 5.60975 16.1501 5.61511 16.1448 5.62035L6.82894 14.8037C6.03201 15.5893 5.08683 16 4.08288 16C4.00662 16 3.93005 15.9976 3.85315 15.9929C2.89406 15.9336 1.96359 15.5044 1.23317 14.7844C0.502745 14.0644 0.0673925 13.1471 0.00722933 12.2017C-0.0611017 11.1278 0.356023 10.1135 1.21348 9.26819L10.4988 0.115019C10.6544 -0.038356 10.9067 -0.0383233 11.0623 0.115019C11.2179 0.268427 11.2179 0.517113 11.0623 0.670488L1.77696 9.82366C0.248546 11.3304 0.715773 13.1634 1.79665 14.229C2.87756 15.2944 4.73704 15.755 6.26546 14.2483L15.5731 5.07297C16.5955 3.93666 16.2496 2.63559 15.4514 1.84878C14.6532 1.06194 13.3334 0.720991 12.1807 1.72879L6.23375 7.59112C5.44828 8.36542 5.67339 9.28053 6.24491 9.84392C6.81646 10.4073 7.74477 10.6292 8.53024 9.85489L13.6769 4.7814C13.8325 4.62799 14.0848 4.62799 14.2404 4.7814C14.396 4.93481 14.396 5.18346 14.2404 5.33687L9.09372 10.4104C7.97728 11.5109 6.53207 11.2379 5.68146 10.3994C4.83084 9.5609 4.5539 8.13621 5.67027 7.03568L11.6254 1.16527C11.6307 1.16007 11.6361 1.155 11.6417 1.15009C12.3306 0.543526 13.1409 0.260964 13.9848 0.333233C14.7305 0.396992 15.4515 0.737945 16.0148 1.29332C16.5782 1.84865 16.9241 2.55936 16.9888 3.29449C17.0621 4.12647 16.7755 4.92519 16.1602 5.60425Z" fill="black" />
            </svg>

        </>
    );
};

export default Icon;
