const Icon = (props) => {
    return (
        <>
<svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Layer_1-2">
<path id="Vector" d="M12.2665 9.20763C12.2665 10.5743 12.2665 11.9476 12.2665 13.3143C12.2665 14.1543 11.9198 14.8476 11.2798 15.3876C10.9198 15.6876 10.4998 15.8743 10.0398 15.9543C9.87984 15.981 9.71317 15.9943 9.55317 15.9943C7.53317 15.9943 5.51317 15.9943 3.49984 15.9943C3.21984 15.9943 2.94651 15.9676 2.67984 15.881C1.81984 15.5943 1.21317 15.0276 0.906507 14.1676C0.806507 13.8876 0.75984 13.601 0.75984 13.3076C0.75984 13.141 0.75984 12.9676 0.75984 12.801C0.75984 12.6943 0.753174 12.5943 0.753174 12.4876C0.753174 11.9276 0.75984 11.3743 0.766507 10.8143C0.766507 10.561 0.75984 10.301 0.753174 10.0476C0.753174 9.97429 0.753174 9.90096 0.753174 9.82763C0.753174 9.18763 0.75984 8.54096 0.766507 7.90096C0.766507 7.65429 0.766507 7.40763 0.766507 7.16763C0.766507 6.95429 0.75984 6.73429 0.75984 6.52096C0.75984 6.30763 0.766507 6.10096 0.766507 5.88763C0.766507 5.68096 0.75984 5.48096 0.75984 5.27429C0.75984 5.15429 0.75984 5.04096 0.773174 4.92096C0.79984 4.70096 0.986507 4.52763 1.21317 4.49429C1.47317 4.46096 1.70651 4.62096 1.77317 4.82763C1.79317 4.89429 1.80651 4.96096 1.80651 5.03429C1.80651 7.78763 1.80651 10.5343 1.80651 13.2876C1.80651 14.0076 2.31984 14.7343 3.07317 14.9076C3.20651 14.941 3.33984 14.9543 3.47317 14.9543C5.45984 14.9543 7.45317 14.9543 9.43984 14.9543C9.69317 14.9543 9.94651 14.9276 10.1865 14.8343C10.5065 14.7143 10.7598 14.5076 10.9532 14.221C11.0998 14.001 11.1932 13.761 11.2265 13.4943C11.2398 13.4076 11.2398 13.321 11.2398 13.2343C11.2398 10.501 11.2398 7.76096 11.2398 5.02763C11.2398 4.83429 11.3132 4.66096 11.4865 4.56096C11.7798 4.39429 12.1465 4.49429 12.2532 4.84763C12.2865 4.95429 12.2798 5.06763 12.2798 5.18096C12.2798 6.52096 12.2798 7.86096 12.2798 9.20096L12.2665 9.20763Z" fill="#060E1E"/>
<path id="Vector_2" d="M6.49988 3.28747C4.51322 3.28747 2.52655 3.28747 0.539884 3.28747C0.273218 3.28747 0.0532176 3.10747 0.00655097 2.84081C-0.0401157 2.58747 0.159884 2.30081 0.413218 2.26081C0.459884 2.25414 0.513218 2.24747 0.559884 2.24747C1.51988 2.24747 2.47988 2.24747 3.43322 2.24747C3.61988 2.24747 3.73988 2.12747 3.73988 1.94081C3.73988 1.47414 3.73988 1.00081 3.73988 0.534141C3.73988 0.294141 3.89322 0.087474 4.11988 0.027474C4.18655 0.00747396 4.25988 -0.00585938 4.32655 -0.00585938C5.77322 -0.00585938 7.22655 -0.00585938 8.67322 -0.00585938C8.72655 -0.00585938 8.77988 -0.00585938 8.83322 0.00747396C9.10655 0.0608073 9.26655 0.260807 9.26655 0.540807C9.26655 1.00747 9.26655 1.47414 9.26655 1.94081C9.26655 2.12747 9.39988 2.24747 9.57322 2.24747C10.5466 2.24747 11.5132 2.24747 12.4866 2.24747C12.7332 2.24747 12.9266 2.37414 12.9932 2.64747C13.0599 2.91414 12.8932 3.22747 12.5999 3.27414C12.4999 3.28747 12.3932 3.28747 12.2932 3.28747C10.3599 3.28747 8.42655 3.28747 6.49322 3.28747H6.49988ZM6.50655 2.24747C6.98655 2.24747 7.46655 2.24747 7.94655 2.24747C8.09322 2.24747 8.23322 2.12081 8.23322 1.97414C8.23322 1.76747 8.23322 1.55414 8.23322 1.34747C8.23322 1.19414 8.15322 1.08747 8.02655 1.05414C7.97322 1.04081 7.91322 1.04747 7.85322 1.04747C6.92655 1.04747 6.00655 1.04747 5.07988 1.04747C4.89988 1.04747 4.77988 1.15414 4.77322 1.34747C4.77322 1.54747 4.77322 1.74081 4.77322 1.94081C4.77322 2.12747 4.89322 2.24747 5.07988 2.24747C5.55322 2.24747 6.02655 2.24747 6.49988 2.24747H6.50655Z" fill="#060E1E"/>
<path id="Vector_3" d="M5.53998 9.12845C5.53998 9.97512 5.53998 10.8284 5.53998 11.6751C5.53998 11.8084 5.51998 11.9418 5.42665 12.0551C5.29998 12.2151 5.13331 12.2884 4.93331 12.2551C4.72665 12.2218 4.57331 12.1018 4.51331 11.8884C4.48665 11.8018 4.48665 11.7084 4.48665 11.6151C4.48665 11.4618 4.48665 11.3151 4.48665 11.1618C4.48665 11.0284 4.47998 10.8951 4.47998 10.7684C4.47998 9.66178 4.48665 8.55512 4.49331 7.44845C4.49331 7.20845 4.49331 6.96845 4.49331 6.72845C4.49331 6.62178 4.49331 6.50845 4.50665 6.40178C4.53998 6.17512 4.77998 5.98178 4.99998 5.98845C5.24665 6.00178 5.51331 6.14178 5.52665 6.47512C5.52665 6.58845 5.53331 6.70178 5.53331 6.82178C5.53331 7.58845 5.53331 8.36178 5.53331 9.12845H5.53998Z" fill="#060E1E"/>
<path id="Vector_4" d="M8.53332 9.1407C8.53332 9.99403 8.53332 10.8474 8.53332 11.694C8.53332 11.9607 8.43332 12.1207 8.20666 12.2207C7.91332 12.3474 7.55999 12.1607 7.49999 11.8407C7.49999 11.814 7.49999 11.7874 7.49999 11.7607C7.49999 10.154 7.49999 8.55403 7.49999 6.94736C7.49999 6.83403 7.48666 6.7207 7.49332 6.61403C7.49332 6.52736 7.50666 6.43403 7.52666 6.35403C7.57332 6.16736 7.78666 5.98736 7.99999 6.0007C8.23999 6.01403 8.42665 6.11403 8.49999 6.3207C8.52665 6.39403 8.53999 6.4807 8.53999 6.5607C8.53999 7.4207 8.53999 8.2807 8.53999 9.14736L8.53332 9.1407Z" fill="#060E1E"/>
</g>
</svg>


        </>
    );
};

export default Icon;