import React, { useEffect, useRef, useContext, useState } from "react";
import ConversationContext from '../../../context/conversationContext';

const EditableText = ({
  isEditing,
  handleEditing,
  searchedName,
  name,
  setName,
  label,
  handleBlur,
  error_msg,
  ClickedKabob,
  onEnterPress
}) => {
  const context = useContext(ConversationContext);
  const inputRef = useRef(null);
  const [shouldFocusInput, setShouldFocusInput] = useState(false);

  useEffect(() => {
    if (isEditing && ClickedKabob && inputRef.current) {
      setShouldFocusInput(true);
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [isEditing, ClickedKabob]);


  useEffect(() => {
    if (shouldFocusInput && inputRef.current) {
      inputRef.current.focus();
      setShouldFocusInput(false);
    }
  }, [shouldFocusInput]);

  const handleClick = () => {
    if (!isEditing) {
      handleEditing(true);
      setShouldFocusInput(true);
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;

    // character limit
    if (inputValue.length <= 32) {
      setName(inputValue);
    }
  };

  const highlightMatch = (text, searchTerm) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.replace(regex, "<span class='highlight-searched'>$1</span>");
  };

  const renderHighlightedText = (text) => {
    return (
      <span
        className="editable-span"
        dangerouslySetInnerHTML={{
          __html: highlightMatch(text, searchedName),
        }}
      />
    );
  };

  const handleBlurAll = () => {
    if (!ClickedKabob && handleBlur) {
      handleBlur();
      blurInput();
    }
  };

  const focusInput = () => {
    if (context.setIsFocused) {
      context.setIsFocused(true);
    }
  };

  const blurInput = () => {
    if (ClickedKabob && context.setIsFocused) {
      context.setIsFocused(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.target.blur();
        handleBlurAll();
        context.setIsFocused(false);
        onEnterPress();
    } else {
        const inputValue = event.target.value;
        if (inputValue.length <= 32) {
            setName(inputValue);
        }
    }
};


  useEffect(() => {
    if (ClickedKabob && inputRef.current) {
      handleEditing(true)
    }
  }, [ClickedKabob]);

  return (
    <div onClick={handleClick}>
      {isEditing ? (
        <>
          <input
            className={`editable-input pl-2`}
            ref={inputRef}
            type="text"
            label={label}
            value={name}
            onChange={handleChange}
            onBlur={handleBlurAll}
            onFocus={focusInput}
            onKeyDown={handleKeyDown}
          />
          <span className={`gp-input-label ${error_msg ? 'error-label' : ''}`}>{label}</span>
        </>
      ) : (
        renderHighlightedText(name)
      )}
    </div>
  );
};

export default EditableText;