import React, { useState, useContext, useEffect } from 'react';
import { Accordion, AccordionHeader, AccordionBody, AccordionItem } from "react-headless-accordion";

import IconAccordion from "../../icons/IconAccordion";
import MRAKabob from "../General/MRKabob";
import EditableText from "../General/MREditableText";

import AccordionChild from './MRLAccordionChild'
import MRContext from '../General/MRContext'
import MRLContext from '../MRLogs/MRLContext'
import IconCheckMark from '../../icons/LogsIcons/IconCheckmark'

import { MRConvoAPI } from "../../../helper/MRConversationAPI";
import { MRLogsAPI } from "../../../helper/MRLogsAPI"
import IconArrowCircle from '../../icons/IconArrowCircle';
import IconCancelConfirmationConvo from '../../icons/LogsIcons/IconCancelConfirmationFolder';
import IconConfirmConvo from '../../icons/LogsIcons/IconConfirmFolder';
import IconsAreYouSureDelete from '../../icons/LogsIcons/IconsAreYouSureDelete';
import IconsAreYouSureCancel from '../../icons/LogsIcons/IconsAreYouSureCancel';
import { Link, useNavigate } from 'react-router-dom';
import { resetLogsData } from '../../../redux/slices/logsSlice';
import { resetResponseData, setClickedLogsAccordian } from '../../../redux/slices/conversationSlice';
import { useDispatch } from 'react-redux';
import coversationContext from '../../../context/conversationContext';
const MRLAccordionPar = ({ convo, folderID = null, moveChildren }) => {

  const mainRegionLogs = useContext(MRLContext);
  const mainRegion = useContext(MRContext);
  const conversation = useContext(coversationContext);

  const [nameIsEditing, setNameIsEditing] = useState(false);
  const [convoName, setConvoName] = useState(convo.name);
  const [prevConvoName, setPrevConvoName] = useState(convo.name)
  const [isDeleting, setIsDeleting] = useState(false);
  const [moveState, setMoveState] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const promptDelete = () => {
    setIsDeleting(true);
  };

  const cancelDelete = () => {
    setIsDeleting(false);
  };


  const handleCancelRenaming = () => {

    setNameIsEditing(false);
    setConvoName(prevConvoName)
  }

  const handleEditing = () => {
    setNameIsEditing(true);
  };

  const handleBlur = (convoID) => {
    MRConvoAPI.renameConvo({ convoID, name: convoName })
    setNameIsEditing(false);
    setPrevConvoName(convoName)
  };

  async function handleDelete(convoID) {
    await MRConvoAPI.deleteConvo(convoID);
    const newLogs = await MRLogsAPI.getLogsData();
    mainRegionLogs.setlogsData(newLogs);
  }
  const handleAddFolder = () => {
    console.log(`handleAddFolder`)
  }

  const resetResponse = () => {
    dispatch(resetResponseData())
    dispatch(resetLogsData())
    dispatch(setClickedLogsAccordian(true))
  }




  const handleCloneConvo = (convo) => {
    MRConvoAPI.cloneConvo({convoUUID : convo.uuid})                    
    .then((response) => {
        mainRegionLogs.updateLogsDataForCloneConversation(response.data)
        console.log("conversation cloned : ", response)
    })
    .catch((error) => {
        console.error(error)
    })
  }

   
    const showMRConversation = () => {
      if (!moveChildren) {

      resetResponse()
      mainRegion.showMRConversation()
    }
  }

  const handleShareConvoUrl = () => {
    return `${window.location.host}/conversation/${convo.uuid}`
  }

  const handleNavigate = () => {
    navigate(`/conversation/${convo.uuid}`)
  }

  const handleMoveConversations = (convo) => {
    setTimeout(() => {
      handleMoveConversation(convo)
    }, 500);
  }



  const handleMoveConversation = (convo) => {
    if (mainRegionLogs.prevFolder !== null) {
      const prevSelectedDiv = document.querySelector(`[data-folder-id="${mainRegionLogs.prevFolder}"]`);
      if (prevSelectedDiv) {
        prevSelectedDiv.classList.remove('border');
      }
    }
    mainRegionLogs.setPrevFolder(convo.id);
    mainRegionLogs.setPrevFolderObj(convo.parent_folder_id);
    mainRegionLogs.setFolderBorder(true)
    setMoveState(true)
    mainRegionLogs.setToBeMovedId(convo.uuid)
    mainRegionLogs.setToBeMovedObj(convo)
    mainRegionLogs.setToBeMovedType('conversation')
    mainRegionLogs.setMoveConversation(true)
    mainRegionLogs.setMoveSelected(true)
  }

  const calculateHeaderWrapperClassName = (convo) => {
    const isSelectedAndMoving = mainRegionLogs?.selectedState && moveState && mainRegionLogs?.moveSelected;
    const isCurrentConvoSelected = convo.id === mainRegionLogs.prevFolder;
    const shouldHaveBorder = (isSelectedAndMoving && isCurrentConvoSelected) || moveChildren;


    if (!mainRegionLogs.selectedState) {
      mainRegionLogs.setMoveSelected(false)
    }
    if (shouldHaveBorder) {
        return 'notAllowedCursor'
    } else if (mainRegionLogs?.moveSelected) {
        if (convo.id === mainRegionLogs?.prevFolderObj) {
          const prevSelectedFolder = document.querySelector(`[data-folder-id="${convo.id}"]`);
          if (prevSelectedFolder) {
            return 'notAllowedCursor'
          }

      }
      else {
        const convoDiv = document.querySelector(`.Conversation`)
        if (convoDiv) {
          return 'notAllowedCursor'
        }

      }
      return 'border copyCursor'
    }

  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 32 && nameIsEditing) {
      e.preventDefault();
      const { selectionStart, selectionEnd, value } = e.target;
      const newValue =
        value.substring(0, selectionStart) +
        ' ' +
        value.substring(selectionEnd, value.length);
        
        setConvoName(newValue);
      const newCursorPosition = selectionStart + 1;

      setTimeout(() => {
        e.target.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }
  };
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nameIsEditing]);
  

  const handleAccordionToggle = (convoID) => {
    if (!nameIsEditing) {
      mainRegionLogs.handleAccordionToggle(convoID);
    }
  };


  const calculateHeaderButtonClassName = (convo) => {
    const isSelectedAndMoving = mainRegionLogs?.selectedState && moveState && mainRegionLogs?.moveSelected;
    const isCurrentConvoSelected = convo.id === mainRegionLogs.prevFolder;
    const shouldHaveBorder = (isSelectedAndMoving && isCurrentConvoSelected) || moveChildren;


    if (!mainRegionLogs.selectedState) {
      mainRegionLogs.setMoveSelected(false)
    }
    if (shouldHaveBorder) {
        return 'notAllowedCursor'
    } else if (mainRegionLogs?.moveSelected) {
        if (convo.id === mainRegionLogs?.prevFolderObj) {
          const prevSelectedFolder = document.querySelector(`[data-folder-id="${convo.id}"]`);
          if (prevSelectedFolder) {
            return 'notAllowedCursor'
          }

      }
      else {
        const convoDiv = document.querySelector(`.Conversation`)
        if (convoDiv) {
          return 'notAllowedCursor'
        }

      }
      return 'copyCursor'
    }

  };

  return (
    <>
      <Accordion key={convo.id}>
        <AccordionItem>
          <div key={convo.id}
            className={`acc_header_wrapper Conversation ${calculateHeaderWrapperClassName(convo)}`}
            data-folder-id={convo.id} onClick={() => { mainRegion.setMRConvoID(convo.id) }}>
            <AccordionHeader className= {`titleWrap ${calculateHeaderButtonClassName(convo)}`} onClick={() => handleAccordionToggle(convo.id) }>
              <div className="acco_col">
                <div className={`AccoIcon ${mainRegionLogs.openItems.includes(convo.id) && `rotate-180`}`}>
                  {convo.has_children && <IconAccordion width="20" height="12" />}

                </div>
                <div className="Acco_title_box">
                  {
                    isDeleting ?
                      <span className="subTitle redConfirmationText">Please confirm you want to delete this folder and all its contents</span> :
                      <span className="subTitle">{convo.header}</span>
                  }
                  <span className="accordion-title">
                    <EditableText name={convoName} setName={setConvoName} searchedName={conversation.searched} prevConvo={convo.name} isEditing={nameIsEditing} handleEditing={handleEditing}>
                      {convo.name}
                    </EditableText>
                  </span>
                </div>
              </div>
            </AccordionHeader>
            {
              isDeleting ?
                <div className="MRLog_button_wrapper MRCprompt_wrap d-flex align-items-center">
                  <div className="AccoIcon customIcon" onClick={() => handleDelete(convo.id)}>
                    <IconsAreYouSureDelete />
                  </div>
                  <div className="AccoIcon customIcon" onClick={cancelDelete}>
                    <IconsAreYouSureCancel />
                  </div>
                </div> :
                <div className="MRLog_button_wrapper MRCprompt_wrap d-flex align-items-center">
                  {
                    nameIsEditing ? (
                      <div className="MRLog_button_wrapper MRCprompt_wrap d-flex align-items-center">
                        <div className={`AccoIcon customIcon ${convoName.trim() === '' || convoName === convo.name? 'disabledIcon' : ''}`} id="iconsConfirmConvo" onClick={(e) => handleBlur(convo.id, convoName)}>
                          
                            <div>
                              <IconConfirmConvo color={convoName.trim() === '' || convoName === convo.name ? '#C7C7C7' : '#5DCDF5'} id ="convo" />
                            </div>
                        </div>
                        <div className="AccoIcon customIcon" onClick={() => handleCancelRenaming(convo.id) }>
                          <IconCancelConfirmationConvo />
                        </div>
                      </div>
                    ) : 
                   
                    (
                      ((mainRegionLogs?.selectedState && moveState && convo.id === mainRegionLogs.prevFolder) || moveChildren) ?
                        <button className={`AccoIcon customIcon btn btn-primary ${calculateHeaderButtonClassName(convo)}`}>
                          <IconCheckMark />
                        </button>
                        :
                        <Link to={`/conversation/${convo.uuid}`} style={{ textDecoration: 'none' }}>
                          <div className={`AccoIcon customIcon ${calculateHeaderButtonClassName(convo)}`} onClick={showMRConversation}>
                            <IconArrowCircle />
                          </div>
                        </Link>
                    )
                      
                  }
                  {!nameIsEditing ? (
                    <MRAKabob 
                      rename={handleEditing} 
                      move={() => handleMoveConversations(convo)} 
                      copyText={convo.name} 
                      markPrivacy={convo.is_public} 
                      convo_uuid={convo.uuid} 
                      copyShareUrl={handleShareConvoUrl()}  
                      deleteConvo={promptDelete} 
                      addFolder={handleAddFolder} 
                      clone={() => handleCloneConvo(convo)} 
                    />
                  ) : null}
                </div>
            }
          </div>
          <AccordionBody>
              <div className="accordion-body" onClick={resetResponse}>
                {convo?.jobs?.map((job) => (
                  <div className="mb-20" key={job.id}>
                    <AccordionChild uuid={convo.uuid} job={job} onClick={resetResponse} moveChildren={moveChildren} moveState={moveState} />
                  </div>
                ))}
              </div>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default MRLAccordionPar;
