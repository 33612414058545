import React from "react";

function IconAtticus(props) {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M19.3119 21.9912C19.1536 21.9912 19.0129 21.934 18.8854 21.8372C18.7359 21.7229 18.6216 21.5821 18.5161 21.4237C18.3358 21.1554 18.2039 20.865 18.0852 20.5659C17.7334 19.6685 17.3641 18.7843 16.9596 17.9133C15.834 15.4806 14.4974 13.1624 12.9981 10.9408C11.5735 8.82931 10.0126 6.81896 8.3682 4.87459C7.5328 3.88481 6.69301 2.89942 5.80925 1.95363C5.51026 1.6325 5.20248 1.32017 4.8947 1.01224C4.78918 0.906664 4.68805 0.796689 4.6309 0.65592C4.54296 0.449166 4.58693 0.229214 4.74961 0.0884455C4.84194 0.00486395 4.94307 -0.0215302 5.06178 0.018061C5.20688 0.0664503 5.33439 0.136835 5.4531 0.229214C5.62018 0.352387 5.77847 0.488757 5.92796 0.633925C6.99199 1.68089 8.06042 2.72786 9.12445 3.77483C9.88071 4.51827 10.6458 5.2529 11.3888 6.00954C13.2267 7.87032 14.9459 9.83669 16.4935 11.9482C17.6103 13.4747 18.6392 15.0627 19.5361 16.73C19.9494 17.4998 20.2968 18.296 20.6617 19.0878C20.7848 19.3562 20.908 19.6201 21.0267 19.8885C21.119 20.0952 21.1586 20.3108 21.1234 20.5351C21.1058 20.6539 21.0618 20.7639 20.9783 20.8519C20.6793 21.173 20.3759 21.4897 20.011 21.7361C19.9011 21.8108 19.7912 21.8768 19.668 21.9252C19.5537 21.9692 19.435 22 19.3119 22V21.9912Z" fill="#013220" />
        <path d="M0.00527232 21.121C0.00527232 21.0022 0.0360501 20.901 0.0668279 20.8042C0.141574 20.5711 0.247098 20.3511 0.365812 20.14C1.55296 17.9933 2.73131 15.8377 3.92724 13.6954C5.01326 11.751 6.21799 9.88145 7.53264 8.08225C7.90198 7.57636 8.2889 7.07927 8.68461 6.59538C8.78134 6.4766 8.78134 6.4766 8.87368 6.59098C9.33095 7.16285 9.78382 7.73472 10.2367 8.3066C10.2499 8.31979 10.2587 8.33739 10.2719 8.35059C10.3202 8.39898 10.3026 8.43417 10.2631 8.47816C10.1619 8.58374 10.0652 8.68931 9.96849 8.79489C7.94595 10.9592 6.13445 13.2907 4.43728 15.7146C3.48756 17.0695 2.56862 18.4508 1.69365 19.8584C1.45623 20.2412 1.23199 20.6327 1.01215 21.0286C0.941797 21.1562 0.867051 21.2793 0.752733 21.3717C0.581257 21.5169 0.370209 21.5433 0.185542 21.4465C0.0492406 21.3761 -0.00791816 21.2617 0.000875496 21.1254L0.00527232 21.121Z" fill="#228B22" />
        <path d="M15.5479 0.149506C15.6094 0.149506 15.6798 0.158304 15.7457 0.189097C16.2206 0.417846 16.6778 0.668591 17.0516 1.04251C17.1043 1.0953 17.1483 1.14808 17.1923 1.20527C17.3989 1.47801 17.4165 1.76835 17.2582 2.06748C17.1659 2.24344 17.0384 2.39741 16.9021 2.54258C16.6866 2.76693 16.4448 2.96488 16.1942 3.14964C15.2445 3.85789 14.3299 4.60572 13.4462 5.38875C13.178 5.62629 12.9186 5.86824 12.6547 6.10579C12.5756 6.17617 12.5756 6.17617 12.5053 6.10579C11.9249 5.52512 11.3445 4.94445 10.7641 4.35937C10.6762 4.27139 10.6762 4.27139 10.7729 4.16582C11.7138 3.14524 12.7163 2.19505 13.7891 1.31525C14.1365 1.03371 14.4706 0.738975 14.8136 0.453038C14.9719 0.321068 15.1434 0.215491 15.3456 0.171501C15.4072 0.158304 15.4687 0.149506 15.5479 0.149506Z" fill="#228B22" />
        <path d="M11.9824 17.3283C12.919 17.3283 13.8599 17.3283 14.7964 17.3283C14.8536 17.3283 14.9151 17.3283 14.9723 17.3283C15.3636 17.3503 15.6538 17.6538 15.6538 18.0453C15.6538 18.4148 15.3636 18.7139 14.9811 18.7403C14.9371 18.7403 14.8887 18.7403 14.8448 18.7403C12.9453 18.7403 11.0459 18.7403 9.14648 18.7403C9.08932 18.7403 9.02777 18.7403 8.97061 18.7359C8.64084 18.7183 8.2935 18.4368 8.29789 18.0013C8.29789 17.6318 8.62765 17.3239 9.01897 17.3195C9.85437 17.3195 10.6898 17.3195 11.5208 17.3195C11.6747 17.3195 11.8285 17.3195 11.9824 17.3195V17.3283Z" fill="#228B22" />
        <path d="M11.6303 16.6294C11.0191 16.6294 10.4035 16.6294 9.79238 16.6294C9.70445 16.6294 9.62091 16.6294 9.53737 16.603C9.19881 16.5151 8.96578 16.1851 9.00535 15.8508C9.04932 15.4857 9.33072 15.2174 9.69565 15.213C10.0914 15.2086 10.4871 15.213 10.8872 15.213C11.7138 15.213 12.536 15.213 13.3626 15.213C13.4374 15.213 13.5165 15.213 13.5912 15.2218C13.7979 15.2437 13.9694 15.3317 14.0925 15.5077C14.2508 15.7276 14.3035 15.9652 14.176 16.2203C14.0441 16.4799 13.8375 16.6294 13.5429 16.6338C12.9053 16.6382 12.2722 16.6338 11.6347 16.6338L11.6303 16.6294Z" fill="#228B22" />
        <path d="M10.0782 0.611725C10.2409 0.611725 10.4036 0.611725 10.5663 0.611725C10.9752 0.620523 11.2698 0.915258 11.2742 1.31117C11.2742 1.70268 10.9796 2.01501 10.5795 2.02821C10.2365 2.03701 9.89355 2.03701 9.55499 2.02821C9.23842 2.01941 8.9834 1.81266 8.89986 1.51353C8.81632 1.22759 8.92185 0.919657 9.16367 0.743696C9.27799 0.660114 9.4011 0.616124 9.5418 0.611725C9.72207 0.611725 9.90234 0.611725 10.0826 0.611725H10.0782Z" fill="#228B22" />
      </svg>
    </>
  );
}

export default IconAtticus;
