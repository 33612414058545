import React, { useState } from 'react';
import IconHyperPromptsWhite from '../../icons/IconHyperPromptsWhite';
import GigaPrompt from '../../icons/IconGigaPrompt';
import IconHPaccordion from '../../icons/IconHPaccordion';
import IconGP from '../../icons/IconGP';
import IconAccordion from '../../icons/IconAccordion';
import MRAKabob from '../General/MRKabob';

const MRCReceipt = ({ receiptData, hpIconHover }) => {
    const receipt = receiptData;
    const showGP = receipt?.show_gp_icon ? receipt?.show_gp_icon : false;
    const prompt = receipt.prompt;
    const [moreText , setMoreText] = useState( prompt?.slice(0, 65))

    const [isReadMore, setIsReadMore] = useState(true);
    const [hoverHPIcon, setHoverHPIcon] = useState(false)

    const handleHoverhpIcon = () => {
        setHoverHPIcon(true)
    }

    const handleLeavehpIcon = () => {
        setHoverHPIcon(false)
    }

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
        setMoreText(!moreText)
    };
    const isSingleLine = prompt.length <= 65


    return (
        <>
            <div className="reciept_wrapper modelIconWrap">
                <div className="gp_prompt" >
                    <div onClick={toggleReadMore} className={`AccoIcon ${isReadMore && !isSingleLine ? '' : 'active'}`}
                     style={{ cursor: !isSingleLine  ? 'pointer' : 'default' }}   >
                        <IconAccordion width="20" height="12" disabled= {isSingleLine} />
                    </div>
                    <h4 className="text" onClick={toggleReadMore}>
                        {isReadMore ? prompt?.slice(0, 65) : prompt}
                        {moreText &&   prompt.length > 65   ? "..." : ''}
                    </h4>
                    <div className='mrKabob'>
                        <MRAKabob copyText={prompt}  />
                    </div>
                </div> 
                <hr />
                <div className="gp_response" dangerouslySetInnerHTML={{ __html: receipt?.gp_response }}>
                </div>

                {/* Icon right */}
                <div className="HPIcon_wrap">
                    <div className="modelIcon" onMouseEnter={handleHoverhpIcon} onMouseLeave={handleLeavehpIcon}>
                        <IconHyperPromptsWhite width={19} height={26} />
                    </div>
                 { hoverHPIcon &&   <div className='HPIcon-hover-text'>
                        {hpIconHover}
                    </div>}
                </div>
            
                {/* Remove comment to see the GP icon  */}
                {showGP &&
                    <div className="GPIcon">
                        <div className="modelIcon">
                            <IconGP width={22} fill="#495FAB" />
                        </div>
                    </div>
                }

            </div>
        </>
    );
};

export default MRCReceipt;