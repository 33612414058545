import React, { useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import mainRegionContext from "../General/MRContext";
import aiModels from './aiModels.json';
import coversationContext from '../../../context/conversationContext';
import IconArrowUp from '../../icons/IconArrowUp';
import IconArrowDown from '../../icons/IconArrowDown';


const AIDropdown = () => {
  const [data, setData] = useState(aiModels);
  const [isVisible, setisVisible] = useState(false)
  const [aiModelId, setaiModelId] = useState()
  const [aiModelValue, setaiModelValue] = useState()
  const [aiModelHeader, setaiModelHeader] = useState()
  const [aiModelImage, setaiModelImage] = useState()
  const [aiModelType, setaiModeltype] = useState()
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const conversation = useContext(coversationContext);

  const handleDropdown = (e) => {
    setaiModelId(e.id)
    setaiModelValue(e.name)
    setaiModelHeader(e.header)
    setaiModelImage(e.image_url)
    
    if(e.model_type === "agent"){
        setaiModeltype("AI Agent");
      }else if(e.model_type ){
        setaiModeltype("AI Model");
      }else{
        setaiModeltype("Select");
    }
}
  useEffect(()=>{
        handleDropdown(data);
  }, [data])
  /*Create the aiModels List*/
  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = Cookies.get('token');
    const response = axios.get(`${baseUrl}/api/v1/ai_models`)
      .then((response) => {
        console.log("response",response.data)
        setData(response.data?.data[0]);
        setaiModelId(response.data?.data[0].id)
        conversation.setaiModelId(response.data?.data[0].id)
        setaiModelValue(response.data?.data[0].name)
        setaiModelHeader(response.data?.data[0].header)
        setaiModelImage(response.data?.data[0].image_url)
        conversation.setaiModelImage(response.data?.data[0].image_url)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
  }, []);

  const handleHoverEnter = () => {
    setisVisible(true);
  };

  const handleHoverLeave = () => {
    setisVisible(false);
  };


  return (
    <>
        <div className="customDropBtn" style={{width : !aiModelImage && '106px' }} >
        <div className='ai-model-dropdown' style={{ visibility: isVisible ? 'visible' : 'hidden'}}>
                    <div className='header-hover-ai-icon'>{aiModelValue}</div>
                    <div className='ai-model-header'>{aiModelHeader}</div>
                </div>        
               
                <span className='dropTitle'     
                    onMouseEnter={handleHoverEnter}
                    onMouseLeave={handleHoverLeave} >

                  {aiModelImage
                  ?  <img className='ai-model-icon' src={aiModelImage}  />
                  :  <div style={{width: '40px'}}></div>}             
                </span>
        </div>
    </>
    )
  }

export default AIDropdown;