import React from 'react';

import { useNavigate } from 'react-router-dom';
import loginBG from '../../images/loginBG.jpg'
import HPLogo from '../../images/HP_logo.svg'
import SignupForm from './hp-signUpForm';

const ConfirmResetPassword = () => {

    const [formData, setFormData] = React.useState({ email: '', password: '' });
    const [signup, setSignup] = React.useState(false);
    const navigate = useNavigate()
    const handlesignup = () => {
        setSignup(!signup);

    };



    const handleButton = ( ) =>{
    } 


    return (
        <>
            <div className="auth_page_wrapper">
                {/* HP Header logo */}
                <a href="./" className="HP_header_logo">
                    <img src={HPLogo} alt="Atticus Logo" />
                    <span>Atticus</span>
                </a>
                <div className="photo_col">
                    <img src={loginBG} alt="" />
                    <h1 className="log_title">42X Prompting Accelerator</h1>
                </div>
                <div className="auth_col">
                    <div className="form_wrap">
                        {/* {signup
                            ? <h2 className="log_title">Log In <span className="log_sub">Welcome back 🎉 </span></h2>
                            :  */}
                            <h6 className="log_title_reset gradient-text">Reset Password </h6>
                            < span className="log_sub_reset_pass mb-3">Please check your email </span>
                            

                        {signup ? <SignupForm /> :
                            <>

                                <form className="d-flex flex-column logForm confirmPWForm" >
                                    <div className="inputBox">

                                        <input type={"email"} id="email" placeholder='Email' onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                    </div>
                                    <input className="log_btn btn_email mt-10" type="submit" value="Email Sent" onClick={handleButton}  />
                                </form>
                            </>
                        }

                        <div className="alt_auth_wrapper text-center">
                            <div className="divider"><span>or</span></div>

                            <p>Don't have an account?</p>

                            <button className="log_btn bg_white" onClick={handlesignup}>
                                Sign up

                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )



}



export default ConfirmResetPassword;