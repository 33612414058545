import React from 'react';
import IconEye from "../../icons/IconEye";
import IconEyeInvisible from "../../icons/IconEyeInvisible";

export default function InputField(props) {
    const handlePasswordToggle = () => {
        props.setShowPassword(!props.showPassword);
    };

    return (
        <div className={`inputBox custom ${props.error ? 'mb-1' : ''}`}>
            <div className={`gp-input-group`}>
                <label className={`gp-input-filled ${props.error ? 'email-error' : ''} ${props.value ? 'filled' : ''}`}>
                    <input
                        id={props.id}
                        type={props.type === 'password' && props.showPassword ? 'text' : props.type}
                        name={props.name}
                        value={props.value}
                        autoComplete="off"
                        onBlur={props.onBlur}
                        onChange={props.onChange}
                        onFocus={props.onFocus}
                        maxLength={props.maxLength}
                        required
                    />
                    <span className={`gp-input-label ${props.error ? 'email-error-label' : ''}`}>{props.label}</span>
                      {props.type === 'password' && (
                        <div className="password-toggle-icon">
                            {props.showPassword ? 
                                <IconEye onClick={handlePasswordToggle} />:
                                <IconEyeInvisible onClick={handlePasswordToggle}/>
                            }
                        </div>
                    )}
                </label>
            </div>
            {props.error && <span className="text-danger mb-2 mt-2 error-font">{props.error}</span>}
        </div>
    );
}
