
import * as React from "react"

function IconLogs(props) {
  return (
    <svg className="icon icon-newfolder"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.fill}
        d="M21.043 5.544h-7.596c-.308 0-.61-.126-.818-.342l-2.37-2.496a3.004 3.004 0 00-2.138-.86H2.957A2.96 2.96 0 000 4.803v14.396a2.96 2.96 0 002.957 2.957h18.1A2.96 2.96 0 0024 19.19V8.501a2.96 2.96 0 00-2.957-2.957zm1.148 13.65a1.15 1.15 0 01-1.139 1.154H2.957a1.15 1.15 0 01-1.148-1.149V4.803c0-.633.515-1.149 1.148-1.15H8.174c.302 0 .595.12.792.316l2.357 2.485a2.971 2.971 0 002.124.899h7.596A1.15 1.15 0 0122.19 8.5v10.693z"
      ></path>
    </svg>
  );
}

export default IconLogs
