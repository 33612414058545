import React, { useState } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import CheckUserType from "./FRUXModal";
import { PMAPI } from "../../helper/PMAPI";
import { useSelector } from "react-redux";

function FRUXButton() {
    const [modalShow, setModalShow] = useState(false);
    const [data, setData] = useState(null)
    const auth = useIsAuthenticated();
    const creditsUpdateState = useSelector(state => state.logs.creditsUpdateState)

    React.useEffect(() => {
        if (auth() && !creditsUpdateState) {
            PMAPI.getCreditsInfo()
            .then((response) => {
                setData(response.data)
            })  
            .catch((error) => {
                console.error(error)
            })

            setModalShow(true);
        }
    }, []);

    const  handleModalShowControl = () => {
        setModalShow(false);
    }

    return (
        <>
            { auth()  && data && data?.showed_frux === false && (
                <CheckUserType
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    modalshowcontrol={handleModalShowControl}
                />
            )}
        </>
    );
}

export default FRUXButton;
