import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

function FAQs() {
    const [faqs, setFaqs] = useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        fetch(`${baseUrl}/api/v1/faqs`)
            .then(response => response.json())
            .then(data => setFaqs(data.data));
    }, [baseUrl]);

    return (
        <>
            <div className='faq_col'>
                <div className="faq_wrap">
                    <div className="faq-content">
                        <h1 className="title"> Atticus FAQ <span>
                            <button className="btn btn-primary hs-cta-trigger-button hs-cta-trigger-button-157081222534 hp-contact-btn">Contact us</button>
                        </span>
                        </h1>
                    </div>
                    <Accordion>
                        {faqs.map((faq, index) => (
                            <Accordion.Item key={index} eventKey={index}>
                                <Accordion.Header>{faq.heading}</Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{ __html: faq.description }} />
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
        </>
    );
}

export default FAQs;
