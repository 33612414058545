const Icon = (props) => {
    return (
        <>
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2471 4.44532L13.2471 4.44532C13.249 4.46348 13.25 4.48171 13.25 4.5V15.75C13.25 16.5773 12.5773 17.25 11.75 17.25H2.25C1.42268 17.25 0.75 16.5773 0.75 15.75V2.25C0.75 1.42268 1.42268 0.75 2.25 0.75H9.5C9.51751 0.75 9.53724 0.750881 9.55855 0.753557C9.5594 0.753664 9.56026 0.753774 9.56111 0.753887L9.5285 1.00175C9.56625 1.006 9.90425 1.05075 10.134 1.2805L13.2471 4.44532ZM13.2471 4.44532L13.2467 4.44191C13.2416 4.39847 13.1909 3.98391 12.8966 3.68932L12.8965 3.68921L10.3108 1.10372L13.2471 4.44532ZM11.7929 4H10.5C10.2246 4 10 3.77543 10 3.5V2.20705L11.7929 4ZM11.75 16.25H2.25C1.97457 16.25 1.75 16.0254 1.75 15.75V2.25C1.75 1.97457 1.97457 1.75 2.25 1.75H9V3.5C9 4.32732 9.67268 5 10.5 5H12.25V15.75C12.25 16.0254 12.0254 16.25 11.75 16.25Z" fill="white" stroke="#060E1E" stroke-width="0.5" />
            </svg>
        </>
    );
};

export default Icon;