import React from "react";
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';


const HMMenuButtons = ({ active, link, Icon, children }) => {
  const handleClickBtn = (e) => {
    e.preventDefault();  
  };
  return (
    <Nav.Link href={link} onClick={handleClickBtn} active={active} className='hm-item d-flex align-items-center'>
      <Button className={`hm-iconbtn ${children === 'Atticus' ? 'atticus-btn' : ''}`}  variant="outline-secondary">
        {Icon}
      </Button>
      <div className={`btn-intro ${children === 'Atticus' ? 'atticus-text' : ''}`}>
        <span className={`${children === 'Atticus' ? 'atticus-span' : ''}`}>{children}</span>
      </div>
    </Nav.Link>
  );
};

export default HMMenuButtons