const IconLogout = (props) => {
    return (
        <>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1268_30610)">
<path d="M11.9602 21.966H2.99002C2.43984 21.966 1.99336 21.5195 1.99336 20.9694V3.02913C1.99336 2.47895 2.43989 2.03247 2.99002 2.03247H11.9602C12.5113 2.03247 12.9568 1.58697 12.9568 1.03581C12.9568 0.484656 12.5113 0.0390625 11.9602 0.0390625H2.99002C1.34152 0.0390625 0 1.38063 0 3.02913V20.9693C0 22.6178 1.34152 23.9593 2.99002 23.9593H11.9602C12.5113 23.9593 12.9568 23.5138 12.9568 22.9627C12.9568 22.4115 12.5113 21.966 11.9602 21.966Z" fill={props.fill}/>
<path d="M23.7032 11.2905L17.6434 5.31037C17.2527 4.92366 16.6208 4.92867 16.2341 5.32036C15.8474 5.71205 15.8514 6.34294 16.2441 6.72966L20.5746 11.0034H8.97029C8.41913 11.0034 7.97363 11.4489 7.97363 12C7.97363 12.5512 8.41913 12.9967 8.97029 12.9967H20.5746L16.2441 17.2705C15.8514 17.6572 15.8484 18.2881 16.2341 18.6798C16.4294 18.8771 16.6866 18.9768 16.9437 18.9768C17.1969 18.9768 17.45 18.8811 17.6434 18.6897L23.7032 12.7096C23.8926 12.5223 24.0002 12.2671 24.0002 12C24.0002 11.733 23.8936 11.4788 23.7032 11.2905Z" fill={props.fill}/>
</g>
<defs>
<clipPath id="clip0_1268_30610">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

        </>
    );
};

export default IconLogout;