const Icon = () => {
    return (
        <>
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="8.70415" height="1.93426" rx="0.967128" transform="matrix(0.674629 0.738157 -0.674629 0.738157 1.30469 7.06641)" fill="#495FAB" />
                <rect width="16.869" height="1.91495" rx="0.957474" transform="matrix(-0.589483 0.807781 -0.753075 -0.657935 16 1.26172)" fill="#495FAB" />
            </svg>

        </>
    );
};

export default Icon;