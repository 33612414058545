import React from "react";
import '../../styles/_errorPages.css'
import roboLogo from '../../images/401_page_img.png'
import questionMark from '../../images/questionMark.png'
import gradient from '../../images/Rectangle 1204.png'
const Error401Page = () => {
    return (
        <div className="position-relative overflow-auto vh-100 ">
            <img className="position-absolute top-0 left-0" src={gradient} alt="" />
            <div className="error_401">
                401
            </div>
            <div className="position-absolute main-div top-31 left-50 text-center">
                <div className="position-relative">
                    <img src={roboLogo} alt="" className="w-50 h-50" />
                    <img src={questionMark} className="q position-absolute top-20 left-0" />
                    <p className="text-p">You have confused our security bot.</p>
                </div>
            </div>
        </div>


    )
}

export default Error401Page;