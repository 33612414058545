import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom'
import LCol from './HPLoginLCol'
import axios from 'axios';
import InputField from '../main-region/MRInputBar/InputField.jsx'; 

const ResetPassword = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [formData, setFormData] = React.useState({ email: '', password: '' });
    const [emailSent, setEmailSent] = React.useState(false)
    const [signup, setSignup] = React.useState(false);
    const [isResetEnabled, setisResetEnabled] = useState(false);
    const [emailError, setEmailError] = React.useState(false)
    const [emailTouched, setEmailTouched] = React.useState(false)
    const [responseError, setResponseError] = React.useState(false)
    const [buttonError, setButtonError] = React.useState(false)
    const handlesignup = () => {
        setSignup(!signup);

    };


    useEffect(() => {
        if (!emailTouched) {
            setEmailError(false);
            return;
        }


    }, [formData, emailTouched]);

    useEffect(() => {
        if (emailTouched &&
            !emailError &&
            !buttonError &&
            !emailSent) {
            setisResetEnabled(true)
        }
        else {
            setisResetEnabled(false)
        }
      }, [emailTouched, emailError]);

      const renderEmailError = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
        if (formData.email === '' || !emailRegex.test(formData.email)) {
            setEmailError('Enter valid email');
        } else {
            setEmailError(false);
        }
    }

    const handleButtonReset = (e) => {
        // navigate('/confirmReset')
        console.log("triggered Click event")
        e.preventDefault()
        const resetEmail = async () => {
            try {
                const response = await axios.post(`${baseUrl}/api/v1/users/send_reset_password_email`, { email: formData.email });
                if (response.status === 200) {
                    console.log(response.data);
                    setEmailSent(true)
                }


            } catch (error) {
                if (error.response && error.response.status === 422) {
                    setResponseError(error.response.data.message)
                    setButtonError(true)
                }

                console.error("An error occurred during conversation creation:", error);
            }
        };
        resetEmail();
    }

    const handleEmailChange = (e) => {
        setFormData({ ...formData, email: e.target.value })
    }

    const handleFocus = () => {
        setEmailTouched(true);
        setButtonError(false)
        setEmailSent(false)
        setResponseError(false)
    };

    return (
        <>
            <div className="auth_page_wrapper">
                <LCol />
                <div className="auth_col">
                    <div className="form_scroll">
                    <div className="form_wrap">
                        <h6 className={`log_title_reset ${emailSent ? 'gradient-text' : ''} `}>Reset Password<span className="log_sub_reset_pass">Enter email to reset password </span></h6>
                        <form className="d-flex flex-column logForm resetPWForm" >
                            <InputField
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={formData.email}
                                onChange={handleEmailChange}
                                onFocus={handleFocus}
                                onBlur={renderEmailError}
                                error={emailError}
                            />
                            <input
                                className={`log_btn mt-10 ${emailSent || buttonError ? 'btn_email btn bg-color text-dark font-weight-bold' : ''}`}
                                type="submit"
                                value={buttonError ? 'Something went wrong!' : emailSent ? 'Email Sent!' : 'Reset Password'}
                                onClick={handleButtonReset}
                                aria-disabled={!isResetEnabled || emailSent || buttonError ? "true" : "false"}
                            />

                        </form>
                        <div className="alt_auth_wrapper text-center">
                            <div className="divider"><span>or</span></div>
                            <p>Already have an account?</p>
                            <Link to="/login" className="log_btn bg_white"> Log In</Link>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )



}



export default ResetPassword;