const Icon = (props) => {
    return (
        <>
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2471 4.44533L13.2471 4.44533L13.2467 4.44191C13.2416 4.39847 13.1909 3.98391 12.8966 3.68932L12.8965 3.68921L10.3108 1.10372C10.3108 1.10372 10.3108 1.10372 10.3108 1.10371C10.0205 0.813439 9.61333 0.759888 9.55855 0.753556C9.53724 0.750881 9.51751 0.75 9.5 0.75H2.25C1.42268 0.75 0.75 1.42268 0.75 2.25V15.75C0.75 16.5773 1.42268 17.25 2.25 17.25H11.75C12.5773 17.25 13.25 16.5773 13.25 15.75V4.5C13.25 4.48171 13.249 4.46347 13.2471 4.44533ZM11.7929 4H10.5C10.2246 4 10 3.77543 10 3.5V2.20705L11.7929 4ZM11.75 16.25H2.25C1.97457 16.25 1.75 16.0254 1.75 15.75V2.25C1.75 1.97457 1.97457 1.75 2.25 1.75H9V3.5C9 4.32732 9.67268 5 10.5 5H12.25V15.75C12.25 16.0254 12.0254 16.25 11.75 16.25Z" fill="#5DCDF5" stroke="#5DCDF5" stroke-width="0.5" />
            </svg>

        </>
    );
};

export default Icon;