import React, { useEffect, useState } from 'react';

import Axios from 'axios'; // Import Axios for making API requests
import Cookies from 'js-cookie';
import { useSignIn } from 'react-auth-kit';
import Spinner from 'react-bootstrap/Spinner';
import PasswordChecklist from "react-password-checklist";
import { useLocation, useNavigate } from 'react-router-dom';
import InputField from '../main-region/MRInputBar/InputField.jsx'; 

const SignupForm = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false)
    const [isCorrect, setIsCorrect] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordChecklistHeader, setPasswordChecklistHeader] = useState(true)
    const [isSignUpDisabled, setIsSignUpDisabled] = React.useState(true);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');
    const [formData, setFormData] = useState({
        name: '',
        email: emailParam ? emailParam : '',
        password: '',
        confirmPassword: null,
    });

    useEffect(() => {

        if (formData.name &&
            formData.email &&
            !emailError &&
            formData.password &&
            isChecked === true &&
            formData.password === formData.confirmPassword) {
            setIsSignUpDisabled(false)
        } else {
            setIsSignUpDisabled(true)
        }

    }, [formData, isChecked, emailError]);

    useEffect(() => {
        if (!emailTouched) {
            setEmailError(false);
            return;
        }

    }, [formData, emailTouched]);

    useEffect(() => {

        let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/;
        if (!passwordRegex.test(formData.password)) {
            setPasswordChecklistHeader('Password must contain:')
        }
        else {
            setPasswordChecklistHeader('Robot Approved:')
        }


    }, [formData.password]);

    const [error, setError] = useState('');

    const renderPasswordError = (e) => {
        let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/;
        if (!passwordRegex.test(formData.password)) {
            setPasswordError('Enter valid password')
        }
        else {
            setPasswordError(false);
            if (confirmPasswordError) {
                renderPasswordConfirmError();
            }
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const renderNameError = () => {
        if (formData.name === '') {
            setNameError('Enter valid name')
        }
        else if (formData.name?.length > 70) {
            setNameError('Name cannot be greater than 70')
        }
        else {
            setNameError(false)
        }
    }

    const handleEmailChange = (e) => {
        setFormData({ ...formData, email: e.target.value });
    };

    const handleFocus = () => {
        setEmailTouched(true);
    };

    const renderPasswordConfirmError = () => {
        if (formData.password === formData.confirmPassword) {
            setConfirmPasswordError(false);
        }
        else {
            setConfirmPasswordError('passwords do not match');
        }
    }
    
    const renderEmailError = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
        if (!emailRegex.test(formData.email)) {
            setEmailError('Enter valid email');
        } else {
            setEmailError(false);
        }
    }

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };

    const handleCheck = () => {
        setIsChecked(!isChecked);
    };

    const handleConfirmPasswordToggle = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const onSubmit = async (e) => {
        if (isSignUpDisabled) {
            e.preventDefault();
            return;
        }
        e.preventDefault();
        setLoading(true);
        try {
            // Send a POST request to your backend API endpoint
            const response = await Axios.post(`${baseUrl}/api/v1/users/register`, { user: formData });
            if (response.status === 201) {
                // Signup was successful, you can redirect or show a success message
                const { jwt, user, credits_data, message } = response.data;
                Cookies.set('token', jwt)
                const expiresInInMinute = 24 * 60;
                signIn({
                    token: jwt,
                    expiresIn: expiresInInMinute,
                    tokenType: 'Bearer',
                    authState: { user: user, credits_data: credits_data }
                });
                navigate('/conversation');
                navigate('/login');
                setLoading(false);
            } else {
                // Handle other status codes or error responses from the API
                setError('Signup failed. Please try again.');
                setLoading(false);
            }
        } catch (error) {
            // Handle network errors or other exceptions
            setError('An error occurred. Please try again later.');
            setLoading(false);
            if (error?.response?.data?.error === 'Email already registered' || error?.response?.data?.error === 'Invalid email format') {
                setEmailError(error?.response?.data?.error)
            }
        }
    };

    return (
        <>
            <form noValidate className="d-flex flex-column logForm signupForm" onSubmit={onSubmit}>

                <InputField
                    id="name"
                    name="name"
                    label="Name"
                    type="name"
                    value={formData.name} 
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    onBlur={renderNameError}
                    error={nameError}
                />

                 <InputField
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleEmailChange}
                    onFocus={handleFocus}
                    onBlur={renderEmailError}
                    error={emailError}
                />
                <InputField
                    id="password"
                    name="password"
                    label="Password"
                    type= "password"
                    value={formData.password} 
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    onBlur={renderPasswordError}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    error={passwordError}
                />
                <InputField
                    id="confirm-password"
                    name="confirm-password"
                    label="Confirm Password"
                    type="password"
                    value={formData.confirmPassword} 
                    onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                    onBlur={renderPasswordConfirmError}
                    showPassword={showConfirmPassword}
                    setShowPassword={setShowConfirmPassword}
                    error={confirmPasswordError}
                />

                <div className="passwordChecklist">
                    <span className='passConfirmTitle'>
                        {passwordChecklistHeader}
                    </span>
                    <PasswordChecklist
                        rules={["capital", "lowercase", "minLength", "number", "maxLength"]}
                        minLength={8}
                        maxLength={100}
                        value={formData.password}
                        onChange={(isValid) => { }}
                        hideIcon={true}
                        messages={{
                            capital: "uppercase letter",
                            lowercase: "lowercase letter",
                            minLength: ">7 Characters",
                            number: "number",
                            maxLength: " "
                        }}
                    />
                </div>
                <div className="inputBox bg_transtarent">
                    <span className="tac_Checkbox">
                        <input type="checkbox" name="input_tac" id="input_tac" onClick={handleCheck} />
                        <label htmlFor="input_tac" className="input_tac_text">
                            I agree,  signing up you agree to our <a href="https://staging-api-hp.42robots.ai/miscellaneous_pages/terms_and_conditions">Terms and conditions</a>
                        </label>
                    </span>
                </div>
                {loading
                    ? <span className="log_btn mt-10" disabled>
                        <Spinner animation="border" role="status" />
                    </span>
                    :<input
                        className="log_btn"
                        type="submit"
                        value="Sign Up"
                        aria-disabled={isSignUpDisabled ? "true" : "false"}
                    />

                }
            </form>
        </>
    );
};

export default SignupForm;
