import React, { useContext, useEffect, useState, useRef } from "react";
import MRLContext from './MRLContext';
import MRLAccordionFolder from './MRLAccordionFolder';
import SkeletonLoading from "../MRConversation/MRCLoading";
import MainRegionContext from "../General/MRContext";
import MRLAccordionPar from "./MRLAccordionPar";
import AccordionChild from './MRLAccordionChild'


const MRLogs = () => {
  const mrState = useContext(MainRegionContext);
  const logsContext = useContext(MRLContext);
  const [selected, setSelected] = useState(false);
  const logsData = logsContext.logsData;
  const [data, setData] = useState(logsContext.logsDataToggle)
  const container = document.getElementById("logsScroll");
  const itemRef = useRef(null);
  const noResultsFound = logsData && logsData?.data?.every(item => item?.data?.length === 0);

  useEffect(() => {
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = () => {
    const distanceFromBottom = container.scrollHeight - container.clientHeight - container.scrollTop;

    if (distanceFromBottom <= 200) {
      logsContext.setScollDown(true)
    } else {
      logsContext.setScollDown(false)
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (itemRef.current && !itemRef.current.contains(event.target)) {
        console.log("logsContext.selectedState in ref", logsContext.selectedState)
        setSelected(false);

        logsContext.setSelectedState(selected)
        if (!selected) {
          logsContext.resetMove()
          container.classList.remove('notAllowedCursor');
        }
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selected]);

  const handleMouseLeave = () => {
    if (logsContext.selectedState && logsContext.moveFolder || logsContext.moveConversation) {
      container.classList.add('notAllowedCursor');
    } else {
      container.classList.remove('notAllowedCursor');
    }
  };

    return (
      <>

        {
          (noResultsFound) ?
              <div className="no-result-div" style={{ color: "#9D9D9D" , padding: '4vh 25vw'}}>
                No results were found
              </div>
           :
            logsData?.data?.map((item, index) => (
              item.data.length > 0 && (
                <div ref={itemRef}
                  onClick={() => setSelected(true)} className="accordion_wrapper" key={index}>
                  <div className="accordion_season_wrapper">
                    <h6 className="season_title">{item.header}</h6>
                    {item.data.map(item => (
                      item.type === 'PARENT_CONVERSATION' ? (
                        <div className="mb-20" key={item.id}>
                          <MRLAccordionPar convo={item} folderID={item.id} />
                        </div>
                      ) : item.type === 'FOLDER' ? (
                        <div onMouseLeave={handleMouseLeave}>
                          <MRLAccordionFolder key={item.id} folder={item} />
                        </div>
                      ) :
                        <div className="mb-20" key={item.id}>
                          <AccordionChild uuid={item.uuid} job={item} />
                        </div>
                    ))}
                  </div>
                </div>
              )
            )
            )}
      </>
    );
};

export default MRLogs;