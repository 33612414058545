function MRSCards(props) {

  return (
    <>
      <div className="card flex-column border-0 rounded-3 my-2 justify-content-between">
          <div className="card-body p-3">
              {props.children}
          </div>
      </div>
    </>
  );
}

export default MRSCards;