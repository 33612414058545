// src/apis/PMMenuAPI.js
import Cookies from 'js-cookie';
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const PMAPI = {
  getCreditsInfo: async function (cancel = false) {
    try {
      const response = await api.request({
        method: "GET",
        url: `/api/v1/credits_information`,
        withCredentials: false,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Token has expired");
        window.location.href = "/login";
      }
      throw error;
    }
  },

  getProfileLinks: async function (cancel = false) {
    try {
      const response = await api.request({
        method: "GET",
        url: `/api/v1/profile_links`,
        withCredentials: false,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Token has expired");
        window.location.href = "/login";
      }
      throw error;
    }
  },

  getFruxImages: async function (cancel = false) {
    try {
      const response = await api.request({
        method: "GET",
        url: `/api/v1/frux_images`,
        withCredentials: false,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Token has expired");
        window.location.href = "/login";
      }
      throw error;
    }
  },

  updateFruxStatus: async function (cancel = false, userProfile) {
    try {
      const response = await api.request({
        method: "POST",
        url: `/api/v1/update_frux_status`,
        withCredentials: false,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
        params: {
          profile_type: userProfile
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Token has expired");
        window.location.href = "/login";
      }
      throw error;
    }
  },
};

// defining the cancel API object for PMAPI
const cancelApiObject = defineCancelApiObject(PMAPI);