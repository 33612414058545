import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import coversationContext from '../../context/conversationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt  } from '@fortawesome/free-solid-svg-icons';
import IconLogout from '../icons/IconLogout';

const HomePage = () => {
    const signOut = useSignOut();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const conversation = useContext(coversationContext);

    const handleLogout = async () => {
        try {
            const authToken = Cookies.get('token');
            // Set the Authorization header with the token
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`, // Get the authToken from cookies
                }
            };

            // Send a DELETE request to your backend API to log the user out
            await axios.delete(`${baseUrl}/api/v1/users/logout`, config); // Replace with your API endpoint
            // Call the signOut function to clear the client-side authentication state
            signOut();
            Cookies.remove('token');
            conversation.resetAiModelValue()
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <>
<Button variant="light PM_logOut_btn" onClick={handleLogout}>
  <span style={{ marginRight: '1.5rem' }}>Log Out</span>
  <IconLogout fill="#006400" width="20" height="20" />
</Button>

        </>
    );
}

export default HomePage;
