import React from "react";
import MRGPSInput from "./MRGPSInput";

function GPSettings({ name, nameSubTitle, nameSubSubTitle, icon, active, disabled, setActive,
    range, rangeInput, rangeValue, setRangeValue }) {
    return (
        <>
            {/* Atticus Steps */}
            <div className={`HP-step ${active ? 'active' : ''}`}>
                <span className="hp-icon">
                    <label htmlFor={name}>
                        {icon}
                    </label>
                    <div className="vertical_line"></div>
                </span>
                <div className="hp-content">
                    <div className="flex-box">
                        <div className="checkWrap">
                            <div className="checkBox">
                                <input
                                    type="checkbox"
                                    name={name}
                                    id={name}
                                    disabled={disabled}
                                    onChange={() => setActive(!active)}
                                    checked={active}
                                />
                                <div className="onOff">
                                    <label htmlFor="">On</label>
                                    <label htmlFor="">Off</label>
                                </div>
                            </div>
                        </div>
                        <span className="hp-title">
                            <label htmlFor={name}>{name}<span className="subTitle">{nameSubTitle}</span></label>
                            <span className="subsubTitle">{nameSubSubTitle}</span>
                            {range &&
                                <MRGPSInput value={rangeValue} setValue={setRangeValue}>
                                    {rangeInput}
                                </MRGPSInput>
                            }
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GPSettings;
