// src/apis/MRFolderAPI.js

import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"
import Cookies from 'js-cookie';

//SET Credentials when live
const credentials = false

export const MRFolderAPI = {
  
  listFolders: async function (cancel = false,selectedItemName, selectedTab=false, selectedAiModels=false, filteredResponseType=false, filter, query ) {
    
    var sort = 'date_asc'
    if (selectedItemName === 'Recent'){
      sort = 'date_asc'
    }
    else if(selectedItemName === 'Oldest' ){
      sort = 'date_desc'
    }
    else if(selectedItemName === 'A-Z'){
      sort = 'alphabet_asc'
    }
    else if(selectedItemName === 'Z-A'){
      sort = 'alphabet_desc'
    }

    const response = await api.request({
      method: "GET",
      url: `/api/v1/folders`,
      withCredentials: credentials,
      signal: cancel ? cancelApiObject[this.listFolders.name].handleRequestCancellation().signal : undefined,
      headers: {
        Authorization: Cookies.get('token'),
      },
      params: {
        type: selectedTab.toLowerCase(),
        query: query,
        filter: filter,
        ai_model: selectedAiModels,
        response_type: filteredResponseType,
        sort_option: sort
      },
    });

    console.log("response.data",response.data)
  
    // const folders = response.data.map(folder => ({
    //   ...folder,
    //   isNewFolder: false,  
    //   isBeingAdded: false, 
    //   isEditing: false     
    // }));
  
    return response.data;
  },
  fetchChildFolders: async function (cancel = false, folderId) {
    const response = await api.request({
      method: "GET",
      url: `/api/v1/folders/${folderId}/fetch_child`,
      withCredentials: credentials,
      signal: cancel ? cancelApiObject[this.listFolders.name].handleRequestCancellation().signal : undefined,
      headers: {
        Authorization: Cookies.get('token'),
      }
    });

    console.log("response.data",response.data)
  
    // const folders = response.data.map(folder => ({
    //   ...folder,
    //   isNewFolder: false,  
    //   isBeingAdded: false, 
    //   isEditing: false     
    // }));
  
    return response.data;
  },
  
  createFolder: async function (folderData, cancel = false, parentFolderId = null) {
    try {
      
        if (parentFolderId !== null) {
            folderData = { ...folderData, folder_id: parentFolderId };
        }
        if (folderData.name===''){
          throw new Error('Empty Name')
        }
        
        const response = await api.request({
            method: "POST",
            url: `/api/v1/folders`,
            withCredentials: credentials,
            data: folderData,
            signal: cancel ? cancelApiObject[this.createFolder.name].handleRequestCancellation().signal : undefined,
            headers: {
                Authorization: Cookies.get('token'),
            }
        });
        if (response.data.error){
          throw new Error('Invalid Name')
        }

        return response;
    } catch (error) {
        console.error('Error in createFolder:', error);
        throw error;
    }


  },
  move:  async function (cancel = false,destinationFolder, folderToBeMoved, type) {
    console.log("folderToBeMoved",folderToBeMoved)
    const response = await api.request({
      method: "POST",
      url: `/api/v1/move/${destinationFolder}`,
      withCredentials: credentials,
      signal: cancel ? cancelApiObject[this.listFolders.name].handleRequestCancellation().signal : undefined,
      headers: {
        Authorization: Cookies.get('token'),
      },
      params: {
        id: folderToBeMoved,
        type: type
      },

    });

    return response
  },

  renameFolder: async function ({ folderID, newName }, cancel = false) {
    try {
      if (newName===''){
        throw new Error('Empty Name')
      }
      
      const response = await api.request({
        method: "POST",
        url: `/api/v1/folders/${folderID}/rename`,
        withCredentials: credentials,
        data: {
          name: newName
        },
        signal: cancel ? cancelApiObject[this.renameFolder.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        }
      });
      if (response.data.error){
        throw new Error('Invalid Name')
      }
      

      return response;
    } catch (error) {
      console.error('Error in renameFolder:', error.message);
      throw new Error(error.message);
    }
  },
  
    deleteFolder: async function (folderID, cancel = false) {
      await api.request({
        method: "DELETE",
        url: `/api/v1/folders/${folderID}`,
        withCredentials: credentials,
        signal: cancel ? cancelApiObject[this.deleteFolder.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        }
      })
    },
}

// defining the cancel API object for MRFolderAPI
const cancelApiObject = defineCancelApiObject(MRFolderAPI)
