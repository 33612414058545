const IconAccordion = (props) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg"
                width={props.width}
                height={props.height}
                viewBox="0 0 20 12"
                fill="#C7C7C7">
                <rect x="1.6582" width="14.0732" height="2.34553" rx="1.17276" transform="rotate(45 1.6582 0)" fill="" />
                <rect x="19.9023" y="1.66016" width="14.0732" height="2.34553" rx="1.17276" transform="rotate(135 19.9023 1.66016)" fill="" />
            </svg>
        </>
    );
};

export default IconAccordion;