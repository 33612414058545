const Icon = (props) => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M7.99761 16C6.94692 16.0019 5.90624 15.7962 4.93533 15.3947C3.96441 14.9932 3.08244 14.4038 2.34004 13.6603C-0.780012 10.5403 -0.780012 5.46413 2.34004 2.34417C3.08131 1.59874 3.96311 1.00773 4.93435 0.605363C5.90556 0.202988 6.94696 -0.00276021 7.99826 2.79624e-05C10.1357 2.79624e-05 12.1451 0.832191 13.6558 2.34417C15.1672 3.85551 16 5.86485 16 8.00224C16 10.1396 15.1678 12.149 13.6558 13.6603C12.9133 14.4038 12.0312 14.9932 11.0602 15.3947C10.0891 15.7962 9.04836 16.0019 7.99761 16ZM7.99826 1.28028C7.11512 1.27777 6.24029 1.45052 5.42441 1.78851C4.60854 2.1265 3.86782 2.62302 3.2452 3.24929C1.9758 4.51869 1.27676 6.20668 1.27676 8.00224C1.27676 9.7978 1.9758 11.4852 3.2452 12.7552C5.86593 15.3759 10.1306 15.3765 12.7507 12.7552C14.0201 11.4858 14.7197 9.7978 14.7197 8.00224C14.7197 6.20668 14.0207 4.51931 12.7507 3.24929C12.128 2.6232 11.3874 2.12681 10.5717 1.78883C9.7559 1.45085 8.88125 1.278 7.99826 1.28028Z" fill="#060E1E"/>
<path d="M5.28203 11.3581C5.15531 11.3584 5.03134 11.321 4.9259 11.2507C4.82046 11.1804 4.73827 11.0804 4.68979 10.9633C4.64128 10.8462 4.6287 10.7174 4.65353 10.5931C4.67841 10.4689 4.73965 10.3548 4.82946 10.2654L10.261 4.83393C10.3205 4.77451 10.391 4.72737 10.4687 4.69518C10.5463 4.66302 10.6296 4.64648 10.7136 4.64648C10.7977 4.64648 10.8809 4.66302 10.9585 4.69518C11.0362 4.72737 11.1068 4.77451 11.1662 4.83393C11.2256 4.89336 11.2728 4.96392 11.305 5.04158C11.3371 5.11924 11.3537 5.20245 11.3537 5.2865C11.3537 5.37054 11.3371 5.45379 11.305 5.53145C11.2728 5.60907 11.2256 5.67963 11.1662 5.73906L5.7346 11.1706C5.67532 11.2302 5.6048 11.2775 5.5271 11.3097C5.44943 11.3419 5.36611 11.3583 5.28203 11.3581Z" fill="#060E1E"/>
<path d="M10.7137 11.3581C10.6297 11.3582 10.5464 11.3417 10.4688 11.3095C10.391 11.2773 10.3205 11.2301 10.2612 11.1706L4.82954 5.73906C4.77012 5.67963 4.72298 5.60907 4.69082 5.53145C4.65866 5.45379 4.64209 5.37054 4.64209 5.2865C4.64209 5.20245 4.65866 5.11924 4.69082 5.04158C4.72298 4.96392 4.77012 4.89336 4.82954 4.83393C4.88901 4.77451 4.95956 4.72737 5.03719 4.69518C5.11486 4.66302 5.19811 4.64648 5.28215 4.64648C5.3662 4.64648 5.44941 4.66302 5.52708 4.69518C5.60474 4.72737 5.6753 4.77451 5.73472 4.83393L11.1663 10.2654C11.2561 10.3548 11.3174 10.4689 11.3422 10.5931C11.3671 10.7174 11.3545 10.8462 11.306 10.9633C11.2575 11.0804 11.1753 11.1804 11.0699 11.2507C10.9644 11.321 10.8405 11.3584 10.7137 11.3581Z" fill="#060E1E"/>
</svg>

        </>
    );
};

export default Icon;