const Icon = () => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.33337 13.3717C9.96193 13.3333 10.7368 13.3333 11.7334 13.3333H20.2667C21.2632 13.3333 22.0382 13.3333 22.6667 13.3717M9.33337 13.3717C8.54895 13.4196 7.99243 13.5273 7.51741 13.7693C6.76476 14.1528 6.15284 14.7647 5.76935 15.5173C5.33337 16.3731 5.33337 17.4931 5.33337 19.7333V21.6C5.33337 23.8403 5.33337 24.9603 5.76935 25.816C6.15284 26.5687 6.76476 27.1805 7.51741 27.564C8.37305 28 9.49316 28 11.7334 28H20.2667C22.507 28 23.627 28 24.4827 27.564C25.2354 27.1805 25.8472 26.5687 26.2307 25.816C26.6667 24.9603 26.6667 23.8403 26.6667 21.6V19.7333C26.6667 17.4931 26.6667 16.3731 26.2307 15.5173C25.8472 14.7647 25.2354 14.1528 24.4827 13.7693C24.0076 13.5273 23.4511 13.4196 22.6667 13.3717M9.33337 13.3717V10.6667C9.33337 6.98477 12.3181 4 16 4C19.6819 4 22.6667 6.98477 22.6667 10.6667V13.3717" stroke="black" stroke-width="2" />
            </svg>
        </>
    );
};
export default Icon;
