function MRSHeader(props) {
  const dynamicStyles = props.current
  ? { borderLeft: '5px solid', color: '#5DCDF5', padding: '0 5px' }
  : {};

  return (
    <>
    <div style= {dynamicStyles}>
      <div className="border-0 rounded-3 " dangerouslySetInnerHTML={{ __html: props?.header}}></div>
      {props.subhead &&
      <div className="text-secondary">
        Current Plan
      </div>
      }
    </div>
    </>
  );
}

export default MRSHeader;