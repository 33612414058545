import React, { useState, useContext, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';
import axios from 'axios';
import MainRegionContext from '../General/MRContext';
import coversationContext from '../../../context/conversationContext';
// import coversationResponseContext from '../../../context/conversationResponseContext';
import IconFeedRobot from '../../icons/IconFeedRobot';
import { createConsumer } from "@rails/actioncable";
import subscriptionContext from '../../../context/subscriptionContext';
import ProfileMenuContext from '../../profile-menu/PMContext';
import pmContext from '../../profile-menu/PMContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset, update, updateResponse, updateReceipt, updateConvoId, setConversationName, setConversationSetId, setScrollBottom } from '../../../redux/slices/conversationSlice';
import IconFlashWhiteBG from '../../icons/IconFlashWhiteBG';
import IconArrowLeft from '../../icons/LogsIcons/IconArrowLeft';
import IconArrowUp from '../../icons/LogsIcons/IconArrowUp';
import IconBusy from '../../icons/LogsIcons/IconBusy';
import { setExpandResponse, setResponseLogs } from '../../../redux/slices/logsSlice';
import MRITooltip from './MRITooltip';
import { MRConvoAPI } from '../../../helper/MRConversationAPI';

export default function MRIButton({ feedRobot }) {
  const dispatch = useDispatch();
  const status = useSelector(state => state.conversation.status);
  const [forms, setForms] = useState(new FormData());
  const [isApproved, setIsApproved] = useState(false);

 
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const mrState = useContext(MainRegionContext);
  const context = useContext(coversationContext);
  const subsContext = useContext(subscriptionContext);
  const authToken = Cookies.get('token');
  const cable = createConsumer(`${baseUrl}/cable?token=${authToken}`);
  const changeSubscription = `/subscriptions`;
  const { id } = useParams();
  const navigate = useNavigate();
  let feedBtnWrapClasses = ['feedBtnWrap'];   

  const [body, setBody] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [feedRobotBtn, setFeedRobotBtn] = useState(false);
  const [atticusApi, setAtticusApi] = useState(false)
  const pmState = useContext(pmContext);

  useEffect(() => {
    if (pmState.userCredits && pmState.userCredits.data) {
      setIsApproved(pmState.userCredits.data.is_approved);
    }
  }, [pmState.userCredits]);

  useEffect(() => {
    if (mrState.MRConvoID === 0) {
      if (subsContext?.subData?.channelSub) {
        // Unsubscribe from the channel when MRConvoID is 0
        subsContext?.subData?.channelSub?.unsubscribe();
        console.log('Channel unsubscribed', subsContext?.subData?.channelSub?.identifier);
        subsContext.update({ channelSub: null });
      }
      dispatch(reset());
      pmState.setupdateUserCredit(true);
    }
  }, [mrState.MRConvoID]);

  useEffect(() => {
    if (Object.keys(body).length > 0) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      const updatedBody = { ...body, folder_id: mrState.MRfolderID };
      // Your API call with updatedBody
    }
  }, [body, mrState.MRfolderID]);

  useEffect(() => {
    MRConvoAPI.can_use_atticus()
    .then((response) => {
      mrState.setCanUseAtticus(response.data)
    })
    .catch((error) => {
      console.error(error)
    })
  }, [atticusApi])

  useEffect(() => {
    if (mrState.generateReport){
      MRConvoAPI.generate_report(context.variationActive, context.multiplierValue,
      context.qualityBoosterActive, context.boosterValue,
      context.aiModelId, context.multiplieriationActive, mrState.MRConvoID)
      .then((response) => {
        console.log("response report:",response.data)
        subscribeConversationChannel(response.data.id, response.data.conversation_set_id);
        setAtticusApi(!atticusApi)
        mrState.setMRConvoID(response.data.id);
        mrState.setFShowExistingConvo(true);
        dispatch(updateConvoId(response.data.id));
        dispatch(updateReceipt(response.data.receipt_data));
        dispatch(setConversationName(response.data.name));
        dispatch(setConversationSetId(response.data.conversation_set_id));
        dispatch(updateResponse(response.data));
        dispatch(reset());
        dispatch(setScrollBottom(true));
        dispatch(update({ "polling": true, "loops_completed": -1 }));
        
      })
      .catch((error) => {
        console.error(error)
      })
      mrState.setGenerateReport(false)
      if (mrState.MRConvoID || id) {
        console.log("null")
      } else {
        mrState.setMRShow([
          {
            "MRTextHeader": true,
            "MRGigaPrompt": false,
            "MRInputBar": true,
            "MRConversation": true,
            "MRLogs": false
          }
        ]);
      }
  }
  },[mrState.generateReport])

  useEffect(() => {
    if (pmState.buttonText === 'Feed Robot' || mrState.fileUpload?.length > 0) {
      setFeedRobotBtn(true);
    } else {
      setFeedRobotBtn(false);
    }
  }, [pmState.buttonText, mrState.fileUpload]);

  useEffect(() => {
    if (!pmState.credits) {
      pmState.setButtonText("Add Credits");
    } else if (!context.prompt && pmState.credits) {
      pmState.setButtonText("Enter Text");
    } else if (!context.aiModelId && pmState.credits) {
      pmState.setButtonText("Choose AI Model");
    } else if (context.aiModelId && context.prompt && pmState.credits) {
      pmState.setButtonText("Feed Robot");
    } 
  }, [context, pmState.credits]);

  const handleHover = () => {
    if (mrState.fileUpload.length > 0) {
      pmState.setButtonText("Submit");
    } else if (pmState.credits && context.prompt) {
      pmState.setButtonText("Feed Robot");
    }

    if (feedRobotBtn || !pmState.credits) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleButton = async () => {
    context.setPrompt("");

    if ((!context.prompt && mrState?.fileUpload && mrState?.fileUpload?.length === 0 ) || !context.aiModelId || (status[status?.length - 1]?.polling === true && status[status?.length - 1]?.loops_completed !== status[status.length - 1]?.total_loops)) {
      return;
    }

    if (!pmState.credits) {
      window.location.href = changeSubscription;
      return;
    }

    const formData = new FormData();
    if (mrState.fileUpload && mrState.fileUpload.length > 0) {
      mrState.fileUpload.forEach(fileObj => {
        formData.append('pdf_files[]', fileObj.file);
      });
    }
    formData.append('variation_enabled', context.variationActive);
    formData.append('prompt_multiplier', context.multiplierValue);
    formData.append('tot_enabled', context.qualityBoosterActive);
    formData.append('tot_loops', context.boosterValue);
    formData.append('prompt', context.prompt);
    formData.append('ai_model_id', context.aiModelId);
    formData.append('has_prompt_multiplier', context.multiplieriationActive);
    if (mrState.MRConvoID || id) {
      formData.append('parent_conversation_id', mrState.MRConvoID);
    }
    mrState.setFileUpload([]);
    setForms(formData);

    try {
      const response = await axios.post(`${baseUrl}/api/v1/conversation`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        dispatch(setExpandResponse(true));
        console.log("response new conversation", response);
        mrState.setFileState('initial')
        mrState.setFiles(response.data.data.file_names)
        subscribeConversationChannel(response.data.data.id, response.data.data.conversation_set_id);
        mrState.setMRConvoID(response.data.data.id);
        mrState.setFShowExistingConvo(true);
        dispatch(updateConvoId(response.data.data.id));
        dispatch(updateReceipt(response.data.data.receipt_data));
        dispatch(setConversationName(response.data.data.name));
        dispatch(setConversationSetId(response.data.data.conversation_set_id));
        dispatch(updateResponse(response.data.data));
        dispatch(reset());
        dispatch(setScrollBottom(true));
        dispatch(update({ "polling": true, "loops_completed": -1 }));
      }
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }

    if (mrState.MRConvoID || id) {
      setBody(formData);
    } else {
      setBody(formData);
      mrState.setMRShow([
        {
          "MRTextHeader": true,
          "MRGigaPrompt": false,
          "MRInputBar": true,
          "MRConversation": true,
          "MRLogs": false
        }
      ]);
    }
  };

  const unsubscribeChannel = () => {
    subsContext?.subData?.channelSub?.unsubscribe();
    subsContext.update({ channelSub: null });
    cable.subscriptions.subscriptions.map((channel) => { channel.unsubscribe(); });
  };

  const ConversationChannel = {
    received(data) {
      console.log("Received Conversation", data);
      mrState.setFileState('loading')
      const pathArray = window.location.pathname.split('/');
      const idIndex = pathArray.indexOf('conversation') + 1;
      const id = idIndex < pathArray.length ? pathArray[idIndex] : null;
      if (data.conversations?.convo_deleted){
        navigate('/conversation')
        unsubscribeChannel()
      }
      if ((mrState.MRConvoID === 0 && !id) || id === data.conversations.parent_conversation_uuid || mrState.MRConvoID) {
        dispatch(updateResponse([{
          "ConvoId": data.conversations.id,
          "Persona": data.conversations.persona,
          "is_image": data.conversations.is_image,
          "image_url": data.conversations.image_url,
          "response": data.conversations.response,
          "is_user_prompt": data.conversations.is_user_prompt,
          "ai_model_url": data.conversations.ai_model_url,
          "loops_completed": data.conversations.loops_completed,
          "total_loops": data.conversations.total_loops,
          "polling": data.conversations.polling,
          "prompt": data.conversations.prompt,
          "parent_conversation_uuid": data.conversations.parent_conversation_uuid,
          "is_hp_error": data.conversations.is_hp_error,
          "is_ai_model_error": data.conversations.is_ai_model_error,
          "hover_text": data.conversations.hover_text,
          "type": data.conversations.type
        }]));
        dispatch(setResponseLogs([{
          "ConvoId": data.conversations.id,
          "Persona": data.conversations.persona,
          "is_image": data.conversations.is_image,
          "image_url": data.conversations.image_url,
          "response": data.conversations.response,
          "is_user_prompt": data.conversations.is_user_prompt,
          "ai_model_url": data.conversations.ai_model_url,
          "loops_completed": data.conversations.loops_completed,
          "total_loops": data.conversations.total_loops,
          "polling": data.conversations.polling,
          "prompt": data.conversations.prompt,
          "parent_conversation_uuid": data.conversations.parent_conversation_uuid,
          "is_hp_error": data.conversations.is_hp_error,
          "is_ai_model_error": data.conversations.is_ai_model_error,
          "hover_text": data.conversations.hover_text,
          "type": data.conversations.type
        }]));

        if (data.conversations.polling === false || data.conversations.loops_completed === data.conversations.total_loops) {
          dispatch(reset());
          mrState.setFileState('complete')
          dispatch(setScrollBottom(true));
          if (!id) {
            const newUrl = `/conversation/${data.conversations.parent_conversation_uuid}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
          }
        }
        if ((data.conversations.polling === false || data.conversations.loops_completed === data.conversations.total_loops && subsContext?.subData?.channelSub)) {
          mrState.setFileState('complete')
          unsubscribeChannel();
        }
        pmState.setupdateUserCredit(true);
      } else {
        unsubscribeChannel();
      }
    },
    disconnected() {
      console.log("Disconnected from Action Cable");
    },
    connected() {
      console.log("Connected to Action Cable");
    }
  };

  let conversationSubscription = null;
  function subscribeConversationChannel(p_conversation_id, setId) {
    const is_subscribed = cable.subscriptions.findAll(`{"channel":"ConversationChannel","parent_conversation_id":${p_conversation_id}}`).length > 0;

    if (!is_subscribed) {
      conversationSubscription = cable.subscriptions.create(
        { channel: "ConversationChannel", parent_conversation_id: p_conversation_id, set_id: setId },
        ConversationChannel
      );
      subsContext.update({ channelSub: conversationSubscription });
    }
  }

  const checkDisable = () => {
    if (!isApproved) {
      return true;
    }
    if (!pmState.credits) {
      return false;
    } else if ((!context.aiModelId || (status[status?.length - 1]?.polling === true && status[status?.length - 1]?.loops_completed !== status[status.length - 1]?.total_loops))) {
      if (context?.prompt) {  
        feedBtnWrapClasses.push('job-in-progress-btn');
      }       
      return true;
    } else {
      return false;
    }
  };
  if ((!context.prompt && mrState?.fileUpload && mrState?.fileUpload?.length === 0) && pmState.buttonText !== 'Add Credits') {
    feedBtnWrapClasses.push('color-disable');
  } else if (checkDisable() && context.prompt) {
      feedBtnWrapClasses.push('btn-busy');
  } else if ((feedRobotBtn || pmState.buttonText === 'Add Credits') && (context.prompt || !pmState.credits) && pmState.withoutFocus && !checkDisable() || (mrState.fileUpload?.length > 0 && !checkDisable() && pmState.withoutFocus) ) {
    feedBtnWrapClasses.push('text-area-btn');
  } else if ((feedRobotBtn || pmState.buttonText === 'Add Credits') && (context.prompt || !pmState.credits) && !pmState.withoutFocus || mrState.fileUpload?.length > 0 && !checkDisable()) {
    feedBtnWrapClasses.push('btn-focus');
  } 
  return (
    <>
      <div className={`${feedBtnWrapClasses.join(' ')} ${checkDisable() ? 'disable-btn' : ''}`}>
        {((pmState.buttonText === 'Feed Robot' && !checkDisable()) || (pmState.buttonText === 'Submit' && !checkDisable()) || (pmState.buttonText === 'Add Credits' && !checkDisable()) || (context.prompt && checkDisable())) && (
          <MRITooltip
            rightMargin={(!pmState.credits || checkDisable()) ? '0%' : ''}
            showTooltip={showTooltip}
            busy={checkDisable() && context.prompt}
            widthTooltip={(!pmState.credits || checkDisable()) && '120px'}
            textTooltip={!pmState.credits ? 'go to Subscriptions' : (context.prompt && checkDisable()) ? 'Processing request' : feedRobotBtn ? 'Send prompt and/or upload file(s)' : ''}
          />
         )} 
        <button
          variant="outline-primary"
          className="btn-disable custom-btn"
          onClick={handleButton}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          <span>
            {!isApproved ? 'Enter Text' :
              checkDisable() && context.prompt ? 'Busy...' :
              checkDisable() ? 'Enter Text' :
              feedRobotBtn ? 'Submit' :
              pmState.buttonText
            }
          </span>
          {checkDisable() && context.prompt ?
            <IconBusy /> : 
          (feedRobotBtn || mrState?.fileUpload?.length > 0) ? <IconArrowUp /> :
          pmState.credits && !context.prompt ?
            <IconArrowLeft /> : 
            <IconFlashWhiteBG width="22" height="21" />}
        </button>
      </div>
    </>
  );
}
