const Icon = (props) => {
    return (
        <>
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.4957 29C12.5913 29.0035 10.7051 28.6306 8.94528 27.9029C7.1855 27.1752 5.58692 26.1068 4.24131 24.7593C-1.41377 19.1044 -1.41377 9.90374 4.24131 4.24881C5.58488 2.89772 7.18313 1.82652 8.94351 1.09722C10.7038 0.367916 12.5914 -0.00500288 14.4968 5.06818e-05C18.3709 5.06818e-05 22.013 1.50835 24.7512 4.24881C27.4905 6.98811 29 10.63 29 14.5041C29 18.3781 27.4916 22.02 24.7512 24.7593C23.4053 26.1068 21.8065 27.1752 20.0466 27.9029C18.2866 28.6306 16.4002 29.0035 14.4957 29ZM14.4968 2.3205C12.8962 2.31596 11.3105 2.62906 9.83175 3.24167C8.35297 3.85429 7.01043 4.75423 5.88192 5.88934C3.58113 8.19013 2.31413 11.2496 2.31413 14.5041C2.31413 17.7585 3.58113 20.8169 5.88192 23.1188C10.632 27.8687 18.3616 27.8699 23.1106 23.1188C25.4114 20.8181 26.6795 17.7585 26.6795 14.5041C26.6795 11.2496 25.4125 8.19124 23.1106 5.88934C21.982 4.75456 20.6397 3.85485 19.1612 3.24226C17.6826 2.62967 16.0973 2.31637 14.4968 2.3205Z" fill="white" />
                <path d="M9.57377 20.5866C9.34408 20.5872 9.1194 20.5194 8.92829 20.3921C8.73718 20.2646 8.5882 20.0833 8.50034 19.8711C8.41241 19.6589 8.3896 19.4254 8.43462 19.2002C8.4797 18.975 8.5907 18.7682 8.75348 18.6062L18.5982 8.76163C18.706 8.65392 18.8338 8.56849 18.9746 8.51013C19.1153 8.45184 19.2662 8.42188 19.4185 8.42188C19.5709 8.42188 19.7217 8.45184 19.8624 8.51013C20.0032 8.56849 20.1311 8.65392 20.2388 8.76163C20.3465 8.86934 20.432 8.99722 20.4903 9.13798C20.5486 9.27875 20.5786 9.42956 20.5786 9.58189C20.5786 9.73422 20.5486 9.88511 20.4903 10.0259C20.432 10.1666 20.3465 10.2945 20.2388 10.4022L10.3941 20.2468C10.2866 20.3549 10.1588 20.4406 10.018 20.4989C9.87719 20.5573 9.72617 20.5871 9.57377 20.5866Z" fill="#9D9D9D" />
                <path d="M19.4189 20.5866C19.2665 20.5868 19.1157 20.5569 18.9749 20.4985C18.8341 20.4402 18.7062 20.3546 18.5986 20.2468L8.75382 10.4022C8.64611 10.2945 8.56068 10.1666 8.50239 10.0259C8.4441 9.88511 8.41406 9.73422 8.41406 9.58189C8.41406 9.42956 8.4441 9.27875 8.50239 9.13798C8.56068 8.99722 8.64611 8.86934 8.75382 8.76163C8.8616 8.65392 8.98949 8.56849 9.13019 8.51013C9.27095 8.45184 9.42184 8.42188 9.57418 8.42188C9.72651 8.42188 9.87733 8.45184 10.0181 8.51013C10.1589 8.56849 10.2868 8.65392 10.3945 8.76163L20.2392 18.6062C20.402 18.7682 20.513 18.975 20.558 19.2002C20.6031 19.4254 20.5802 19.6589 20.4924 19.8711C20.4044 20.0833 20.2555 20.2646 20.0644 20.3921C19.8732 20.5194 19.6486 20.5872 19.4189 20.5866Z" fill="#9D9D9D" />
            </svg>

        </>
    );
};

export default Icon;