
import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { BsCheckSquare, BsSquare } from 'react-icons/bs';
import styled, { css } from "styled-components";
import MRLContext from './MRLContext';
import { MRFolderAPI } from '../../../helper/MRFolderAPI';
import { MRLogsAPI } from '../../../helper/MRLogsAPI';
import SkeletonLoading from '../MRConversation/MRCLoading';
import MainRegionContext from '../General/MRContext';
import coversationContext from '../../../context/conversationContext';
const Input = styled.input`
  ${({ theme }) => css`
    border: 1px solid white;
    position: absolute;
  `}
  `;

const MRLFilter = () => {
  const [sortData, setSortData] = useState()
  const [selectedItemIcon, setSelectedItemIcon] = useState();
  const [selectedItemsResponseType, setSelectedItemsResponseType] = useState();
  const [filteredResponseType, setFilteredResponseType] = useState('')
  const [aiModels, setAiModels] = useState()
  const [selectedAiModels, setSelectedAiModels] = useState('')
  const [isOpenResponseDropdown, setIsOpenResponseDropdown] = useState(false);
  const [isOpenAIDropdown, setisOpenAIDropdown] = useState(false)
  const [selectedTab, setSelectedTab] = useState('All');
  const [aiModelTab, setAiModelTab] = useState(false)
  const [responseTypeTab, setResponseTypeTab] = useState(false)
  const [selectedSortName, setSelectedSortName] = useState()
  const [allResponseTypeSelected, setAllResponseTypeSelected] = useState(false)
  const [allAiModelSelected, setAllAiModelSelected] = useState(false)
  const [selectedAiModelsArray, setSelectedAiModelsArray] = useState([])
  const [selectedResponseModelArray, setSelectedResponseModelArray] = useState([])

  const [loader, setLoader] = useState(false)
  const dropdownRefResponseType = useRef(null);
  const dropdownRefAiModel = useRef(null)
  const logsContext = useContext(MRLContext);
  const mrState = useContext(MainRegionContext);
  const context = useContext(coversationContext);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('click', handleClickOutsideAiModel);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('click', handleClickOutsideAiModel);
    };
  }, []);
  //Sort and filter api
  useEffect(() => {
    MRLogsAPI.getSortOptions()
      .then((response) => {
        setSortData(response.data)
        if (!selectedItemIcon) {
          setSelectedItemIcon(response.data[0].icon)
        }


      })
      .catch((error) => {
        console.error("Error fetching logs data:", error);
      })


    MRLogsAPI.getFilterOption()
      .then((response) => {
        setAiModels(response["data"][0].values)

        let uniqueValuesMap = response["data"][1].values.reduce((acc, value) => {
          acc[value.display_name] = value;
          return acc;
        }, {});

        let uniqueValuesArray = Object.values(uniqueValuesMap);
        response["data"][1].values = uniqueValuesArray;
        setSelectedItemsResponseType(response["data"][1].values)
      })
      .catch((error) => {
        console.error("Error fetching logs data:", error);
      })
  }, [])

  useEffect(() => {
    logsContext.resetLogsData()
    setLoader(true)
  }, [selectedTab]);


  useEffect(() => {
    var responseTypesSelected = null
    var filter = false

    if (selectedAiModelsArray?.length > 0 && !selectedResponseModelArray?.length) {
      responseTypesSelected = selectedItemsResponseType?.map(item => item.value)
    } else {
      responseTypesSelected = selectedResponseModelArray
    }

    if (aiModels?.length === selectedAiModelsArray?.length && selectedItemsResponseType?.length === selectedResponseModelArray?.length) {
      filter = false
    } else if (selectedAiModelsArray?.length > 0 || selectedResponseModelArray?.length > 0) {
      filter = true
    }

    MRFolderAPI.listFolders(false, selectedSortName, selectedTab, selectedAiModelsArray, responseTypesSelected, filter, context.searched)//sort, filter,ai model,response type, filter, query
      .then((response) => {
        logsContext.updateLogsFilterAndSort(response)
        setLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching logs data:", error)
        setLoader(false)
      })
      .finally(() => {
        setLoader(false)
      })
  }, [selectedSortName, selectedTab, selectedAiModelsArray, selectedResponseModelArray, context.searched]);

  const handleClickOutside = (event) => {
    if (dropdownRefResponseType.current && !dropdownRefResponseType.current.contains(event.target)) {
      setIsOpenResponseDropdown(false);
      setResponseTypeTab(false)
    }
  };

  const handleClickOutsideAiModel = (event) => {
    if (dropdownRefAiModel.current && !dropdownRefAiModel.current.contains(event.target)) {
      setisOpenAIDropdown(false);
      setAiModelTab(false)
    }
  };

  const handleItemClick = (item) => {
    setSelectedItemIcon(item.icon);
    setSelectedSortName(item.display_name)
  };

  const handleSelectAllResponseType = (event) => {
    event.stopPropagation()
    setAllResponseTypeSelected(!allResponseTypeSelected)

    if (!allResponseTypeSelected) {
      const allValues = selectedItemsResponseType.map(item => item.value);
      setSelectedResponseModelArray(allValues);

    } else {
      setSelectedResponseModelArray([]);
    }
  }

  const handleSelectAllAiModel = (event) => {
    event.stopPropagation()
    setAllAiModelSelected(!allAiModelSelected)

    if (!allAiModelSelected) {
      const allValues = aiModels.map(item => item.value);
      setSelectedAiModelsArray(allValues);
    } else {
      setSelectedAiModelsArray([]);
    }
  }

  const toggleResponseTypeDropdown = () => {
    setResponseTypeTab(!responseTypeTab)
    if (!isOpenResponseDropdown) {
      setFilteredResponseType('')
    }
    setIsOpenResponseDropdown(!isOpenResponseDropdown);
  };

  const toggleAIDropdown = () => {
    setAiModelTab(!aiModelTab)
    if (!isOpenAIDropdown) {
      setSelectedAiModels('')
    }
    setisOpenAIDropdown(!isOpenAIDropdown);
  };

  const handleClickCheckboxAIModel = (item, index, event) => {
    event.stopPropagation()
    const indexValue = selectedAiModelsArray?.indexOf(item.value)
    if (indexValue === -1) {
      setSelectedAiModelsArray([...selectedAiModelsArray, item.value])
    } else {
      const newArray = selectedAiModelsArray.filter(seletedItem => seletedItem !== item.value)
      setSelectedAiModelsArray(newArray)
      setAllAiModelSelected(false)
    }
  }

  const handleClickCheckboxResponseType = (item, index, event) => {
    event.stopPropagation()
    const indexValue = selectedResponseModelArray?.indexOf(item.value)
    if (indexValue === -1) {
      setSelectedResponseModelArray([...selectedResponseModelArray, item.value])
    } else {
      const newArray = selectedResponseModelArray.filter(seletedItem => seletedItem !== item.value)
      setSelectedResponseModelArray(newArray)
      setAllResponseTypeSelected(false)
    }
  };

  return (
    <>
      <div className='filterDiv'>
        <div className='sortDiv'>
          <button
            className={`sortDiv-child-btn btn btn-primary ${selectedTab === 'All' ? 'selected-tab' : ''}`}
            onClick={() => setSelectedTab('All')}
          >
            All
          </button>
          <button
            className={`sortDiv-child-btn btn btn-primary ${selectedTab === 'Folders' ? 'selected-tab' : ''}`}
            onClick={() => setSelectedTab('Folders')}
          >
            Folders
          </button>
          <button
            className={`sortDiv-child-btn btn btn-primary ${selectedTab === 'Conversations' ? 'selected-tab' : ''}`}
            onClick={() => setSelectedTab('Conversations')}
          >
            Conversations
          </button>
          <button
            className={`sortDiv-child-btn btn btn-primary ${selectedTab === 'Jobs' ? 'selected-tab' : ''}`}
            onClick={() => setSelectedTab('Jobs')}
          >
            Jobs
          </button>
          {/* AI model */}
          <button ref={dropdownRefAiModel} className={`dropdown dropdown-style btn btn-primary sortDiv-child ${aiModelTab ? 'selected-tab' : ''}`}>
            <span className={`dropdown-header dropdown-header-style`} onClick={toggleAIDropdown}>
              AI
            </span>
            {isOpenAIDropdown && (
              <div className="dropdown-content dropdown-content-ai-model">
               
                <div key="all" className="dropdown-option ai-dropdown-options all-model" onClick={(event) => handleSelectAllAiModel(event)}>
                  <span style={{ marginBottom: "2%", marginRight: "2%" }}>
                    <Input
                      type="checkbox"
                      id="checkbox"
                      style={{ opacity: 0 }}
                    />
                    {allAiModelSelected ? <BsCheckSquare fill={"#5DCDF5"} size={15} /> : <BsSquare fill={"#5DCDF5"} size={15} />}
                  </span>
                  <span className="item-content">
                    <div className="item-name">All Agents/models</div>
                    <div className="item-type">all</div>
                  </span>
                </div>
                {aiModels?.map((item, index) => (
                  <div key={item.id} className="dropdown-option ai-dropdown-options" onClick={(event) => handleClickCheckboxAIModel(item, index, event)}>
                    <span style={{ marginBottom: "2%", marginRight: "2%" }}  >

                      <Input
                        type="checkbox"
                        id="checkbox"
                        style={{ opacity: 0 }}

                      />
                      {selectedAiModelsArray.includes(item.value) ? <BsCheckSquare fill={"#5DCDF5"} size={15} /> : <BsSquare fill={"#5DCDF5"} size={15} />}
                    </span>
                    <span className="item-content">
                      <div className="item-name">{item.display_name}</div>
                      <div className="item-type">{item.type}</div>
                    </span>
                  </div>
                ))}
              </div>
            )}
          </button>
          {/* Response type model */}

          <button ref={dropdownRefResponseType} className={`dropdown  dropdown-style btn btn-primary  sortDiv-child ${responseTypeTab ? 'selected-tab' : ''}`}>
            <span className={`dropdown-header dropdown-header-style `} onClick={toggleResponseTypeDropdown}>
              Response Type
            </span>
            {isOpenResponseDropdown && (
              <div className="dropdown-content dropdown-content-response-type" >
                <div key="all" className="dropdown-option" onClick={(event) => handleSelectAllResponseType(event)}>
                  <span style={{ marginBottom: "2%", marginRight: "2%" }}>
                    <Input
                      type="checkbox"
                      id="checkbox"
                      style={{ opacity: 0 }}
                    />
                    {allResponseTypeSelected ? <BsCheckSquare fill={"#5DCDF5"} size={15} /> : <BsSquare fill={"#5DCDF5"} size={15} />}
                  </span>
                  <label>All</label>
                </div>
                {selectedItemsResponseType?.map((item, index) => (
                  <div key={item.id} className="dropdown-option" onClick={(event) => handleClickCheckboxResponseType(item, index, event)} >
                    <span style={{ marginBottom: "2%", marginRight: "2%" }}>

                      <Input
                        type="checkbox"
                        id="checkbox"
                        style={{ opacity: 0 }}

                      />
                      {selectedResponseModelArray.includes(item.value) ? <BsCheckSquare fill={"#5DCDF5"} size={15} /> : <BsSquare fill={"#5DCDF5"} size={15} />}
                    </span>

                    <label>{item.value}</label>
                  </div>
                ))}
              </div>
            )}
          </button>
        </div>
        {/* sort menu */}
        <div className='dropdownDiv'>
          <DropdownButton
            align="start"
            title={
              <div className='aiDropWrap'>
                <span className='dropTitle'>
                  <span dangerouslySetInnerHTML={{ __html: selectedItemIcon }} />

                </span>
              </div>
            }
            drop="down"
            direction="down"
            className="custom-dropdown-button"
          >
           <span className='title-dropdown-sort-by'>
              Sort By
          </span>
            {sortData?.map(item => (
              <Dropdown.Item
                key={item.value}
                onClick={() => handleItemClick(item)}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <span> {item.display_name}</span>
                <span className={`dropDownItems ${selectedItemIcon === item.icon ? 'selected-item-icon' : ''}`} dangerouslySetInnerHTML={{ __html: item.icon }} />
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      </div>
      {
        (loader || !logsContext.logsData) && <div className="loading">
          <SkeletonLoading runTime={mrState.loadingTime} />
        </div>
      }
    </>
  );
};


export default MRLFilter;
