const Icon = (props) => {
    return (
        <>
            <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.35604 0.338201C6.11962 0.112722 5.80985 -4.53738e-07 5.5 -4.80825e-07C5.19023 -5.07906e-07 4.8803 0.112758 4.64396 0.338201L0.354593 4.42911C-0.118199 4.88003 -0.118199 5.6111 0.354593 6.06202C0.827386 6.51294 1.59391 6.51294 2.0667 6.06202L4.28936 3.94218L4.28936 12.8454C4.28936 13.483 4.83139 14 5.5 14C6.16861 14 6.71064 13.483 6.71064 12.8454L6.71064 3.94218L8.9333 6.06202C9.40609 6.51294 10.1726 6.51294 10.6454 6.06202C11.1182 5.6111 11.1182 4.88003 10.6454 4.42911L6.35604 0.338201Z" fill="white" />
            </svg>
        </>
    );
};

export default Icon;
