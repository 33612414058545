// src/apis/MRLogsAPI.js

import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"
import Cookies from 'js-cookie';

//SET Credentials when live
const credentials = false

export const MRLogsAPI = {
  

    getLogsData: async function ( cancel = false ,page, per_page) {
      const response = await api.request({
        method: "GET",
        url: `/api/v1/folders`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
        params: {
          page,
          per_page        },

      }) 
      console.log(response.data)
      return response.data
    },    
    getSortOptions: async function ( cancel = false ) {
      const response = await api.request({
        method: "GET",
        url: `/api/v1/logs/sort_options`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
      }) 
      console.log(response.data)
      return response.data
    },
    getFilterOption:  async function ( cancel = false ) {
      const response = await api.request({
        method: "GET",
        url: `/api/v1/logs/filter_options`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
      }) 
      console.log(response.data)
      return response.data
    },
    

    changePrivacy: async function ( cancel = false ,convoId, privacy) {
      const response = await api.request({
        method: "PATCH",
        url: `/api/v1/conversation/${convoId}/privacy`,
        withCredentials: credentials,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        },
        params: {
          public : privacy
        },

      }) 
      return response.data
    },
    deleteJob: async function (jobID, cancel = false) {
      await api.request({
        method: "POST",
        url: `/api/v1/jobs/${jobID}/delete`,
        withCredentials: credentials,
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        headers: {
          Authorization: Cookies.get('token'),
        }
      })
    }
}

// defining the cancel API object for MRLogsAPI
const cancelApiObject = defineCancelApiObject(MRLogsAPI)