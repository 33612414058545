const IconArrowUp = (props) => {
    return (
        <>
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height} 
            viewBox="0 0 11 8" 
            fill="none">
                <g filter="url(#filter0_dd_1985_7171)">
                    <rect width="4.8994" height="0.816567" rx="0.408284" transform="matrix(-0.714363 -0.699776 0.714363 -0.699776 8.41602 6)" fill="#FAFAFA" />
                    <rect width="4.89941" height="0.816567" rx="0.408284" transform="matrix(0.714363 -0.699776 0.714363 0.699776 2 5.42773)" fill="#FAFAFA" />
                </g>
                <defs>
                    <filter id="filter0_dd_1985_7171" x="0.5" y="0.5" width="10" height="7" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="0.5" dy="0.5" />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.3625 0 0 0 0 0.3625 0 0 0 0 0.3625 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1985_7171" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="-0.5" dy="-0.5" />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="effect1_dropShadow_1985_7171" result="effect2_dropShadow_1985_7171" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1985_7171" result="shape" />
                    </filter>
                </defs>
            </svg>
        </>
    );
};

export default IconArrowUp;