import React, { useState } from 'react';
import { AccordionHeader, AccordionItem } from "react-headless-accordion";
import IconAccordion from '../../icons/IconAccordion';
import IconGP from '../../icons/IconGP';
import MRAKabob from "../General/MRKabob";
import MRFormatting from "./MRFormatting";
import MRCErrorPrompt from './MRCErrorPrompt';

const MRReadMore = ({ children, expand }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(!expand);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const isSingleLine = text.length <= 68;
    return (
        <>
            <div
                onClick={toggleReadMore}
                className={`AccoIcon ${isReadMore && !isSingleLine ? '' : 'active'}`}
                style={{ cursor: !isSingleLine ? 'pointer' : 'default' }}
            >
                <IconAccordion width="20" height="12" disabled={isSingleLine} />
            </div>
            <p className="text">
                {isReadMore
                    ? text.slice(0, 68)
                    : <div className="Code"><MRFormatting markdownContent={text} /></div>}
                {(isReadMore && text.length > 68) ? "..." : ''}
            </p>
        </>
    );
};


const MRPromptText = ({ promptText, expand }) => {
    const text = promptText;
    if (text) {
        return (
            <MRReadMore expand={expand}>
                {promptText}
            </MRReadMore>
        );
    }
};

const MRCPrompt = (props) => {
    const data = props?.prompt;
    const response = data;
    const receipt = props?.receipt
    // For accordiong rotation functionality
    const [isActive, setIsActive] = useState(false);
    const [hover, setHover] = useState(false)
    const [hoverGPIcon, setHoverGPIcon] = useState(false)

    const toggleActive = () => {
        setIsActive(!isActive);
        console.log("activated")
    };

    const handleHoverleftIcon = () => {
        setHover(true)
    }

    const handleLeaveleftIcon = () => {
        setHover(false)
    }

    const handleHoverGPIcon = () => {
        setHoverGPIcon(true)
    }

    const handleLeaveGPIcon = () => {
        setHoverGPIcon(false)
    }

    return (
        <>
            <AccordionItem key={response?.ConvoId}>
                {/* <div className="acc_header_wrapper MRCprompt_wrap modelIconWrap"> */}
                <div className={`${data?.is_hp_error ? 'error_header': ''} acc_header_wrapper MRCprompt_wrap modelIconWrap ${response?.is_user_prompt ? 'gp_prompts_wrap' : ''}`}>
                    {/* AI Icon */}
                    {data.is_hp_error ? <MRCErrorPrompt promptData={data}/> : <> {!response?.is_user_prompt &&
                        <div className="GptleftIcon">
                        
                            {/* <img src={response.ai_model_url}/>*/}
                            <div className="modelIcon" onMouseEnter={handleHoverleftIcon} onMouseLeave={handleLeaveleftIcon}>
                                <img src={response?.ai_model_url} alt="aiIcon" />
                            </div>
                            {hover &&  <p className='GptleftIconHover'>
                                {response?.hover_text}
                            </p> }               
                        </div>
                    }
                        <AccordionHeader as='div' className="titleWrap text" >
                            <div className="acco_col " onClick={toggleActive} >
                                {!data?.is_image && !response?.is_user_prompt}

                            <div className="">
                                <span className={`accordion-title ${isActive ? 'active-rotate ' : ''}`}>
                                    {data?.is_image ? <img src={data?.image_url} className="img-fluid ai_image_response" /> : <MRPromptText promptText={data?.response} expand={props.expand} />}
                                </span>
                            </div>

                                {/* 
                            Enable when comments come live
                            <div className="AccoIcon" onClick={() => console.log("trigger Comment")}>
                                <IconComment width="19" height="19" />
                            </div>
                            */}
                            </div>

                        </AccordionHeader>
                        {!response?.is_ai_model_error && <MRAKabob
                            copyText={!data?.is_image ? data?.response : null}
                            downloadImg={data?.is_image ? data?.image_url : null}
                            receipt={receipt}

                        //deleteConvo={data.id}
                        />}
                        {/* Icon right */}

                        {data?.is_user_prompt &&
                            <div className="GPIcon ConversationGPIcon">
                                <div className="modelIcon" onMouseEnter={handleHoverGPIcon} onMouseLeave={handleLeaveGPIcon}>
                                    <IconGP width={22} />
                                </div>
                                {hoverGPIcon && <div className='GPIcon-hover'>
                                    {response?.hover_text}
                                </div>}
                            </div>
                        }</>}

                </div>
            </AccordionItem>
        </>
    );
};

export default MRCPrompt;