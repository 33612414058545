import React, {  useContext, useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from 'react-bootstrap/NavLink';
import Button from 'react-bootstrap/Button';

import MainRegionContext from "../main-region/General/MRContext";

import HMMenuButtons from './HMMenuButtons';
import IconHyperPrompts from '../icons/IconAtticus';
import IconNewFolder from '../icons/IconNewFolder';

import IconNewConversation from '../icons/IconNewConversation';
import IconLogs from '../icons/IconLogs';
import { useNavigate } from "react-router-dom";
import IconGP from "../icons/IconGP";
import coversationContext from "../../context/conversationContext";
import IconGigaPrompt from "../icons/IconGigaPrompt";
import IconYoutube from "../icons/IconYoutube";
import MRConversation from "../main-region/MRConversation/MRConversation";
import MRLContext from "../main-region/MRLogs/MRLContext";
import pmContext from "../profile-menu/PMContext";
import DropDownBox from '../main-region/MRDropDown';
import Col from 'react-bootstrap/Col';
import { useDispatch } from "react-redux";
import { resetResponseData } from "../../redux/slices/conversationSlice";
import { resetLogsData, setAddNewFolder, setExpandResponse, updateDisableEditing, updateLogoutUser } from "../../redux/slices/logsSlice";
import { useIsAuthenticated } from "react-auth-kit";


function HMMenu({param}) {
  const MainRegionState = useContext(MainRegionContext);
  const conversation = useContext(coversationContext);
  const pmState = useContext(pmContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const MRShow = MainRegionState.MRShow[0];
  const MRLogs = useContext(MRLContext)
  const dispatch = useDispatch()
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    
    if(param === 'conversation'){
      newMRConversation()
 
  }else if(param === 'conversations'){
      showMRLogs()

    }
  
  },[param])
  




  const showMRHome = () => {
    MainRegionState.setMRSubscription(false);
    MainRegionState.setMRShow([
      {
        "MRTextHeader": true,
        "MRGigaPrompt": false,
        "MRInputBar": true,
        "MRConversation": true,
        "MRLogs": false
      }
    ]);
    pmState?.setupdateUserCredit(true)
  }
  const showGigaPrompt = () => {
    MainRegionState.setMRSubscription(false);
    if(MainRegionState.MRShow[0].MRGigaPrompt){
      MainRegionState.setMRShow([
        {
          "MRTextHeader": (!MainRegionState.MRShow[0].MRTextHeader),
          "MRGigaPrompt": (!MainRegionState.MRShow[0].MRGigaPrompt),
          "MRInputBar": true,
          "MRConversation": true,
          "MRLogs": false
        }
    ]);
    
    } else{
      MainRegionState.setMRShow([
        {
          "MRTextHeader": (!MainRegionState.MRShow[0].MRTextHeader),
          "MRGigaPrompt": true,
          "MRInputBar": true,
          "MRConversation": false,
          "MRLogs": false
        }
    ]);
    }
    pmState?.setupdateUserCredit(true)

  };
  const newMRConversation = () => {
    conversation.resetAiModelValue()
    MainRegionState.setMRSubscription(false);
    MainRegionState.setMRConvoID(0);
    MainRegionState.setMRShow([
      {
        "MRTextHeader": true,
        "MRGigaPrompt": false,
        "MRInputBar": true,
        "MRConversation": true,
        "MRLogs": false
      }
    ]);
    dispatch(resetResponseData())
    dispatch(resetLogsData())
    dispatch(updateDisableEditing(false))
    dispatch(updateLogoutUser(false))
    navigate('/conversation')
    pmState?.setupdateUserCredit(true)

  }
  const showMRFolder = () => {
    MainRegionState.setMRSubscription(false);
    MainRegionState.setMRShow([
      {
        "MRTextHeader": true,
        "MRGigaPrompt": false,
        "MRInputBar": true,
        "MRConversation": false,
        "MRLogs": true
      }
    ]);
    dispatch(resetResponseData())
    dispatch(resetLogsData())
    dispatch(updateDisableEditing(false))
    dispatch(updateLogoutUser(false))
    dispatch(setAddNewFolder(true))
    navigate('/conversations')
  };
  const showMRLogs = () => {
    MainRegionState.setMRSubscription(false);
    MainRegionState.setMRShow([
      {
        "MRTextHeader": true,
        "MRGigaPrompt": false,
        "MRInputBar": true,
        "MRConversation": false,
        "MRLogs": true
      }
    ]);
    dispatch(resetResponseData())
    dispatch(resetLogsData())
    dispatch(updateDisableEditing(false))
    dispatch(updateLogoutUser(false))
    dispatch(setExpandResponse(false))
    navigate('/conversations')
    pmState?.setupdateUserCredit(true)
  };
  return (
    <>
   { isAuthenticated() &&  <Navbar className="position-fixed top-0 start-0 hyper-menu">
      <Nav className="d-flex vh-100 flex-column justify-content-between">
        <div className="flex-column hp-top-menu">
          <Nav.Item onClick={showMRHome}>
            <HMMenuButtons active={false} Icon={<IconHyperPrompts width='24' height='24' />}>
              Atticus
            </HMMenuButtons>
          </Nav.Item>
          <Nav.Item onClick={newMRConversation}>
            <HMMenuButtons active={false} link="/conversation" Icon={<IconNewConversation fill="#006400" width='24' height='24' />}>
              New Conversation
            </HMMenuButtons>
          </Nav.Item>
          <Nav.Item onClick={showMRFolder} >
            <HMMenuButtons  active={false} link='/conversations#nFolder' Icon={<IconNewFolder fill="#006400" width='24' height='24' />}>
              New Folder
            </HMMenuButtons>
          </Nav.Item>
          <Nav.Item onClick={showMRLogs}>
            <HMMenuButtons active={false} link="/conversations" Icon={<IconLogs fill="#006400" width='24' height='24' />} onClick>
              Logs
            </HMMenuButtons>
          </Nav.Item>
          {/*To be removed*/}
          <Nav.Item>
        {/* <Nav.Link target="_blank" href="https://www.youtube.com/playlist?list=PLtbChfEU4ITqfKxnJnSGA0E8sX7MAqw8-" active={false} className='hm-item d-flex align-items-center'>
      <Button className='hm-iconbtn' variant="outline-secondary">
      <IconYoutube width='24' height='24' />
      </Button>
      <div className="btn-intro">
        <span>YouTube</span>
      </div>
    </Nav.Link> */}
          </Nav.Item>
            {/*To be removed*/}
        </div>

        {/* <div className="flex-column hp-bottom-menu">

          <Nav.Item onClick={newMRConversation}>
            <HMMenuButtons link= "/conversation" active={false} Icon={<IconNewConversation width='24' height='24' />}>
              New Conversation
            </HMMenuButtons>
          </Nav.Item>
          
          <Nav.Item onClick={showMRFolder}>
            <HMMenuButtons active={false} link='/conversations#nFolder' Icon={<IconNewFolder width='24' height='24' />} onClick>
              New Folder
            </HMMenuButtons>
          </Nav.Item>
           
          <Nav.Item onClick={showMRLogs}>
            <HMMenuButtons link= "/conversations" Icon={<IconLogs width='24' height='24' />}>
              Conversations
            </HMMenuButtons>
          </Nav.Item>
        </div> */}
      </Nav>
    </Navbar>
    }
    { (id && !MRShow.MRGigaPrompt ) && <> <DropDownBox welcomePage={false} /> <MRConversation /> </> }
    </>
  );
}

export default HMMenu;