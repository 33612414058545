import React from 'react';
import { Link } from 'react-router-dom'
import LCol from './HPLoginLCol'
import SignUpform from './hp-signUpForm'
import { useIsAuthenticated } from 'react-auth-kit';
import { Navigate } from 'react-router-dom'


const Signup = () => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated()) {
        // If authenticated user, then redirect to secure dashboard
        return (
            <Navigate to={'/conversation'} replace />
        )
    }
    return (
        <>
            <div className="auth_page_wrapper">
                <LCol />
                <div className="auth_col">
                    <div className="form_scroll">
                        <div className="form_wrap">
                            <h2 className="log_title">Sign up <span className="log_sub">Join the party</span></h2>
                            <SignUpform />
                            <div className="alt_auth_wrapper text-center">
                                <div className="divider"><span>or</span></div>
                                <p>Already have an account?</p>
                                <Link to="/login" className="log_btn bg_white"> Log In</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;
