import React, { useEffect } from "react";
import MRState from "../main-region/General/MRState";
import PMState from "../profile-menu/PMState";
import HMMenu from "./HMMenu";
import MainRegion from "../main-region/MainRegion";
import PMMenu from "../profile-menu/PMMenu";
import { useIsAuthenticated } from "react-auth-kit";
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

function HMConversationId() {
  const isAuthenticated = useIsAuthenticated();

  const isTokenValid = () => {
    const token = Cookies.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp > currentTime;
      } catch (error) {
        console.log('Invalid token');
      }
    }
    return false;
  };

  return (
    <>
       <MRState>
           <div className="HyperPrompt">
               {isAuthenticated() && isTokenValid ? 
                   <PMState>
                       <HMMenu />
                       <MainRegion />
                       <PMMenu />
                   </PMState>
                   :
                   <>
                       <HMMenu />
                       <MainRegion />
                       <PMMenu />
                   </>
               }
           </div>
       </MRState>
    </>
  );
}

export default HMConversationId;