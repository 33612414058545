import React, { useContext, useEffect, useState } from "react";
import { useIsAuthenticated } from 'react-auth-kit'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';

import HMMenu from '../../hyper-menu/HMMenu'
import PMMenu from '../../profile-menu/PMMenu'
import MRState from '../../main-region/General/MRState'
import PMState from '../../profile-menu/PMState';

import MRSCards from "./MRSCards";
import MRSHeader from "./MRSHeader";
import MRSButton from "./MRSButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileMenuContext from "../../profile-menu/PMContext";



function MRSubscriptions() {

const isAuthenticated = useIsAuthenticated()
const baseUrl = process.env.REACT_APP_BASE_URL;
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const [subscriptionData, setSubscriptionData] = useState('')
const [header, setHeader] = useState('Service Plan')
const [subHeader, setSubHeader] = useState('Purchase plan to get instant access to Giga Prompts!!')
const [hasThriveCartParam, setHasThriveCartParam] = useState(false);
const [product_id, setProductId] = useState()
const [invoice_id, setInvoiceId] = useState()
const [loader,setLoader] = useState(false)
const pmState = useContext(ProfileMenuContext);
const navigate = useNavigate();
const [hasBeenClicked, setHasBeenClicked] = useState(false);  


useEffect(() => {
  const hasThriveCart = Array.from(searchParams.keys()).some(
    (param) => param.startsWith('thrivecart')
  );

  setHasThriveCartParam(hasThriveCart);
    
  setProductId(searchParams.get('thrivecart[product_id]'))
  setInvoiceId(searchParams.get('thrivecart[invoice_id]'))


}, [searchParams]);


useEffect(() => {
  axios.defaults.headers.common['Authorization'] = Cookies.get('token');


  const fetchSubscriptionData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/subscriptions`);
      if (response.status === 200) {
        console.log(response?.data.data);
        setHeader(response?.data?.data?.header);
        setSubHeader(response?.data?.data?.sub_header);
        setSubscriptionData(response?.data?.data?.subscriptions);
        let product = response?.data?.data?.subscriptions?.filter(product =>
          product?.thrive_cart_product_id === product_id
        );
        setLoader(product)
      }
    } catch (error) {
      console.error("An error occurred during subscription data fetch:", error);
    }
  };

  fetchSubscriptionData(); 

}, [baseUrl]);

useEffect(() => {
  axios.defaults.headers.common['Authorization'] = Cookies.get('token');


  const creditChange = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/subscriptions/polling` , invoice_id);
      if (response.status === 200) {
        navigate('/conversation');
        pmState.setupdateUserCredit(true)
       }
    } catch (error) {
      console.error("An error occurred during subscription data fetch:", error);
    }
  };
    if(hasThriveCartParam && loader){
    creditChange(); 
    }

}, [hasThriveCartParam,loader]);




return (
<>
  <MRState>
    <div className="HyperPrompt">
      <HMMenu />
      {isAuthenticated() &&
      <PMState>
        <div>
          <Container>
            <Row>
              <Col>
                <div className="text-center subscription_page_header">{header} <div className="text-center subscription_page_sub_header">{subHeader}</div></div>
              </Col>
            </Row>
            
          </Container>
          
            <div className="subs_wrapper">
            {subscriptionData && subscriptionData.map((data) => (
              <div className="subs_card" key={data?.id}>
                <MRSCards> 
                  
                  <div className="subs_title">
                    <MRSHeader current={data?.is_current_plan}  subhead={false}  header={data?.name}/>
                    {data?.is_current_plan  && <span>Current Plan</span> } 
                  </div>
                  <div className="subsPhoto">
                    <img className="img-fluid" src={data?.image_url} alt={data?.name} />
                  </div>
                  <div className="subscription_desc" dangerouslySetInnerHTML={{ __html: data?.description}} >
                  </div>
                  <MRSButton hasBeenClicked= {hasBeenClicked} setHasBeenClicked= {setHasBeenClicked} header_name={data?.name} is_current_plan = {data.is_current_plan} infinite={data?.is_infinite} loading={hasThriveCartParam && data?.thrive_cart_product_id === product_id}  product_id={data?.thrive_cart_product_id} disable={hasThriveCartParam} buttonText={data?.cta_amount}  link={data?.thrive_cart_product_url}  />
                </MRSCards>
              </div>
            ))}

            </div>
        </div>
        <PMMenu />
      </PMState>
      }
    </div>
  </MRState>
</>
);
}

export default MRSubscriptions;