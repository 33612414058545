const Icon = (props) => {
    return (
        <>
            <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.838202 4.64397C0.612722 4.88038 0.5 5.19015 0.5 5.5C0.5 5.80977 0.612759 6.1197 0.838202 6.35604L4.92911 10.6454C5.38003 11.1182 6.1111 11.1182 6.56202 10.6454C7.01294 10.1726 7.01294 9.40609 6.56202 8.9333L4.44218 6.71064L13.3454 6.71064C13.983 6.71064 14.5 6.16861 14.5 5.5C14.5 4.83139 13.983 4.28936 13.3454 4.28936L4.44218 4.28936L6.56202 2.0667C7.01294 1.59391 7.01294 0.827387 6.56202 0.354594C6.1111 -0.118198 5.38003 -0.118198 4.92911 0.354594L0.838202 4.64397Z" fill="#C7C7C7" />
            </svg>
        </>
    );
};

export default Icon;
