import React, { useState, useContext, useEffect, useRef } from 'react';
import { Form, InputGroup, Dropdown, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import axios from 'axios';
import MRIDropdown from './MRIDropdown';
import MRIButton from './MRIButton';
import MRITextInput from './MRITextInput';
import ExpandBtn from '../../icons/ExpandBtn';
import IconExpand from '../../icons/IconExpand';
import IconVertex from '../../icons/LogsIcons/IconVertex'
import IconCircle from '../../icons/LogsIcons/IconCircle'
import IconsAreYouSureCancel from '../../icons/LogsIcons/IconsAreYouSureCancel'
import DropDownBox from '../MRDropDown';

import MainRegionContext from '../General/MRContext'
import coversationContext from '../../../context/conversationContext';
import coversationResponseContext from '../../../context/conversationResponseContext';
import { createConsumer } from "@rails/actioncable";
import subscriptionContext from '../../../context/subscriptionContext';
import { useParams } from 'react-router-dom';
import { MRConvoAPI } from '../../../helper/MRConversationAPI';
import IconFeature from '../../../images/Features.png'
import IconHealthReport from '../../../images/HealthReport.png'
// import subscribeConversationChannel from "../../../websockets/conversationConsumer";

export default function MRInputBar({ viewOneConvo }) {
  const MainRegionState = useContext(MainRegionContext);
  const [inputBarFocused, setInputBarFocused] = useState(false);
  const [currentTextRow, setCurrentTextRow] = useState(1);
  const [expand, setExpand] = useState(false);
  const [isTextLongEnough, setIsTextLongEnough] = useState(false);
  const [cardHover, setCardHover] = useState()
  const dropzoneRef = useRef(null);
  const { id } = useParams()
  
  const showGigaPrompt = () => {
    MainRegionState.setMRSubscription(false);
 
    if(MainRegionState.MRShow[0].MRGigaPrompt){
      MainRegionState.setMRShow([
        {
          "MRTextHeader": (!MainRegionState.MRShow[0].MRTextHeader),
          "MRGigaPrompt": (!MainRegionState.MRShow[0].MRGigaPrompt),
          "MRInputBar": true,
          "MRConversation": true,
          "MRLogs": false
        }
    ]);   
    } 
    else{
      MainRegionState.setMRShow([
        {
          "MRTextHeader": (!MainRegionState.MRShow[0].MRTextHeader),
          "MRGigaPrompt": true,
          "MRInputBar": true,
          "MRConversation": false,
          "MRLogs": false
        }
    ]);
    }
  }
    

  useEffect(() => {
    MRConvoAPI.can_use_atticus()
    .then((response) => {
      MainRegionState.setCanUseAtticus(response.data)
    })
    .catch((error) => {
      console.error(error)
    })
  }, [])


 
  const generateReport = () => {
    MainRegionState.setGenerateReport(true);
  }

  const expandTextArea = (e) => {
    e.preventDefault();
    setExpand(!expand);
    setInputBarFocused(true);
  };

  useEffect(() => {
    let input;
    if (!expand) {
      input = document.querySelector('.GPInputText') || document.querySelector('.GPInputTextnew');
    } else if (viewOneConvo) {
      input = document.querySelector('.GPInputTextexisting');
    } else {
      input = document.querySelector('.GPInputTextnew');
    }
    if (input) {
      const scrollHeight = input.height;
      if (scrollHeight > 250) {
        setIsTextLongEnough(true);
      } else {
        setIsTextLongEnough(false);
      }
    }
  }, [expand, viewOneConvo]);

  const handleHoverCard = (id) => {
    setCardHover(id);
  };

  const handleMouseLeave = () => {
    setCardHover(false);
  };

  const handleRemoveFile = (id) => {
    const updatedFiles = MainRegionState.fileUpload.filter(file => file.id !== id);
    MainRegionState.setFileUpload(updatedFiles);
  };

  const showDropzone = () => {
    MainRegionState.setTriggerBtn(true)
  };

  return (
    <span className={`${viewOneConvo && 'GPInputWrapperFixed'} GPInputWrapperFixed inputBar`}>
      <MRIDropdown />
      <span className={`text-bar-btn mr-header GPInputWrapper ${inputBarFocused && 'gpInputWrapperFocus'}`}>
        <div className='file-cards'>
          {MainRegionState.fileUpload.map((file, index) => (
            <div key={file.id} className='file-attached' onMouseEnter={() => handleHoverCard(file.id)} onMouseLeave={handleMouseLeave}>
              <div className={`file-card-tooltip ${cardHover && cardHover === file.id ? 'file-card-tooltip-visible' : ''}`}>
                <span className='tooltip-file-text'>{file.name}</span>
              </div>
              <span className='cancel-icon-file' onClick={() => handleRemoveFile(file.id)}>
                <IconsAreYouSureCancel />
              </span>
              <IconCircle />
            </div>
          ))}
        </div>
        <div>
          <InputGroup>
            <MRITextInput
              setInputBarFocused={setInputBarFocused}
              InputBarFocused={inputBarFocused}
              viewOneConvo={viewOneConvo}
              expand={expand}
              setCurrentTextRow={setCurrentTextRow}
              currentTextRow={currentTextRow}
              setExpand={setExpand}
            />
            <div className="d-flex flex-column align-items-center" style={{ height: '100%' }}>
              {currentTextRow >= 3 && (
                <div>
                  {expand ? (
                    <span onClick={(e) => expandTextArea(e)}>
                      <ExpandBtn />
                    </span>
                  ) : (
                    <span onClick={(e) => expandTextArea(e)}>
                      <IconExpand />
                    </span>
                  )}
                </div>
              )}
              <div className='mt-auto longtext'>
                <MRIButton />
              </div>
            </div>
          </InputGroup>
        </div>
      </span>
      <span className='features-container'>
        <Dropdown drop="up">
          <span style={{display: 'flex'}}>
          <Dropdown.Toggle as={Button} className="icon-features custom-dropdown-toggle">
            <img src={IconFeature} width={22} alt="" />
          </Dropdown.Toggle>
            <span className="features-text">Features</span>
          </span>
          <Dropdown.Menu>
            <Dropdown.Item onClick={generateReport}
            disabled={!MainRegionState.canUseAtticus?.can_generate_report || !MainRegionState.canUseAtticus?.can_use_atticus}
            >
              <span className="icon-file">
                <img src={IconHealthReport} width={18} />
              </span>
              <span className="add-file-text" style={{ color: (!MainRegionState.canUseAtticus?.can_generate_report || !MainRegionState.canUseAtticus?.can_use_atticus) ? 'rgb(177 174 174)' : '' }}>
                Generate Report
              </span>
            </Dropdown.Item>
            <Dropdown.Item onClick={showDropzone}>
              {!id && <DropDownBox />}
              <span className="icon-file">
                <IconVertex width={22} />
              </span>
              <span className="add-file-text">Add file(s)</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </span>
    </span>
  );
}
