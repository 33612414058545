const Icon = (props) => {
    return (
        <>
<svg width="19" height="19" viewBox="0 0 19 19" fill="#060E1E" xmlns="http://www.w3.org/2000/svg">
<path d="M9.18283 18.3711C7.97644 18.3734 6.78154 18.1372 5.66673 17.6762C4.55193 17.2151 3.53925 16.5384 2.68682 15.6847C-0.895608 12.1024 -0.895608 6.2739 2.68682 2.69157C3.53795 1.83567 4.55043 1.15708 5.66561 0.695076C6.78074 0.23307 7.97648 -0.00316927 9.18358 3.21063e-05C11.6377 3.21063e-05 13.945 0.955519 15.6796 2.69157C17.4149 4.42689 18.3712 6.734 18.3712 9.18815C18.3712 11.6423 17.4156 13.9494 15.6796 15.6847C14.827 16.5384 13.8141 17.2151 12.6993 17.6762C11.5843 18.1372 10.3893 18.3733 9.18283 18.3711ZM9.18358 1.47001C8.16957 1.46714 7.16508 1.66548 6.2283 2.05356C5.29151 2.44164 4.44102 3.01175 3.72613 3.73083C2.2686 5.18835 1.46597 7.12649 1.46597 9.18815C1.46597 11.2498 2.2686 13.1872 3.72613 14.6455C6.73524 17.6545 11.6319 17.6553 14.6403 14.6455C16.0978 13.188 16.9012 11.2498 16.9012 9.18815C16.9012 7.12649 16.0985 5.18906 14.6403 3.73083C13.9253 3.01195 13.075 2.442 12.1384 2.05393C11.2017 1.66586 10.1974 1.46739 9.18358 1.47001Z" fill="#060E1E"/>
<path d="M6.06451 13.0402C5.91901 13.0406 5.77667 12.9976 5.65561 12.917C5.53454 12.8362 5.44017 12.7214 5.3845 12.5869C5.3288 12.4525 5.31436 12.3046 5.34287 12.1619C5.37143 12.0192 5.44175 11.8883 5.54487 11.7856L11.7814 5.54921C11.8496 5.48098 11.9307 5.42686 12.0198 5.38989C12.109 5.35297 12.2045 5.33398 12.301 5.33398C12.3976 5.33398 12.4931 5.35297 12.5823 5.38989C12.6714 5.42686 12.7525 5.48098 12.8207 5.54921C12.8889 5.61745 12.9431 5.69846 12.98 5.78763C13.0169 5.8768 13.036 5.97234 13.036 6.06884C13.036 6.16534 13.0169 6.26093 12.98 6.3501C12.9431 6.43923 12.8889 6.52024 12.8207 6.58847L6.58415 12.8249C6.51609 12.8934 6.43511 12.9477 6.3459 12.9846C6.25672 13.0216 6.16105 13.0405 6.06451 13.0402Z" fill="#060E1E"/>
<path d="M12.3015 13.0402C12.205 13.0403 12.1094 13.0214 12.0202 12.9844C11.931 12.9475 11.85 12.8932 11.7819 12.8249L5.54531 6.58847C5.47708 6.52024 5.42296 6.43923 5.38603 6.3501C5.3491 6.26093 5.33008 6.16534 5.33008 6.06884C5.33008 5.97234 5.3491 5.8768 5.38603 5.78763C5.42296 5.69846 5.47708 5.61745 5.54531 5.54921C5.61359 5.48098 5.6946 5.42686 5.78373 5.38989C5.87291 5.35297 5.96849 5.33398 6.065 5.33398C6.1615 5.33398 6.25704 5.35297 6.34622 5.38989C6.43539 5.42686 6.5164 5.48098 6.58464 5.54921L12.8212 11.7856C12.9243 11.8883 12.9946 12.0192 13.0231 12.1619C13.0517 12.3046 13.0372 12.4525 12.9815 12.5869C12.9258 12.7214 12.8315 12.8362 12.7104 12.917C12.5893 12.9976 12.447 13.0406 12.3015 13.0402Z" fill="#060E1E"/>
</svg>



        </>
    );
};

export default Icon;