import React, { useState } from 'react';
import Loginform from './hp-loginForm';
import { Link } from 'react-router-dom';
import LCol from './HPLoginLCol';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const Login = () => {
    const isAuthenticated = useIsAuthenticated();
    const auth = useAuthUser();
    const [showWelcomeBack, setShowWelcomeBack] = useState(true);

    const isTokenValid = () => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                return decodedToken.exp > currentTime;
            } catch (error) {
                console.log('Invalid token');
            }
        }
        return false;
    };

    if (isAuthenticated() && isTokenValid()) {
        // If authenticated user and token is valid, then redirect to secure dashboard
        return <Navigate to={'/conversation'} replace />;
    }

    return (
        <>
            <div className="auth_page_wrapper">
                <LCol />
                <div className="auth_col">
                    <div className="form_scroll">
                        <div className="form_wrap">
                            <h2 className="log_title">
                                Log In
                                {showWelcomeBack && <span className="log_sub">Welcome back 🎉</span>}
                            </h2>
                            <Loginform setShowWelcomeBack={setShowWelcomeBack} />
                            <div className="alt_auth_wrapper text-center">
                                <div className="divider">
                                    <span>or</span>
                                </div>
                                <p>Don't have an account?</p>
                                <Link to="/signup" className="log_btn bg_white"> Sign up</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;