const Icon = (props) => {
    return (
        <>
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.51562 14.2822C9.4875 14.2822 9.45937 14.2822 9.43125 14.279C9.0875 14.2447 8.80313 13.9947 8.725 13.6572L6.23438 3.25092L4.43437 7.0478C4.3 7.33217 4.0125 7.51342 3.69688 7.51342H0.8125C0.3625 7.51342 0 7.1478 0 6.6978C0 6.2478 0.365625 5.8853 0.8125 5.8853H3.18125L5.75 0.466547C5.94063 0.0602973 6.42812 -0.114703 6.83437 0.0790472C7.05937 0.185297 7.22187 0.385297 7.27812 0.625922L9.76875 11.0322L11.5656 7.2353C11.7 6.95092 11.9875 6.76967 12.3 6.76967H15.1875C15.6375 6.76967 16 7.1353 16 7.5853C16 8.0353 15.6344 8.3978 15.1875 8.3978H12.8156L10.25 13.8165C10.1156 14.1009 9.82812 14.2822 9.51562 14.2822Z" fill="#C7C7C7" />
            </svg>
        </>
    );
};

export default Icon;
