import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useSignIn } from 'react-auth-kit';
import Spinner from 'react-bootstrap/Spinner';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import InputField from '../main-region/MRInputBar/InputField.jsx'; 
import Cookies from 'js-cookie';

const LogInComponent = ({ setShowWelcomeBack }) => {
    const navigate = useNavigate();
    const signIn = useSignIn();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [showPassword, setShowPassword] = React.useState(false);
    const [formData, setFormData] = React.useState({ email: '', password: '' });
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoginEnabled, setisLoginEnabled] = useState(false);
    const [passwordError, setPasswordError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect_uri = searchParams.get('redirect_uri')
    
    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };


    const renderPasswordError = (e) => {
        let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/;

        if (!passwordRegex.test(formData.password)) {
            setPasswordError('Enter valid password')
        }
        else {
            setPasswordError(false)
        }
    };

    useEffect(() => {
        if (formData.email &&
            formData.password &&
            !emailError) {
            setisLoginEnabled(true)
        }
        else {
            setisLoginEnabled(false)
        }
    }, [formData, emailError]);

    const renderEmailError = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;

        if (!emailRegex.test(formData.email)) {
            setEmailError('enter valid email');
        } else {
            setEmailError(false);
        }
    };

    const onSubmit = async (e) => {
        if (!isLoginEnabled){
            e.preventDefault();
            return
        }
        e.preventDefault();
        setLoading(true);
        try {

            const response = await axios.post(`${baseUrl}/api/v1/users/login`, { user: formData });
            if (response.status === 200) {
                const { jwt, user, credits_data } = response.data;
                const expiresInInMinute = 24 * 60;
                Cookies.set('token', jwt)
                if (signIn(
                    {
                        token: jwt,
                        expiresIn: expiresInInMinute,
                        tokenType: 'Authorization',
                        authState: { user: user, credits_data: credits_data }
                    }
                )) {
                    // Redirect to app
                    redirect_uri ? window.location.replace(redirect_uri) : navigate('/conversation');
                }
                setLoading(false);
            }
        } catch (error) {
            setError(error?.response?.data?.error);
            console.error(error);
            setShowWelcomeBack(false)
            setLoading(false);
        }
    }

    return (
        <>
            {error && (error === 'Invalid credentials') && (
                <h4 className="mb-5 text-danger">These are invalid credentials</h4>
            )}
           <form noValidate className="d-flex flex-column logForm loginForm" onSubmit={onSubmit}>
                <InputField
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email} 
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    onBlur={renderEmailError}
                    error={emailError}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                />
                <InputField
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formData.password} 
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    onBlur={renderPasswordError}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                />
                <div className="forget_password mb-20 text-decoration-none">
                    <Link to="/resetpassword">Forgot password?</Link>
                </div>
                {loading ? (
                    <span className="log_btn mt-10" disabled>
                        <Spinner animation="border" role="status" />
                    </span>
                ) : (
                    <input
                        className={`log_btn mt-10 ${!isLoginEnabled ? 'disabled' : ''}`}
                        aria-disabled={!isLoginEnabled}
                        type="submit"
                        value="Log In"
                    />
                )}



            </form>
        </>
    );
};

export default LogInComponent;
