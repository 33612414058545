const MRITooltip = ({rightMargin, showTooltip,busy, widthTooltip,textTooltip}) => {

    return (
        <div className={`tooltip-btn ${busy ? 'tooltip-busy' : ''}`} style={{ right: rightMargin, visibility: showTooltip ? 'visible' : 'hidden' }}>
            <span className='tooltip-text' style={{ width: widthTooltip }}>{textTooltip}</span>
        </div>
    )

}

export default MRITooltip;