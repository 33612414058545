const Icon = (props) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24" fill="none">
                <path d="M17.9977 5.20725C17.991 5.15062 17.9243 4.644 17.5796 4.299L13.701 0.42075C13.3564 0.076125 12.8494 0.009 12.7927 0.002625C12.7785 0.00075 12.7642 0 12.75 0H1.875C0.841125 0 0 0.841125 0 1.875V22.125C0 23.1589 0.841125 24 1.875 24H16.125C17.1589 24 18 23.1589 18 22.125V5.25C18 5.23575 17.9993 5.2215 17.9977 5.20725ZM17.049 4.82925C17.0621 4.84238 17.0715 4.86 17.0831 4.875H14.25C13.6298 4.875 13.125 4.37025 13.125 3.75V0.916875C13.14 0.9285 13.1573 0.937875 13.1708 0.951L17.049 4.82925ZM16.125 23.25H1.875C1.25475 23.25 0.75 22.7453 0.75 22.125V1.875C0.75 1.25475 1.25475 0.75 1.875 0.75H12.375V3.75C12.375 4.78387 13.2161 5.625 14.25 5.625H17.25V22.125C17.25 22.7453 16.7453 23.25 16.125 23.25Z" fill="black" />
            </svg>

        </>
    );
};

export default Icon;
