import Button from 'react-bootstrap/Button';
import PMcredit from './PMcredit'


function PMMenuTrigger({creditsInfo, handleShowPMMenu }) {

  return (
    <>
      <Button variant="light" onClick={handleShowPMMenu} className="position-fixed profile_hamburger top-0 end-0">
        {/* {creditsInfo.data.credit_details?.current_credits} Icon {creditsInfo.data.user_initials} */}
        <div className="profile_name">{creditsInfo.user_initials}</div>
      </Button>

      
    </>
  );
}

export default PMMenuTrigger;
