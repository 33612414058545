import { useState, useEffect } from "react";
import MRLContext from "./MRLContext";
import { MRLogsAPI } from "../../../helper/MRLogsAPI";
import { MRFolderAPI } from "../../../helper/MRFolderAPI";
import { faUniversalAccess } from "@fortawesome/free-solid-svg-icons";


const MRLogs = (props) => {
  const [logsLoading, setlogsLoading] = useState(false);
  const [openItems, setOpenItems] = useState([0]);
  const [logsData, setlogsData] = useState();
  const [logsDataToggle, setLogsDataToggle] = useState(false)
  const [page, setPage] = useState(1)  
  const [currentPage, setCurrentPage] = useState(1)
  const [total_page, setTotalPage] = useState(2)
  const [scrollDown, setScollDown] = useState(false)
  const [moreData , setNoMoreData] = useState(false)
  const [moveFolder, setMoveFolder] = useState(false)
  const [moveConversation, setMoveConversation] = useState(false)
  const [toBeMovedId, setToBeMovedId] = useState()
  const [tobeMovedType, setToBeMovedType] =  useState()
  const [toBeMovedObj, setToBeMovedObj] = useState()
  const [selectedState, setSelectedState] = useState(false)
  const [prevFolder, setPrevFolder] = useState(null)
  const [prevFolderObj, setPrevFolderObj] = useState(null)
  const [moveSelected ,setMoveSelected] = useState(false)
  const [folderBorder ,setFolderBorder] = useState(false)
  const [loader, setLoader] = useState(false)
  const [moveState, setMoveState] = useState()
  const [moveConvoState, setMoveConvoState] = useState()
  const removeLastAddedFolder = () => {
    setlogsData(prevLogsData => {
        const updatedLogsData = { ...prevLogsData };
        const todayHeader = updatedLogsData.data.find(header => header.header === "Today");
        if (todayHeader && todayHeader.data.length > 0) {
            todayHeader.data.pop();
        }
        return updatedLogsData;
    });
};
  const findParentFolderForMoveFolder = (folderId, data) => {
    for (const [index, section] of data.data.entries()) {
      if (Array.isArray(section.data)) {
        const parentIndex = section.data.findIndex((folder) => folder.id === folderId);
        if (parentIndex !== -1) {
          return [index, parentIndex];
        }
      }
    }
    return [-1, -1];
  }


  const findParentFolder = (folderId, data) => {
    for (const [index, section] of data.data.entries()) {
      if (Array.isArray(section.data)) {
        const parentIndex = section.data.findIndex((folder) => folder.id === folderId);
        if (parentIndex !== -1) {
          return [index, parentIndex];
        }
      }
    }
    return [-1, -1];
  };



const removeFolder = (folderId, parentFolderId, topParentFolderId, section, ) => {
  const stack = [section];
 
  while (stack.length > 0) {
      const data = stack.pop();
      if (parentFolderId === null){
        return -1
      }else{
      if (data.id === parentFolderId) {
          const indexToRemove = data.children.findIndex(child => child.id === folderId);
          if (indexToRemove !== -1) {
              const removedFolder = data.children.filter((child, index) => index !== indexToRemove);
              data.children = removedFolder;      
              if (data.children.length === 0){
                data.has_children = false
              }
              return section;
          }
      }

      if (data.has_children && Array.isArray(data.children)) {
          stack.push(...data.children);
      }
    }
  }

  return null;
};

  const condenseHeader = (header, child = null) => {
    const parts = header.split(' < ');
    if (tobeMovedType == 'conversation' ||  (child && child?.type == 'PARENT_CONVERSATION')) {
      parts[0] = 'CONVERSATION'
    }
    if (parts.length > 3) {
        return `${parts[0]} < ${parts[1]} < ... < ${parts[parts.length - 1]}`;
    }
    return parts.join(' < ');
  }

  const updateBreadCrumbs = (moveItem) => {
    moveItem.header = condenseHeader(moveItem.header)
    if (moveItem.has_children && moveItem.children && moveItem.children.length !== 0) {
      const updatedChildHeader = `${moveItem.header} < ${moveItem.name}`
            moveItem.children.map(item => item.header = condenseHeader(updatedChildHeader, item))
    }
  }
  const findImmediateParent = (folderId, data) => {

    if (!Array.isArray(data.children)) {
      return data;
    }
  
    const stack = [...data.children];
  
    while (stack.length > 0) {
      const section = stack.pop();
      if (section && section.id === folderId) {
        return section;
      }
  
      if (section && section.has_children && section.type === "FOLDER" && Array.isArray(section.children)) {
        stack.push(...section.children);
      }
    }
  
    return data;
  };

  const findImmediateParentToBeMovedFolder = (folderId,parentFolderId, data, toBeMoved) => {
    if (!Array.isArray(data.children)) {
      return data;
    }

    const stack = [...data.children];
    while (stack.length > 0) {
      const data = stack.pop();
      if (data.id === folderId) {
          if (!data?.children){
            data.children = []
          }
          toBeMoved.header = `${data.header} < ${data.name}`
          updateBreadCrumbs(toBeMoved)
          data.children.push(toBeMoved)
          data.has_children = true
          
          toBeMoved.parent_folder_id = folderId
          toBeMoved.top_parent_folder_id = data.top_parent_folder_id

          return data.children
      }

      if (data.has_children && Array.isArray(data.children)) {
          stack.push(...data.children);
      }
  }
    return null;
  }

  const findParentFolderForCloneConversation = (folderId, data) => {
    for (const [index, section] of data.data.entries()) {
      if (Array.isArray(section.data)) {
        const parentIndex = section.data.findIndex((folder) => folder.id === folderId);
        if (parentIndex !== -1) {
          return [index, parentIndex];
        }
      }
    }
    return [-1, -1];
  }

  const findImmediateParentFolderForCloneConversation= (folderId,parentFolderId, data, toBeMoved) => {
    if (!Array.isArray(data.children)) {
      return data;
    }

    const stack = [...data.children];
    while (stack.length > 0) {
      const data = stack.pop();
      if (data.id === folderId) {
          if (!data?.children){
            data.children = []
          }
          data.children.push(toBeMoved)
          return data.children
      }

      if (data.has_children && Array.isArray(data.children)) {
          stack.push(...data.children);
      }
  }
    return null;
  }
  
  
  const updateLogsDataMoveFolder = (destinationFolder, toBeMoved ) => {
    const destinationFolderTopParentFolderId = destinationFolder.top_parent_folder_id;
    const destinationFolderParentFolderId = destinationFolder.parent_folder_id
    const destinationFolderId = destinationFolder.id

    const toBeMovedTopParentFolderId = toBeMoved.top_parent_folder_id;
    const toBeMovedParentFolderId = toBeMoved.parent_folder_id
    const toBeMovedFolderId = toBeMoved.id  

    setlogsData((prevLogsData) => {
      const [sourceSectionIndex, sourceParentIndex] = findParentFolderForMoveFolder(toBeMovedTopParentFolderId ? toBeMovedTopParentFolderId : toBeMovedFolderId, prevLogsData); //finding top_parent folder of to be moved folder
      const topParentFolderObject = prevLogsData?.data[sourceSectionIndex]?.data[sourceParentIndex];
      const [section, parentIndex] = findParentFolderForMoveFolder(destinationFolderTopParentFolderId ? destinationFolderTopParentFolderId : destinationFolderId, prevLogsData); //finding top_parent of destination folder to be moved

      if (parentIndex !== -1) { //appending folder
        if (destinationFolderTopParentFolderId){
          const immediateParent = findImmediateParentToBeMovedFolder(destinationFolderId, destinationFolderParentFolderId,prevLogsData.data[section].data[parentIndex], toBeMoved )  //finding parent folder 
          immediateParent.has_children = true
        }
        else{
          if (!prevLogsData.data[section].data[parentIndex]?.children) {
            prevLogsData.data[section].data[parentIndex].children = [];
            prevLogsData.data[section].data[parentIndex].has_children = true
          }
          
          toBeMoved.header = `${prevLogsData.data[section].data[parentIndex].header} < ${prevLogsData.data[section].data[parentIndex].name}`
          updateBreadCrumbs(toBeMoved)
          prevLogsData.data[section].data[parentIndex].children.push(toBeMoved)
          prevLogsData.data[section].data[parentIndex].has_children = true
          toBeMoved.parent_folder_id = prevLogsData.data[section].data[parentIndex].id
          toBeMoved.top_parent_folder_id = prevLogsData.data[section].data[parentIndex].id
        }         
    }

      if (sourceSectionIndex !== -1) { //removing folder to be moved
        const removed = removeFolder(toBeMovedFolderId, toBeMovedParentFolderId, toBeMovedTopParentFolderId,  topParentFolderObject)
            if (removed === -1 ){
              prevLogsData.data[sourceSectionIndex].data.splice(sourceParentIndex, 1)
            }
            else if (removed) {
              console.log("removed", removed)
            } else {
                topParentFolderObject?.children?.splice(sourceParentIndex, 1)
                if (topParentFolderObject?.children.length === 0){
                  topParentFolderObject.has_children = false
                }
            }    
      }
      
      return prevLogsData;
    });
  }

  const updateLogsFilterAndSort = (data) => {
    setlogsData(data)
  }

    // useEffect(() => {
    //     console.log(logsData)
    
    //     if (!logsData) {
    //       setlogsLoading(true);
    
    //       MRFolderAPI.listFolders()
    //       .then((response) => {
    //         updateLogsData(response)
    
    //         })
    //         .catch((error) => {
    //           console.error("Error fetching logs data:", error);
    //         })
    //         .finally(() => {
    //           setlogsLoading(false);
    //           if(page === total_page){
    //             setNoMoreData(false)
    //           }else{
    //             setNoMoreData(true)
    //           }
    //         });
    //     } else {
    //       console.log("logsData is already present, no fetch needed");
    //     }
    //   },[logsData]);

  const updateLogsData = (newData) => {
    setlogsData((prevLogsData) => {
      if (!prevLogsData) {
        return newData;
      }
  
      const topParentFolderId = newData.data.top_parent_folder_id;
      const parentFolderId = newData?.data?.children[0]?.parent_folder_id
      const [section, parentIndex] = findParentFolder(topParentFolderId, prevLogsData);
      const topParentInAppending = prevLogsData.data[section].data[parentIndex]


      if (parentIndex !== -1) {
          const immediateParent = findImmediateParent(parentFolderId, topParentInAppending);
          if (immediateParent) {
            if (!immediateParent.children){
   
                immediateParent.children = []
                const uniqueChildren = newData.data.children.filter(child => child.parent_folder_id === immediateParent.id) 
                
                uniqueChildren.header = `${immediateParent.header} < ${immediateParent.name}`
                updateBreadCrumbs(uniqueChildren)
                immediateParent.children.push(...uniqueChildren)
                immediateParent.has_children = true

            }else{
              const uniqueChildIds = new Set(prevLogsData.data[section].data[parentIndex]?.children.map(child => child.id));
              const uniqueNewChildren = newData.data.children.filter(child => !uniqueChildIds.has(child.id));
              const  uniqueNewChildrenUpdated = uniqueNewChildren.filter(child => child.parent_folder_id === prevLogsData.data[section].data[parentIndex].id )
              
              uniqueNewChildrenUpdated.header = `${prevLogsData.data[section].data[parentIndex]?.header} < ${prevLogsData.data[section].data[parentIndex]?.name}`
              updateBreadCrumbs(uniqueNewChildrenUpdated)
              prevLogsData.data[section].data[parentIndex]?.children.push(...uniqueNewChildrenUpdated);   
    
            }
            return prevLogsData;
          }
      }
      

      return prevLogsData;
    });
  };


  
  
  const updateLogsDataForCloneConversation = (convoCloned ) => {
    const conversationTopParentFolderId = convoCloned.top_parent_folder_id;
    const conversationParentFolderId = convoCloned.parent_folder_id 

    setlogsData((prevLogsData) => {
      const [sourceSectionIndex, sourceParentIndex] = findParentFolderForCloneConversation(conversationTopParentFolderId, prevLogsData); //finding top_parent folder of to be moved folder
      const topParentFolderObject = prevLogsData?.data[sourceSectionIndex]?.data[sourceParentIndex];
      if (sourceParentIndex !== -1) { //appending folder
        if (conversationTopParentFolderId !== conversationParentFolderId){
          findImmediateParentFolderForCloneConversation(conversationParentFolderId, conversationTopParentFolderId,topParentFolderObject, convoCloned )  //finding parent folder 
        }
        else{
          prevLogsData?.data[sourceSectionIndex]?.data[sourceParentIndex].children.push(convoCloned)
        }         
    }
      setLogsDataToggle(!logsDataToggle)
      return prevLogsData;
    });

  }


  const resetLogsData = () => {
    setlogsData('')
  }

  const updateIsPublic = (conversationId, newIsPublicValue) => {
    setlogsData((prevData) => {
     const newData =  { data: prevData.data.map((day) => ({
        ...day,
        data: day.data.map((folder) => ({
          ...folder,
          children: folder.children?.map((conv) => 
            conv.uuid === conversationId ? { ...conv, is_public: newIsPublicValue } : conv
          ),
          })),
      }))
    }
      return newData;
    });
  };

  const handleAccordionToggle = (itemId, folder) => {
    if (openItems.includes(itemId)) {
      setOpenItems(openItems.filter((item) => item !== itemId));
    } else {
      setOpenItems([...openItems, itemId]);
    }
  };

  const resetMove = () => {
    setMoveConversation(false)
    setMoveFolder(false)
    setToBeMovedId(null)
    setToBeMovedType(null)
    setMoveSelected(false)
    setSelectedState(false)
    setMoveState(false)
    setMoveConvoState(false)
  }

  

return (    
    <MRLContext.Provider value={{
      openItems, handleAccordionToggle,moreData,updateLogsData, moveState, moveConvoState, setMoveConvoState, setMoveState, updateLogsDataMoveFolder,toBeMovedObj, setToBeMovedObj,
      scrollDown, setScollDown, page, setPage,total_page, setTotalPage,setCurrentPage,moveSelected ,setMoveSelected,prevFolderObj, setPrevFolderObj,folderBorder ,setFolderBorder,
      logsLoading, logsData, moveConversation, setMoveConversation,resetMove,
      setlogsData,moveFolder, setMoveFolder,toBeMovedId, setToBeMovedId,tobeMovedType, setToBeMovedType,updateLogsFilterAndSort,loader, setLoader,resetLogsData,
      removeLastAddedFolder,setOpenItems,openItems, updateIsPublic,prevFolder, setPrevFolder,selectedState, setSelectedState,  updateLogsDataForCloneConversation,logsDataToggle
      }}>
        {props.children}
    </MRLContext.Provider>
  )
}

export default MRLogs;


