import React, { useState, useContext, useEffect, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import MRISearchInputBar from './MRISearchInputBar';
import IconSearch from '../icons/IconSearch';
import IconCancel from '../icons/IconCancelBar'
import coversationContext from '../../context/conversationContext';

const MRSeachBar = () => {
    const context = useContext(coversationContext);

    const handleEmpty = () => {
        context.setSearchInputBar("")
        context.setSearched("")
    }

    const handleSearch = () => {
        context.setSearched(context.searchInputBar)
    }


    return (
        <>
            <InputGroup>
                <MRISearchInputBar />
                <button className={`btn cancel-btn btn btn-primary ${context.searchInputBar === '' ? 'cancel-icon': ''}`} style={{ cursor: "pointer !important"  }} onClick={handleEmpty}> <IconCancel />  </button>
                <button className='searchIcon btn btn-primary' onClick={handleSearch}> <IconSearch /> </button>
            </InputGroup>
        </>
    );
}
export default MRSeachBar