
const IconArrowCircle = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M8.99988 18C13.9624 18 17.9997 13.9626 17.9997 8.99996C17.9997 4.03744 13.9624 0 8.99988 0C4.03735 0 0 4.03744 0 8.99996C0 13.9626 4.03735 18 8.99988 18ZM8.99988 1.23203C13.2831 1.23203 16.7676 4.71669 16.7677 8.99996C16.7677 13.2832 13.2831 16.7679 8.99988 16.768C4.71669 16.7679 1.23211 13.2832 1.23211 8.99988C1.23211 4.71677 4.71669 1.23203 8.99988 1.23203Z" fill="#5DCDF5" />
                <path d="M10.1661 5.14949C9.92548 4.909 9.53542 4.90908 9.29493 5.14949C9.05427 5.39014 9.05427 5.78012 9.29501 6.02078L11.6621 8.38783L4.80739 8.38849C4.46719 8.38857 4.19146 8.6643 4.19146 9.00467C4.19154 9.34487 4.46727 9.62051 4.80747 9.62051L11.6625 9.61986L9.29476 11.9874C9.05419 12.228 9.05419 12.6181 9.29476 12.8586C9.41509 12.9789 9.57271 13.0391 9.73041 13.0391C9.88802 13.0391 10.0456 12.9789 10.166 12.8587L13.5852 9.43957C13.7007 9.32409 13.7656 9.16746 13.7656 9.00401C13.7655 8.84056 13.7007 8.68401 13.5851 8.56828L10.1661 5.14949Z" fill="#5DCDF5" />
            </svg>
        </>
    );
};

export default IconArrowCircle;