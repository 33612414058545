import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    logsData: [],
    responseFruxData: [],
    selectedUser: '',
    responseFruxDataState: false,
    isPolling: false,
    buttonPressed: false,
    clickedChild: false,
    disableEditing: false,
    logOutUser: false,
    addNewFolder: false,
    creditsUpdateState: false,
    expandResponse: false
};

export const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        updateLogsData: (state, action) => {
            const props = action.payload;
            if (props.data.meta.current_convo_id === 0 || props.data.meta.current_convo_id === null) {
                state.logsData = [props];
            } else {
                const index = state.logsData.findIndex(
                    (item) =>
                        item.data?.jobs_data[item.data?.jobs_data.length - 1]?.id === props.data?.jobs_data[0]?.id
                );

                if (index !== -1) {
                    const existingResponses = state.logsData[index].data.jobs_data[
                        state.logsData[index].data.jobs_data.length - 1
                    ]?.responses;
                    const newResponses = props.data.jobs_data[0]?.responses;
                    state.logsData[index].data.jobs_data[state.logsData[index].data.jobs_data.length - 1].responses =
                    existingResponses.concat(newResponses);
                    props.data.jobs_data = props.data.jobs_data.slice(1);
                    state.logsData = [...state.logsData, props];
                } else {
                    state.logsData = [...state.logsData, props];
                }
            }
        },
        setResponseLogs: (state, action) => {
            const props = action.payload;

            if (
                state.logsData[0]?.data?.jobs_data[0]?.responses[0]?.ConvoId === props[0]?.ConvoId
            ) {
                return
            } else {
                state.logsData[0]?.data?.jobs_data[0]?.responses.unshift(props[0]);
            }
        },
        setFruxData: (state, action) => {
            state.responseFruxData = action.payload
        },
        setFruxDataState: (state, action) => {
            state.responseFruxDataState = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        setAddNewFolder: (state, action) => {
            state.addNewFolder = action.payload
        },
        setCreditsUpdateState: (state, action) => {
            state.creditsUpdateState = action.payload
        },
        setExpandResponse: (state, action) => {
            state.expandResponse = action.payload
        },
        updateDisableEditing: (state, action) => {
            state.disableEditing = action.payload
        },
        updateLogoutUser: (state, action) => {
            state.logOutUser = action.payload
        },
        resetLogsData: (state) => {
            state.logsData = [];
        },
    },
});

export const { updateLogsData, setResponse, resetLogsData, logsData,setResponseLogs, updateDisableEditing, updateLogoutUser,setAddNewFolder, disableEditing, logOutUser, setFruxData, setFruxDataState, setSelectedUser,  selectedUser, creditsUpdateState, setCreditsUpdateState, setExpandResponse} = logsSlice.actions;

export default logsSlice.reducer;
